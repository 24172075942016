import { ConfirmState, INITIAL_CONFIRM_STATE } from '@orbiapp/components';
import { PostTargetType } from 'dtos/base/post-target-type';
import React from 'react';

interface CreatePostContextType {
  confirm: ConfirmState;

  targetType: PostTargetType | null;
  setTargetType: (targetType: PostTargetType | null) => void;
}

export const CreatePostContext = React.createContext<CreatePostContextType>({
  confirm: INITIAL_CONFIRM_STATE,

  targetType: null,
  setTargetType: () => {},
});
