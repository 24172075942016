import { TicketListItem } from '../../models';
import {
  consumeTicketThunk,
  createActivityDraftThunk,
  createActivityThunk,
  createDynamicActivityLinkThunk,
  createQrScanTokenThunk,
  deleteActivityDraftThunk,
  deleteQrScanTokenThunk,
  getActivitiesThunk,
  getActivityCategoriesThunk,
  getActivityDiscountStatsThunk,
  getActivityDraftThunk,
  getActivityDraftsThunk,
  getActivityRecordsThunk,
  getActivityThunk,
  getActivityUsersDataThunk,
  getActivityUsersThunk,
  getCoHostingRequestsThunk,
  getFreeTextListItemsThunk,
  getQrScanTokensThunk,
  getRequestedInfoStatsThunk,
  getTicketDetailsThunk,
  getTicketListItemsThunk,
  getTicketsHistoryThunk,
  refundTicketThunk,
  scanTicketThunk,
  suggestActivityCategoriesThunk,
  unconsumeTicketThunk,
  undoScanTicketThunk,
  unpublishActivityThunk,
  updateActivityThunk,
  updateCohostingRequestThunk,
} from './activities.actions';
import {
  activityListItemAdapter,
  coHostingAdapter,
  draftAdapter,
  freetextListItemsAdapter,
  qrScanTokensAdapter,
  ticketsAdapter,
  ticketsHistoryAdapter,
} from './activities.adapter';
import { ActivityBuilder } from './activities.types';
import {
  createActivityFormToActivityListItem,
  updateActivityFormToActivityListItem,
} from './activities.utils';

const createActivityBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(createActivityThunk.fulfilled, (state, action) => {
      const { activityKey, departmentKey } = action.payload;

      state.createActivity.data = activityKey;

      const activityListItem = createActivityFormToActivityListItem(
        action.meta.arg,
        activityKey,
        departmentKey,
      );

      activityListItemAdapter.addOne(state.activities.data, activityListItem);

      state.createActivity.status = 'idle';
      state.activityDraft.data = null;
    })
    .addCase(createActivityThunk.pending, (state) => {
      state.createActivity.status = 'pending';
      state.createActivity.error = null;
      state.createActivity.originalError = undefined;
    })
    .addCase(createActivityThunk.rejected, (state, action) => {
      state.createActivity.status = 'idle';

      state.createActivity.error = action.error;

      if (action.payload && 'message' in action.payload) {
        state.createActivity.originalError = action.payload.message;
      }
    });
};

const getActivityBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityThunk.fulfilled, (state, action) => {
      const minTicketCounts: Record<string, number> = {};

      action.payload.stats?.ticketTypeStats?.forEach((ticketType) => {
        minTicketCounts[ticketType.ticketTypeKey] =
          ticketType.numberOfSoldTickets;
      });

      state.activity.data = action.payload;
      state.activity.status = 'idle';
    })
    .addCase(getActivityThunk.pending, (state) => {
      state.activity.status = 'pending';
      state.activity.error = null;
    })
    .addCase(getActivityThunk.rejected, (state, action) => {
      state.activity.status = 'idle';
      state.activity.error = action.error;
    });
};

const updateActivityBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(updateActivityThunk.fulfilled, (state, action) => {
      activityListItemAdapter.updateOne(state.activities.data, {
        id: action.meta.arg.activityKey,
        changes: updateActivityFormToActivityListItem(
          action.meta.arg,
          action.payload.departmentKey,
        ),
      });

      state.updateActivity.status = 'idle';
      state.updateActivity.data = action.meta.arg.activityKey;
    })

    .addCase(updateActivityThunk.pending, (state) => {
      state.updateActivity.status = 'pending';
      state.updateActivity.error = null;
    })

    .addCase(updateActivityThunk.rejected, (state, action) => {
      state.updateActivity.status = 'idle';
      state.updateActivity.error = action.error;

      if (action.payload?.kind !== 'bad-request') return;
      if (typeof action.payload?.message !== 'object') return;

      const metadata = action.payload.message.metadata;
      if (!metadata) return;

      const activity = state.activity.data;

      const ticketType = activity?.ticketTypes?.find((ticketType) => {
        return ticketType.ticketTypeKey === metadata.ticketTypeKey;
      });
      if (!ticketType) return;

      const ticketTypeStats = activity?.stats?.ticketTypeStats.find(
        (ticketTypeStats) => {
          return ticketTypeStats.ticketTypeKey === metadata.ticketTypeKey;
        },
      );
      if (!ticketTypeStats) return;

      if ('availableTicketCount' in metadata) {
        ticketTypeStats.numberOfSoldTickets =
          ticketType.ticketCount - metadata.availableTicketCount;
      }

      if ('reservedTicketCount' in metadata) {
        ticketTypeStats.numberOfSoldTickets = metadata.reservedTicketCount;
      }
    });
};

const unpublishActivityBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(unpublishActivityThunk.fulfilled, (state, action) => {
      if (state.activity.data) {
        state.activity.data.endDate = 0;
        state.activity.data.startDate = 0;
      }

      activityListItemAdapter.updateOne(state.activities.data, {
        id: action.meta.arg,
        changes: { endDate: 0, startDate: 0 },
      });

      state.unpublishActivity.status = 'idle';
    })
    .addCase(unpublishActivityThunk.pending, (state) => {
      state.unpublishActivity.status = 'pending';
      state.unpublishActivity.error = null;
    })
    .addCase(unpublishActivityThunk.rejected, (state, action) => {
      state.unpublishActivity.status = 'idle';
      state.unpublishActivity.error = action.error;
    });
};

const createDynamicActivityLinkBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(createDynamicActivityLinkThunk.fulfilled, (state, action) => {
      state.dynamicActivityLink.status = 'idle';
      state.dynamicActivityLink.data = action.payload;
    })
    .addCase(createDynamicActivityLinkThunk.pending, (state) => {
      state.dynamicActivityLink.status = 'pending';
      state.dynamicActivityLink.error = null;
    })
    .addCase(createDynamicActivityLinkThunk.rejected, (state, action) => {
      state.dynamicActivityLink.status = 'idle';
      state.dynamicActivityLink.error = action.error;
    });
};

const getActivityRecordsBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityRecordsThunk.fulfilled, (state, action) => {
      state.activityRecords.status = 'idle';
      state.activityRecords.data = action.payload;
    })
    .addCase(getActivityRecordsThunk.pending, (state) => {
      state.activityRecords.status = 'pending';
      state.activityRecords.error = null;
    })
    .addCase(getActivityRecordsThunk.rejected, (state, action) => {
      state.activityRecords.status = 'idle';
      state.activityRecords.error = action.error;
    });
};

const coHostingRequestsBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getCoHostingRequestsThunk.fulfilled, (state, action) => {
      state.coHostingRequests.status = 'idle';

      coHostingAdapter.setAll(state.coHostingRequests.data, action.payload);
    })
    .addCase(getCoHostingRequestsThunk.pending, (state) => {
      state.coHostingRequests.status = 'pending';
      state.coHostingRequests.error = null;
    })
    .addCase(getCoHostingRequestsThunk.rejected, (state, action) => {
      state.coHostingRequests.status = 'idle';
      state.coHostingRequests.error = action.error;
    });
};

const updateCoHostingRequestBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(updateCohostingRequestThunk.fulfilled, (state, action) => {
      state.updateCoHostingRequest.status = 'idle';

      coHostingAdapter.removeOne(
        state.coHostingRequests.data,
        action.meta.arg.cohostRequestKey,
      );
    })
    .addCase(updateCohostingRequestThunk.pending, (state) => {
      state.updateCoHostingRequest.status = 'pending';
      state.updateCoHostingRequest.error = null;
    })
    .addCase(updateCohostingRequestThunk.rejected, (state, action) => {
      state.updateCoHostingRequest.status = 'idle';
      state.updateCoHostingRequest.error = action.error;
    });
};

const createActivityDraftBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(createActivityDraftThunk.fulfilled, (state, action) => {
      state.createActivityDraft.status = 'idle';
      state.createActivityDraft.data = action.payload.key;
      state.activityDraft.data = action.payload;
      draftAdapter.addOne(state.activityDrafts.data, action.payload);
    })
    .addCase(createActivityDraftThunk.pending, (state) => {
      state.createActivityDraft.status = 'pending';
      state.createActivityDraft.error = null;
    })
    .addCase(createActivityDraftThunk.rejected, (state, action) => {
      state.createActivityDraft.status = 'idle';
      state.createActivityDraft.error = action.error;
    });
};

const getActivityDraftsBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityDraftsThunk.fulfilled, (state, action) => {
      state.activityDrafts.status = 'idle';
      draftAdapter.setMany(state.activityDrafts.data, action.payload);
    })
    .addCase(getActivityDraftsThunk.pending, (state) => {
      state.activityDrafts.status = 'pending';
      state.activityDrafts.error = null;
    })
    .addCase(getActivityDraftsThunk.rejected, (state, action) => {
      state.activityDrafts.status = 'idle';
      state.activityDrafts.error = action.error;
    });
};

const deleteActivityDraftBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(deleteActivityDraftThunk.fulfilled, (state, action) => {
      state.deleteActivityDraft.status = 'idle';
      draftAdapter.removeOne(state.activityDrafts.data, action.payload);
    })
    .addCase(deleteActivityDraftThunk.pending, (state) => {
      state.deleteActivityDraft.status = 'pending';
      state.deleteActivityDraft.error = null;
    })
    .addCase(deleteActivityDraftThunk.rejected, (state, action) => {
      state.deleteActivityDraft.status = 'idle';
      state.deleteActivityDraft.error = action.error;
    });
};

const activityDraftBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityDraftThunk.fulfilled, (state, action) => {
      state.activityDraft.status = 'idle';
      state.activityDraft.data = action.payload;
    })
    .addCase(getActivityDraftThunk.pending, (state) => {
      state.activityDraft.status = 'pending';
      state.activityDraft.error = null;
    })
    .addCase(getActivityDraftThunk.rejected, (state, action) => {
      state.activityDraft.status = 'idle';
      state.activityDraft.error = action.error;
    });
};

const getActivitiesBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivitiesThunk.fulfilled, (state, action) => {
      activityListItemAdapter.setMany(state.activities.data, action.payload);
      state.activities.status = 'idle';
    })
    .addCase(getActivitiesThunk.pending, (state, action) => {
      state.activities.error = null;
      state.activities.pagination = action.meta.arg;
      state.activities.status = 'pending';
    })
    .addCase(getActivitiesThunk.rejected, (state, action) => {
      state.activities.error = action.error;
      state.activities.status = 'idle';
    });
};

const qrScanTokensBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getQrScanTokensThunk.fulfilled, (state, action) => {
      qrScanTokensAdapter.setMany(state.qrScanTokens.data, action.payload);

      state.qrScanTokens.status = 'idle';
    })
    .addCase(getQrScanTokensThunk.pending, (state) => {
      state.qrScanTokens.error = null;
      state.qrScanTokens.status = 'pending';
    })
    .addCase(getQrScanTokensThunk.rejected, (state, action) => {
      state.qrScanTokens.error = action.error;
      state.qrScanTokens.status = 'idle';
    });
};

const createQrScanTokenBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(createQrScanTokenThunk.fulfilled, (state, action) => {
      state.createQrScanToken.data = action.payload.qrScanTokenKey;
      state.createQrScanToken.status = 'idle';

      qrScanTokensAdapter.addOne(state.qrScanTokens.data, action.payload);
    })
    .addCase(createQrScanTokenThunk.pending, (state) => {
      state.createQrScanToken.error = null;
      state.createQrScanToken.status = 'pending';
    })
    .addCase(createQrScanTokenThunk.rejected, (state, action) => {
      state.createQrScanToken.error = action.error;
      state.createQrScanToken.status = 'idle';
    });
};

const deleteQrScanTokenBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(deleteQrScanTokenThunk.fulfilled, (state, action) => {
      state.deleteQrScanToken.status = 'idle';

      qrScanTokensAdapter.removeOne(state.qrScanTokens.data, action.meta.arg);
    })
    .addCase(deleteQrScanTokenThunk.pending, (state) => {
      state.deleteQrScanToken.error = null;
      state.deleteQrScanToken.status = 'pending';
    })
    .addCase(deleteQrScanTokenThunk.rejected, (state, action) => {
      state.deleteQrScanToken.error = action.error;
      state.deleteQrScanToken.status = 'idle';
    });
};

const freetextListItemsBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getFreeTextListItemsThunk.fulfilled, (state, action) => {
      freetextListItemsAdapter.setMany(
        state.freetextListItems.data,
        action.payload,
      );

      state.freetextListItems.status = 'idle';
    })
    .addCase(getFreeTextListItemsThunk.pending, (state, action) => {
      state.freetextListItems.pagination = action.meta.arg;
      state.freetextListItems.error = null;
      state.freetextListItems.status = 'pending';
    })
    .addCase(getFreeTextListItemsThunk.rejected, (state, action) => {
      state.freetextListItems.error = action.error;
      state.freetextListItems.status = 'idle';
    });
};

const getRequestedInfoStatsBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getRequestedInfoStatsThunk.fulfilled, (state, action) => {
      state.requestedInfoStats.data = action.payload;
      state.requestedInfoStats.status = 'idle';
    })
    .addCase(getRequestedInfoStatsThunk.pending, (state) => {
      state.requestedInfoStats.error = null;
      state.requestedInfoStats.status = 'pending';
    })
    .addCase(getRequestedInfoStatsThunk.rejected, (state, action) => {
      state.requestedInfoStats.error = action.error;
      state.requestedInfoStats.status = 'idle';
    });
};

const getActivityUsersBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityUsersThunk.fulfilled, (state, action) => {
      state.activityUsers.data = action.payload;
      state.activityUsers.status = 'idle';
    })
    .addCase(getActivityUsersThunk.pending, (state) => {
      state.activityUsers.error = null;
      state.activityUsers.status = 'pending';
    })
    .addCase(getActivityUsersThunk.rejected, (state, action) => {
      state.activityUsers.error = action.error;
      state.activityUsers.status = 'idle';
    });
};

const getActivityDiscountBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityDiscountStatsThunk.fulfilled, (state, action) => {
      state.discountStats.data = action.payload;
      state.discountStats.status = 'idle';
    })
    .addCase(getActivityDiscountStatsThunk.pending, (state) => {
      state.discountStats.error = null;
      state.discountStats.status = 'pending';
    })
    .addCase(getActivityDiscountStatsThunk.rejected, (state, action) => {
      state.discountStats.error = action.error;
      state.discountStats.status = 'idle';
    });
};

const getTicketListItemsBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getTicketListItemsThunk.fulfilled, (state, action) => {
      if (state.ticketListItems.search) {
        ticketsAdapter.setAll(state.ticketListItems.data, action.payload);
      } else {
        ticketsAdapter.setMany(state.ticketListItems.data, action.payload);
      }
      state.ticketListItems.status = 'idle';
    })
    .addCase(getTicketListItemsThunk.pending, (state, action) => {
      state.ticketListItems.error = null;
      state.ticketListItems.pagination = action.meta.arg;
      state.ticketListItems.status = 'pending';
    })
    .addCase(getTicketListItemsThunk.rejected, (state, action) => {
      state.ticketListItems.error = action.error;
      state.ticketListItems.status = 'idle';
    });
};

const getTicketsHistoryBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getTicketsHistoryThunk.fulfilled, (state, action) => {
      ticketsHistoryAdapter.setAll(state.ticketsHistory.data, action.payload);
      state.ticketsHistory.status = 'idle';
    })
    .addCase(getTicketsHistoryThunk.pending, (state) => {
      state.ticketsHistory.status = 'pending';
      state.ticketsHistory.error = null;
    })
    .addCase(getTicketsHistoryThunk.rejected, (state, action) => {
      state.ticketsHistory.status = 'idle';
      state.ticketsHistory.error = action.error;
    });
};

const refundTicketBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(refundTicketThunk.fulfilled, (state, action) => {
      state.refundTicket.status = 'idle';
      state.selectedTicketListItem = null;
      ticketsAdapter.removeOne(
        state.ticketListItems.data,
        action.meta.arg.ticketKey,
      );
    })
    .addCase(refundTicketThunk.pending, (state) => {
      state.refundTicket.status = 'pending';
      state.refundTicket.error = null;
    })
    .addCase(refundTicketThunk.rejected, (state, action) => {
      state.refundTicket.status = 'idle';
      state.refundTicket.error = action.error;
    });
};

const consumeTicketBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(consumeTicketThunk.fulfilled, (state, action) => {
      const now = Date.now();

      ticketsAdapter.updateOne(state.ticketListItems.data, {
        id: action.meta.arg.ticketKey,
        changes: { consumedAt: now },
      });

      if (state.selectedTicketListItem) {
        state.selectedTicketListItem.consumedAt = now;
      }

      state.consumeTicket.status = 'idle';
    })
    .addCase(consumeTicketThunk.pending, (state) => {
      state.consumeTicket.status = 'pending';
      state.consumeTicket.error = null;
    })
    .addCase(consumeTicketThunk.rejected, (state, action) => {
      state.consumeTicket.status = 'idle';
      state.consumeTicket.error = action.error;
    });
};

const unconsumeTicketBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(unconsumeTicketThunk.fulfilled, (state, action) => {
      state.undoConsumeTicket.status = 'idle';

      ticketsAdapter.updateOne(state.ticketListItems.data, {
        id: action.meta.arg.ticketKey,
        changes: { consumedAt: null },
      });

      if (state.selectedTicketListItem) {
        state.selectedTicketListItem.consumedAt = null;
      }

      state.consumeTicket.status = 'idle';
    })
    .addCase(unconsumeTicketThunk.pending, (state) => {
      state.undoConsumeTicket.status = 'pending';
      state.undoConsumeTicket.error = null;
    })
    .addCase(unconsumeTicketThunk.rejected, (state, action) => {
      state.undoConsumeTicket.status = 'idle';
      state.undoConsumeTicket.error = action.error;
    });
};

const getTicketDetailsBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getTicketDetailsThunk.fulfilled, (state, action) => {
      state.ticketDetails.status = 'idle';

      const ticketKey = state.selectedTicketListItem?.ticketKey;

      if (!ticketKey) {
        return;
      }

      const isEmailTransferred =
        action.payload.ticketTransferHistory?.some(
          (transfer) => transfer.transferType === 'email',
        ) !== undefined;

      const changes: Partial<TicketListItem> = {
        emailTransfer: isEmailTransferred,
        details: {
          paidAmount: action.payload.paidAmount,
          requestedInfoAnswers: action.payload.requestedInfoAnswers,
          ticketTransferHistory: action.payload.ticketTransferHistory,
          usedDiscount: action.payload.usedDiscount,
        },
      };

      ticketsAdapter.updateOne(state.ticketListItems.data, {
        id: ticketKey,
        changes,
      });

      if (!state.selectedTicketListItem) {
        return;
      }

      state.selectedTicketListItem = {
        ...state.selectedTicketListItem,
        ...changes,
      };
    })
    .addCase(getTicketDetailsThunk.pending, (state) => {
      state.ticketDetails.status = 'pending';
      state.ticketDetails.error = null;
    })
    .addCase(getTicketDetailsThunk.rejected, (state, action) => {
      state.ticketDetails.status = 'idle';
      state.ticketDetails.error = action.error;
    });
};

const scanTicketBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(scanTicketThunk.fulfilled, (state, action) => {
      state.scanTicket.data = action.payload;
      state.scanTicket.status = 'idle';
    })
    .addCase(scanTicketThunk.pending, (state) => {
      state.scanTicket.status = 'pending';
      state.scanTicket.error = null;
      state.scanTicket.data = null;
    })
    .addCase(scanTicketThunk.rejected, (state, action) => {
      state.scanTicket.status = 'idle';
      state.scanTicket.error = action.error;
    });
};

const undoScanTicketBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(undoScanTicketThunk.fulfilled, (state) => {
      state.undoScanTicket.status = 'idle';
      state.scanTicket.data = null;
    })
    .addCase(undoScanTicketThunk.pending, (state) => {
      state.undoScanTicket.status = 'pending';
      state.undoScanTicket.error = null;
    })
    .addCase(undoScanTicketThunk.rejected, (state, action) => {
      state.undoScanTicket.status = 'idle';
      state.undoScanTicket.error = action.error;
    });
};

const getActivityUsersDataBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityUsersDataThunk.fulfilled, (state, action) => {
      state.activityUsersData.status = 'idle';
    })
    .addCase(getActivityUsersDataThunk.pending, (state) => {
      state.activityUsersData.status = 'pending';
      state.activityUsersData.error = null;
    })
    .addCase(getActivityUsersDataThunk.rejected, (state, action) => {
      state.activityUsersData.status = 'idle';
      state.activityUsersData.error = action.error;
    });
};

const getActivityCategoriesBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(getActivityCategoriesThunk.fulfilled, (state, action) => {
      state.activityCategories.data = action.payload;
      state.activityCategories.status = 'idle';
    })
    .addCase(getActivityCategoriesThunk.pending, (state) => {
      state.activityCategories.status = 'pending';
      state.activityCategories.error = null;
    })
    .addCase(getActivityCategoriesThunk.rejected, (state, action) => {
      state.activityCategories.status = 'idle';
      state.activityCategories.error = action.error;
    });
};

const suggestActivityCategoriesBuild = (builder: ActivityBuilder) => {
  return builder
    .addCase(suggestActivityCategoriesThunk.fulfilled, (state, action) => {
      state.suggestActivityCategories.data = action.payload;
      state.suggestActivityCategories.status = 'idle';
    })
    .addCase(suggestActivityCategoriesThunk.pending, (state) => {
      state.suggestActivityCategories.status = 'pending';
      state.suggestActivityCategories.error = null;
    })
    .addCase(suggestActivityCategoriesThunk.rejected, (state, action) => {
      state.suggestActivityCategories.status = 'idle';
      state.suggestActivityCategories.error = action.error;
    });
};

export const extraReducers = (builder: ActivityBuilder) => {
  createActivityBuild(builder);
  getActivityBuild(builder);
  getActivitiesBuild(builder);
  getActivityRecordsBuild(builder);
  updateActivityBuild(builder);
  unpublishActivityBuild(builder);
  createDynamicActivityLinkBuild(builder);

  coHostingRequestsBuild(builder);
  updateCoHostingRequestBuild(builder);

  activityDraftBuild(builder);
  createActivityDraftBuild(builder);
  deleteActivityDraftBuild(builder);
  getActivityDraftsBuild(builder);

  createQrScanTokenBuild(builder);
  deleteQrScanTokenBuild(builder);
  qrScanTokensBuild(builder);

  freetextListItemsBuild(builder);
  getActivityUsersBuild(builder);
  getRequestedInfoStatsBuild(builder);
  getActivityDiscountBuild(builder);

  consumeTicketBuild(builder);
  getTicketDetailsBuild(builder);
  getTicketListItemsBuild(builder);
  getTicketsHistoryBuild(builder);
  refundTicketBuild(builder);
  scanTicketBuild(builder);
  unconsumeTicketBuild(builder);
  undoScanTicketBuild(builder);
  getActivityUsersDataBuild(builder);

  getActivityCategoriesBuild(builder);
  suggestActivityCategoriesBuild(builder);
};
