import { NavigateWithQuery } from '@orbiapp/components';
import React from 'react';

import { AuthStateSelector, useSelector } from '../store';

type RouteProps = React.PropsWithChildren<{
  redirectTo?: string;
}>;

export function AuthenticatedRoute(props: RouteProps) {
  const { children, redirectTo = '/sign-in' } = props;

  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);

  if (!authenticated) {
    return (
      <NavigateWithQuery
        to={redirectTo}
        state={{ returnTo: window.location.pathname }}
      />
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export function UnauthenticatedRoute(props: RouteProps) {
  const { children, redirectTo = '/activities' } = props;

  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);

  if (authenticated) {
    return <NavigateWithQuery to={redirectTo} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export function FallbackNavigate() {
  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);

  if (authenticated) {
    <NavigateWithQuery replace to="/activities" />;
  }

  return <NavigateWithQuery replace to="/sign-in" />;
}
