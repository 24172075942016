import {
  Box,
  Button,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  Modal,
  NavigateWithQuery,
  ResponsiveBox,
  SolidIconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  formatDate,
  parseCurrency,
  translate,
  useConfirm,
  useModalState,
} from '@orbiapp/components';
import React from 'react';

import {
  MembershipPeriodForm,
  MembershipTypePeriod,
} from '../../../../../models';
import {
  DeletePeriodSelector,
  MembershipTypeSelector,
  OrbiPaySettingsSelector,
  UpdatePeriodSelector,
  createPeriodThunk,
  deletePeriodThunk,
  updatePeriodThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { getPeriodFormDefaultValues } from '../../../../../utils';
import { EditPeriodForm, PeriodForm } from '../../components/period-form';
import {
  SummaryList,
  SummaryListItem,
  SummaryListItemGrid,
} from '../../components/summary-list';
import {
  PeriodsSidebarContextType,
  PeriodsSidebarState,
} from './periods.types';

const PeriodsSidebarContext = React.createContext<PeriodsSidebarContextType>({
  state: null,
  setState: () => {},
});

function PeriodsSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [state, setState] = React.useState<PeriodsSidebarState>(null);

  return (
    <PeriodsSidebarContext.Provider value={{ state, setState }}>
      {children}
    </PeriodsSidebarContext.Provider>
  );
}

function PeriodsEmptyState() {
  const { state, setState } = React.useContext(PeriodsSidebarContext);

  const openSidebar = () => {
    setState({ mode: 'create', data: getPeriodFormDefaultValues() });
  };

  return (
    <EmptyState
      buttonOnClick={openSidebar}
      buttonTx="label.memberships.periods.empty-state.button"
      disabled={state?.mode === 'create'}
      titleTx="label.memberships.periods.empty-state.label"
    />
  );
}

function AddPeriodButton() {
  const { state, setState } = React.useContext(PeriodsSidebarContext);

  const addNewPeriod = () => {
    setState({ mode: 'create', data: getPeriodFormDefaultValues() });
  };

  return (
    <ResponsiveBox
      xs={
        <Button
          disabled={state?.mode === 'create'}
          onClick={addNewPeriod}
          tx="label.memberships.periods.empty-state.button"
          variant="secondary"
        />
      }
    >
      <Tooltip
        placement="left"
        titleTx="label.memberships.periods.empty-state.button"
      >
        <SolidIconButton
          disabled={state?.mode === 'create'}
          icon="plus-circle-outline"
          onClick={addNewPeriod}
        />
      </Tooltip>
    </ResponsiveBox>
  );
}

function PeriodsContent() {
  const periods = useSelector(MembershipTypeSelector.selectMembershipPeriods);
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);
  const deletePeriodStatus = useSelector(DeletePeriodSelector.selectStatus);

  const dispatch = useDispatch();

  const { state, setState } = React.useContext(PeriodsSidebarContext);

  const { confirmValue, closeConfirm, isOpen, openConfirm } =
    useConfirm<string>();

  if (!periods || periods.length === 0) {
    return <PeriodsEmptyState />;
  }

  const removePeriod = () => {
    if (!confirmValue) return;

    dispatch(deletePeriodThunk(confirmValue));

    if (state?.data.key === confirmValue) {
      setState(null);
    }

    closeConfirm();
  };

  const renderPeriodTableRow = (period: MembershipTypePeriod) => {
    const stagePeriodForRemove: React.MouseEventHandler<HTMLButtonElement> = (
      e,
    ) => {
      e.stopPropagation();

      openConfirm(period.key);
    };

    const selectRow = () => {
      setState({ mode: 'view', data: period });
    };

    return (
      <TableRow
        highlight={state?.data.key === period.key}
        key={period.key}
        onClick={selectRow}
      >
        <TableCell text={period.name} />
        <TableCell
          text={
            period.validFrom
              ? formatDate(period.validFrom, 'DD MMM YYYY HH:mm')
              : translate('label.memberships.update-period-modal.lifetime')
          }
        />
        <TableCell
          text={
            period.validTo
              ? formatDate(period.validTo, 'DD MMM YYYY HH:mm')
              : translate('label.memberships.update-period-modal.lifetime')
          }
        />
        <TableCell text={formatDate(period.saleStart, 'DD MMM YYYY HH:mm')} />
        <TableCell text={formatDate(period.saleEnd, 'DD MMM YYYY HH:mm')} />
        <TableCell text={parseCurrency(period.price, currency)} />
        <TableCell width={40} hoverCell fixedRight>
          <IconButton icon="trash-outline" onClick={stagePeriodForRemove} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Box flex flexJustify="between" flexWrap="wrap" gap={16}>
        <Text
          color="pageTitle"
          as="h1"
          tx="label.memberships.view-membership.tabs.periods"
          variant="titleMd"
        />

        <AddPeriodButton />
      </Box>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.create-membership.periods.name.label" />
            <TableHead tx="label.memberships.create-membership.periods.valid-from.label" />
            <TableHead tx="label.memberships.create-membership.periods.valid-to.label" />
            <TableHead tx="label.memberships.create-membership.periods.sale-start.label" />
            <TableHead tx="label.memberships.create-membership.periods.sale-end.label" />
            <TableHead tx="label.memberships.create-membership.periods.price.label" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>{periods.map(renderPeriodTableRow)}</TableBody>
      </Table>

      <Confirm
        cancelTx="prompt.delete-period.cancel"
        confirmTx="prompt.delete-period.confirm"
        isLoading={deletePeriodStatus === 'pending'}
        isOpen={isOpen}
        messageTx="prompt.delete-period.message"
        onCancel={closeConfirm}
        onConfirm={removePeriod}
        titleTx="prompt.delete-period.title"
      />
    </React.Fragment>
  );
}

function CreatePeriodSidebar() {
  const { state, setState } = React.useContext(PeriodsSidebarContext);

  const dispatch = useDispatch();

  const closeSidebar = () => {
    setState(null);
  };

  const addPeriod = async (period: MembershipPeriodForm) => {
    const res = await dispatch(createPeriodThunk(period));

    if (res.meta.requestStatus === 'fulfilled') {
      closeSidebar();
    }
  };

  return (
    <ContentSidebar
      isOpen={state?.mode === 'create'}
      onClose={closeSidebar}
      width={470}
    >
      <PeriodForm
        titleTx="label.memberships.create-membership.periods.sidebar.title"
        defaultValues={getPeriodFormDefaultValues()}
        onSubmitted={addPeriod}
      />
    </ContentSidebar>
  );
}

function ViewPeriodSidebarContent() {
  const { state } = React.useContext(PeriodsSidebarContext);

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  if (!state?.data) {
    return null;
  }

  return (
    <React.Fragment>
      <Text
        variant="bodyLgBold"
        tx="label.memberships.view-membership.periods.period"
      />

      <SummaryList>
        <SummaryListItem
          titleTx="label.memberships.create-membership.summary.period-name"
          subtitle={state.data.name}
        />

        <SummaryListItemGrid>
          <SummaryListItem
            titleTx="label.memberships.create-membership.summary.valid-from"
            subtitle={
              state.data.validFrom
                ? formatDate(state.data.validFrom, 'DD MMM YYYY HH:mm')
                : translate('label.memberships.update-period-modal.lifetime')
            }
          />

          <SummaryListItem
            titleTx="label.memberships.create-membership.summary.valid-to"
            subtitle={
              state.data.validTo
                ? formatDate(state.data.validTo, 'DD MMM YYYY HH:mm')
                : translate('label.memberships.update-period-modal.lifetime')
            }
          />
        </SummaryListItemGrid>

        <SummaryListItemGrid>
          <SummaryListItem
            titleTx="label.memberships.create-membership.summary.sale-start"
            subtitle={formatDate(state.data.saleStart, 'DD MMM YYYY HH:mm')}
          />
          <SummaryListItem
            titleTx="label.memberships.create-membership.summary.sale-end"
            subtitle={formatDate(state.data.saleEnd, 'DD MMM YYYY HH:mm')}
          />
        </SummaryListItemGrid>

        <SummaryListItem
          titleTx="label.memberships.create-membership.summary.price"
          subtitle={parseCurrency(state.data.price, currency)}
        />
      </SummaryList>
    </React.Fragment>
  );
}

function ViewPeriodSidebar() {
  const updatePeriodStatus = useSelector(UpdatePeriodSelector.selectStatus);

  const dispatch = useDispatch();

  const { state, setState } = React.useContext(PeriodsSidebarContext);

  const { closeModal, isOpen, openModal } = useModalState();

  const closeSidebar = () => {
    setState(null);
  };

  const savePeriod = async (period: MembershipPeriodForm) => {
    const res = await dispatch(updatePeriodThunk(period));

    if (res.meta.requestStatus === 'fulfilled') {
      setState({
        mode: 'view',
        data: {
          ...state?.data,
          ...period,
        },
      });

      closeModal();
    }
  };

  return (
    <React.Fragment>
      <ContentSidebar
        isOpen={state?.mode === 'view'}
        onClose={closeSidebar}
        width={470}
      >
        <ContentSidebarContentContainer>
          <ViewPeriodSidebarContent />
        </ContentSidebarContentContainer>

        <ContentSidebarFooterContainer flexJustify="end">
          <Button
            onClick={openModal}
            tx="label.memberships.view-membership.periods.edit-period"
            variant="secondary"
          />
        </ContentSidebarFooterContainer>
      </ContentSidebar>

      <Modal width={800} isOpen={isOpen} onClose={closeModal}>
        {state?.data && (
          <EditPeriodForm
            isLoading={updatePeriodStatus === 'pending'}
            defaultValues={{
              key: state.data.key,
              lifetime: !state.data.validTo,
              name: state.data.name,
              price: state.data.price,
              saleEnd: state.data.saleEnd,
              saleStart: state.data.saleStart,
              validFrom: state.data.validFrom,
              validTo: state.data.validTo,
            }}
            closeModal={closeModal}
            onSubmitted={savePeriod}
          />
        )}
      </Modal>
    </React.Fragment>
  );
}

export function ViewMembershipPeriods() {
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  if (isIntegrated) {
    return <NavigateWithQuery to="overview" />;
  }

  return (
    <PeriodsSidebarProvider>
      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <PeriodsContent />
          </InnerContentContainer>
        </ContentContainer>

        <CreatePeriodSidebar />
        <ViewPeriodSidebar />
      </InnerPageContainer>
    </PeriodsSidebarProvider>
  );
}
