import {
  Banner,
  EmulateUserSessionStorage,
  FeatureFlagProvider,
  InternetStatusBanner,
  MainContainer,
  OrbiLoader,
  WorkspacesSessionStorage,
} from '@orbiapp/components';
import i18next from 'i18next';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { FeatureReleaseModals, OrbiPayBanner } from '../../components';
import { STATSIG_ENV, STATSIG_SDK_KEY } from '../../config';
import { usePendo, useTracking, useUserPilot } from '../../helpers';
import {
  AccountSelector,
  AuthAccountSelector,
  AuthStateSelector,
  DepartmentSelector,
  OrbiPaySettingsSelector,
  authActions,
  getAccountThunk,
  getAuthAccountThunk,
  getDepartmentThunk,
  getOrbiPaySettingsThunk,
  useDispatch,
  useSelector,
} from '../../store';
import { isAnyPending } from '../../utils';

function EmulateUserBanner() {
  const userEmail = useSelector(AccountSelector.selectEmail);
  const isEmulating = useSelector(AuthStateSelector.selectIsEmulating);

  const dispatch = useDispatch();

  const [isClosed, setIsClosed] = React.useState(false);

  if (isClosed || !userEmail || !isEmulating) {
    return null;
  }

  const stopEmulatingUser = async () => {
    dispatch(authActions.setIsEmulating(false));

    EmulateUserSessionStorage.endSession();
    WorkspacesSessionStorage.endSession();

    await dispatch(getAuthAccountThunk());
    await dispatch(getAccountThunk());
    dispatch(getDepartmentThunk());
  };

  const closeBanner = () => {
    setIsClosed(true);
  };

  return (
    <Banner
      buttonOnClick={stopEmulatingUser}
      buttonTx="button.cancel"
      message={userEmail}
      onClose={closeBanner}
      titleTx="label.emulate-user-banner.title"
      variant="warning"
      zIndex={200}
    />
  );
}

/**
 * Every route in the app is rendered within the `MainOutlet` component.
 */
export function MainOutlet() {
  useTracking();
  useUserPilot();
  usePendo();

  const userEmail = useSelector(AccountSelector.selectEmail);
  const userKey = useSelector(AccountSelector.selectUserKey);

  const initializing = useSelector(AuthStateSelector.selectInitializing);
  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);
  const authAccountStatus = useSelector(AuthAccountSelector.selectStatus);

  const language = useSelector(AccountSelector.selectLanguage);
  const accountStatus = useSelector(AccountSelector.selectStatus);
  const hasCorrectRole = useSelector(AccountSelector.selectHasCorrectRole);

  const departmentStatus = useSelector(DepartmentSelector.selectStatus);

  const orbiPayStatus = useSelector(OrbiPaySettingsSelector.selectStatus);

  const dispatch = useDispatch();

  const isLoadingEssentials = isAnyPending(
    accountStatus,
    departmentStatus,
    authAccountStatus,
    orbiPayStatus,
  );

  const getEssentials = React.useCallback(async () => {
    if (!authenticated) return;
    await dispatch(getAccountThunk());
    dispatch(getAuthAccountThunk());
  }, [authenticated, dispatch]);

  React.useEffect(() => {
    if (!hasCorrectRole) return;
    dispatch(getDepartmentThunk());
    dispatch(getOrbiPaySettingsThunk());
  }, [hasCorrectRole, dispatch]);

  React.useEffect(() => {
    getEssentials();
  }, [getEssentials]);

  React.useEffect(() => {
    if (!language) return;
    i18next.changeLanguage(language);
  }, [language]);

  if (
    initializing ||
    isLoadingEssentials ||
    (authenticated && accountStatus !== 'completed') ||
    (hasCorrectRole && departmentStatus !== 'completed')
  ) {
    return <OrbiLoader />;
  }

  return (
    <FeatureFlagProvider
      userEmail={userEmail}
      userKey={userKey}
      sdkKey={STATSIG_SDK_KEY}
      env={STATSIG_ENV}
    >
      <MainContainer>
        <InternetStatusBanner
          offlineMessageTx="label.internet-status.offline-message"
          recentlyOnlineMessageTx="label.internet-status.recently-online-message"
        />
        <EmulateUserBanner />
        <OrbiPayBanner />

        <FeatureReleaseModals />

        <Outlet />
      </MainContainer>
    </FeatureFlagProvider>
  );
}
