import {
  Box,
  Button,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  Divider,
  EmptyState,
  IconButton,
  InnerContentContainer,
  List,
  ListItem,
  SearchInput,
  Status,
  StatusVariant,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  getAvatarVariantFromString,
} from '@orbiapp/components';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useDepartmentSearch } from '../../../../../helpers';
import { CoHost, PartialDepartment } from '../../../../../models';
import {
  DepartmentSelector,
  searchActions,
  useDispatch,
} from '../../../../../store';
import {
  getCoHostingStatusTx,
  getOptionalLabelText,
  joinOrgNodeBranchNames,
} from '../../../../../utils';
import {
  EventSubmitButton,
  GoBackButton,
  SaveActivityAsDraftButton,
  useRootPath,
} from '../components';
import { ActivityFormContext } from '../create-activity.context';

const CoHostsSidebarContext = React.createContext({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => {},
});

export function CoHostsSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <CoHostsSidebarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </CoHostsSidebarContext.Provider>
  );
}

function getCoHostingStatusVariant(
  cohostRequestStatus: CoHost['cohostRequestStatus'],
): StatusVariant {
  switch (cohostRequestStatus) {
    case 'accepted':
      return 'success';

    case 'pending':
      return 'info';

    case 'not-sent':
      return 'warning';

    case 'rejected':
    case 'canceled':
      return 'error';
  }
}

function CoHosts() {
  const { isOpen, setIsOpen } = React.useContext(CoHostsSidebarContext);

  const formContext = React.useContext(ActivityFormContext);

  const cohosts = formContext.watch('coHosting.cohosts');

  const addCoHosts = () => setIsOpen(true);

  const renderCoHostTableRow = (cohost: CoHost) => {
    const removeCoHost: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation();

      const newState = (cohosts ?? []).filter(
        (cohostItem) => cohostItem.departmentKey !== cohost.departmentKey,
      );

      formContext.setValue(
        'coHosting.cohosts',
        newState.length ? newState : null,
        {
          shouldDirty: true,
        },
      );
    };

    return (
      <TableRow key={cohost.departmentKey}>
        <TableCell text={cohost.name} />
        <TableCell>
          <Status
            tx={getCoHostingStatusTx(cohost.cohostRequestStatus)}
            variant={getCoHostingStatusVariant(cohost.cohostRequestStatus)}
          />
        </TableCell>
        <TableCell text={joinOrgNodeBranchNames(cohost.orgNodesBranch)} />
        <TableCell width={40} hoverCell fixedRight>
          <IconButton
            disabled={
              cohost.cohostRequestStatus !== 'not-sent' &&
              cohost.cohostRequestStatus !== 'pending'
            }
            icon="trash-outline"
            onClick={removeCoHost}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
        <Box
          height="fit-content"
          flexAlign="center"
          gap={16}
          flexWrap="wrap"
          flex
        >
          <GoBackButton />

          <Text
            as="h1"
            color="pageTitle"
            tx="label.create-activity.tabs.co-hosting.label"
            variant="titleMd"
          />
        </Box>

        <Box flex flexWrap="wrap" flexAlign="center" gap={16}>
          <SaveActivityAsDraftButton />

          <EventSubmitButton />
        </Box>
      </Box>

      <Box gap={16} flex flexDirection="column">
        <Box flex flexJustify="between">
          <Text
            text={getOptionalLabelText(
              'label.create-activity.co-hosting.steps.1',
            )}
            variant="bodyMdBold"
          />

          <IconButton
            disabled={isOpen}
            icon="plus-circle-outline"
            onClick={addCoHosts}
          />
        </Box>
        {cohosts?.length ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead tx="label.create-activity.co-hosting.department-name" />
                <TableHead tx="label.create-activity.co-hosting.status" />
                <TableHead tx="label.create-activity.co-hosting.org-node-branch" />
                <TableHead fixedRight />
              </TableRow>
            </TableHeader>

            <TableBody>{cohosts.map(renderCoHostTableRow)}</TableBody>
          </Table>
        ) : (
          <EmptyState
            buttonOnClick={addCoHosts}
            buttonTx="label.create-activity.co-hosting.empty-state.button"
            disabled={isOpen}
            titleTx="label.create-activity.co-hosting.empty-state.label"
          />
        )}
      </Box>
    </React.Fragment>
  );
}

export function AddCoHostsSidebar() {
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);

  const location = useLocation();

  const formContext = React.useContext(ActivityFormContext);

  const { isOpen, setIsOpen } = React.useContext(CoHostsSidebarContext);
  const { search, searchResult, searchString, setSearchString } =
    useDepartmentSearch();

  const [cohosts, setCoHosts] = React.useState<CoHost[]>([]);

  const dispatch = useDispatch();

  const closeSidebar = () => setIsOpen(false);

  const addCoHosts = () => {
    formContext.setValue(
      'coHosting.cohosts',
      [...(formContext.getValues('coHosting.cohosts') ?? []), ...cohosts],
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );

    setCoHosts([]);
    closeSidebar();
    dispatch(searchActions.clearDepartments());
    setSearchString('');
  };

  const selectedCoHostsKeysSet = new Set([
    ...cohosts.map((cohost) => cohost.departmentKey),
    ...(formContext.getValues('coHosting.cohosts') ?? [])?.map(
      (cohost) => cohost.departmentKey,
    ),
  ]);

  const handleSearchStringChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setSearchString(e.target.value);
    search(e.target.value);
  };

  const renderResultListItem = (partialDepartment: PartialDepartment) => {
    const addCoHost = () => {
      setCoHosts((prevCoHosts) => [
        ...prevCoHosts,
        {
          cohostRequestStatus: 'not-sent',
          departmentKey: partialDepartment.departmentKey,
          logoUrl: partialDepartment.logo?.thumbnail64.url ?? null,
          name: partialDepartment.name,
          orgNodesBranch: partialDepartment.orgNodeBranch,
        },
      ]);
    };

    return (
      <ListItem
        avatarFallbackLetter={partialDepartment.name[0]}
        avatarSrc={partialDepartment.logo?.thumbnail64.url}
        key={partialDepartment.departmentKey}
        onClick={addCoHost}
        title={partialDepartment.name}
        subtitle={joinOrgNodeBranchNames(partialDepartment.orgNodeBranch)}
        avatarVariant={getAvatarVariantFromString(
          partialDepartment.departmentKey,
        )}
      />
    );
  };

  const renderSelectedCoHostListItem = (selectedCoHostListItem: CoHost) => {
    const removeCoHost = () => {
      setCoHosts((prevCoHosts) =>
        prevCoHosts.filter(
          (cohost) =>
            cohost.departmentKey !== selectedCoHostListItem.departmentKey,
        ),
      );
    };

    return (
      <ListItem
        icon="check-circle-solid"
        iconColor="listItemIcon"
        avatarFallbackLetter={selectedCoHostListItem.name[0]}
        avatarSrc={selectedCoHostListItem.logoUrl ?? undefined}
        key={selectedCoHostListItem.departmentKey}
        onClick={removeCoHost}
        subtitle={joinOrgNodeBranchNames(selectedCoHostListItem.orgNodesBranch)}
        title={selectedCoHostListItem.name}
        avatarVariant={getAvatarVariantFromString(
          selectedCoHostListItem.departmentKey,
        )}
      />
    );
  };

  const renderResultList = searchResult.length > 0;
  const renderSelectedCoHosts = cohosts.length > 0;

  if (!location.pathname.endsWith('/co-hosting')) {
    return null;
  }

  return (
    <ContentSidebar width={470} isOpen={isOpen} onClose={closeSidebar}>
      <ContentSidebarContentContainer>
        <Text
          variant="bodyLgBold"
          tx="label.create-activity.co-hosting.add-cohosts"
        />

        <SearchInput
          value={searchString}
          onChange={handleSearchStringChange}
          placeholderTx="placeholder.search-for-departments"
        />

        {searchString.length > 0 && !renderResultList && (
          <Text
            textAlign="center"
            tx="placeholder.no-search-results"
            variant="bodySm"
          />
        )}

        {renderResultList && (
          <List>
            {searchResult
              .filter(
                (searchResultItem) =>
                  !selectedCoHostsKeysSet.has(searchResultItem.departmentKey) &&
                  searchResultItem.departmentKey !== departmentKey,
              )
              .map(renderResultListItem)}
          </List>
        )}

        {renderSelectedCoHosts && (
          <React.Fragment>
            {renderResultList && <Divider />}

            <List>{cohosts.map(renderSelectedCoHostListItem)}</List>
          </React.Fragment>
        )}
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button onClick={addCoHosts} tx="button.save" variant="secondary" />
      </ContentSidebarFooterContainer>
    </ContentSidebar>
  );
}

function TabFooter() {
  const rootPath = useRootPath();

  return (
    <Box p={32} flex flexJustify="between" gap={16}>
      <Tooltip titleTx="button.previous" placement="right">
        <IconButton
          to={`${rootPath}/request-more-info`}
          icon="arrow-left-circle-outline"
        />
      </Tooltip>

      <Tooltip placement="left" titleTx="button.continue">
        <IconButton
          to={`${rootPath}/addons`}
          icon="arrow-right-circle-outline"
        />
      </Tooltip>
    </Box>
  );
}

export function CreateActivityCoHosting() {
  return (
    <React.Fragment>
      <InnerContentContainer>
        <CoHosts />
      </InnerContentContainer>

      <TabFooter />
    </React.Fragment>
  );
}
