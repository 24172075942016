import {
  GeneralApiResult,
  GeneralApiResultWithData,
} from '@orbiapp/components';

import { ActivityDraft } from '../../../../../../models';
import { Logger } from '../../../../../logger';
import { apisauce } from '../../../../api';
import { getGeneralApiProblem } from '../../../../api.utils';
import { V1GetActivityDraft, V2GetActivityDraft } from './draft.dto';
import { V1DraftMappers, V2DraftMappers } from './draft.mappers';

const threshold = 1691744596578;

export const getActivityDrafts = async (): Promise<
  GeneralApiResultWithData<ActivityDraft[]>
> => {
  const res = await apisauce.get<
    Array<V2GetActivityDraft.ActivityDraftDto>,
    any
  >('/v1/activities/drafts');

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  if (!res.data) {
    Logger.error('getActivityDrafts', {
      err: 'Failed to get activity drafts',
    });
    return { kind: 'ok', data: [] };
  }

  return {
    kind: 'ok',
    data: res.data
      .map((draft) =>
        draft.lastUpdated > threshold
          ? V2DraftMappers.getActivityDraftMapper(
              draft as V2GetActivityDraft.ActivityDraftDto,
            )
          : V1DraftMappers.getActivityDraftMapper(
              draft as V1GetActivityDraft.ActivityDraftDto,
            ),
      )
      .sort((a, b) => b.lastUpdated - a.lastUpdated),
  };
};

export const getActivityDraft = async (
  draftKey: string,
): Promise<GeneralApiResultWithData<ActivityDraft>> => {
  const res = await apisauce.get<V2GetActivityDraft.ActivityDraftDto, any>(
    '/v1/activities/drafts/' + draftKey,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  if (!res.data) {
    Logger.error('getActivityDraft', {
      err: 'Failed to get activity draft',
    });
    return { kind: 'bad-request' };
  }

  const draft: ActivityDraft =
    res.data.lastUpdated > threshold
      ? V2DraftMappers.getActivityDraftMapper(
          res.data as V2GetActivityDraft.ActivityDraftDto,
        )
      : V1DraftMappers.getActivityDraftMapper(
          res.data as V1GetActivityDraft.ActivityDraftDto,
        );

  return { kind: 'ok', data: draft };
};

export const deleteActivityDraft = async (
  draftKey: string,
): Promise<GeneralApiResult> => {
  const res = await apisauce.delete<unknown, any>(
    '/v1/activities/drafts/' + draftKey,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};

export const createActivityDraft = async (
  activityDraft: ActivityDraft,
): Promise<GeneralApiResult> => {
  const dto = V2DraftMappers.createActivityDraftMapper(activityDraft);

  const res = await apisauce.put<unknown, any>(
    '/v1/activities/drafts/' + dto.key,
    dto,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
};
