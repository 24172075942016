import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  ContentContainer,
  Icon,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  LAYOUT_Z_INDEX,
  SolidIconButton,
  Text,
  Time,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  useFullscreen,
  useNavigateWithQuery,
} from '@orbiapp/components';
import React from 'react';

import { usePlaySound } from '../../../../helpers';
import { UIDRegexp } from '../../../../models';
import {
  ActivityDataSelector,
  ScanTicketSelector,
  activityActions,
  scanTicketThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { ScanQrCodesContainer } from '../shared/scan-qr-codes-container';
import { ScanResultCard } from '../shared/scan-result-card';
import {
  ShareScanLinkSidebar,
  ShareScanLinkSidebarContext,
} from '../shared/share-scan-link-sidebar';
import { ToggleFullscreenButton } from '../shared/toggle-fullscreen-button';
import { Styled } from './scan-with-device.styled';

const UID_MAX_LENGTH = 36;

function useQrScannerInput() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const inputRef = React.useRef<HTMLInputElement>(null);
  const timeoutBetweenSuccessfulScans = React.useRef(0);
  const mustFocusWindowRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigateWithQuery();

  const dispatch = useDispatch();

  const scanResultTicketStatus = useSelector(
    ScanTicketSelector.selectScannedTicketStatus,
  );

  const { enableSoundFeedback, toggleSoundFeedback, playSound } =
    usePlaySound();

  React.useEffect(() => {
    if (!scanResultTicketStatus) {
      return;
    }

    switch (scanResultTicketStatus) {
      case 'alreadyConsumed':
        playSound('failure');
        break;

      case 'successfullyConsumed':
        playSound('success');
        break;

      default:
        playSound('error');
    }
  }, [scanResultTicketStatus, playSound]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.focus();
  };

  const onInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!activityKey) {
      playSound('error');
      navigate('/activities');
      return;
    }

    if (
      timeoutBetweenSuccessfulScans.current ||
      e.target.value.length > UID_MAX_LENGTH
    ) {
      e.target.value = '';
      playSound('error');
      return;
    }

    e.target.value = e.target.value.replace('+', '-');

    if (!UIDRegexp.test(e.target.value)) return;

    timeoutBetweenSuccessfulScans.current = window.setTimeout(() => {
      timeoutBetweenSuccessfulScans.current = 0;
    }, 3 * Time.Second);

    const ticketPurchaseKey = e.target.value;

    await dispatch(scanTicketThunk({ activityKey, ticketPurchaseKey }));

    e.target.value = '';
  };

  React.useEffect(() => {
    window.onblur = () => {
      if (mustFocusWindowRef.current) {
        mustFocusWindowRef.current.style.display = 'flex';

        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
    };

    window.onfocus = () => {
      if (mustFocusWindowRef.current) {
        mustFocusWindowRef.current.style.display = 'none';
      }

      if (inputRef.current) {
        inputRef.current.value = '';
        inputRef.current.focus();
      }
    };

    if (!document.hasFocus()) {
      if (mustFocusWindowRef.current) {
        mustFocusWindowRef.current.style.display = 'flex';
      }
    }

    return () => {
      window.onfocus = null;
      window.onblur = null;
    };
  }, [inputRef]);

  const input = (
    <Styled.HiddenInput
      autoFocus
      onBlur={onBlur}
      onInput={onInput}
      ref={inputRef}
    />
  );

  return {
    enableSoundFeedback,
    input,
    mustFocusWindowRef,
    toggleSoundFeedback,
  };
}

function ScanWithQrScannerCard() {
  const {
    enableSoundFeedback,
    input,
    mustFocusWindowRef,
    toggleSoundFeedback,
  } = useQrScannerInput();

  const { isFullscreen, exitFullscreen } = useFullscreen();

  return (
    <Box gap={32} flex flexWrap="wrap" width="100%">
      <Styled.ScanWithQrScannerCard
        flex
        flexAlign="center"
        flexDirection="column"
        isFullscreen={isFullscreen}
        px={32}
        py={93}
        r={32}
        relative
      >
        {isFullscreen && (
          <Box
            absolute
            onClick={exitFullscreen}
            right={32}
            top={32}
            zIndex={LAYOUT_Z_INDEX.scanTickets.closeScanModalButton}
          >
            <Tooltip placement="left" titleTx="button.close">
              <SolidIconButton icon="x-mark" />
            </Tooltip>
          </Box>
        )}

        <Styled.MustFocusWindow ref={mustFocusWindowRef}>
          <Icon name="qr-code" size={144} />
          <Box>
            <Styled.MustFocusWindowText
              variant="titleMd"
              tx="label.scan-qr-codes.qr-scanner.window-out-of-focus"
            />
            <Styled.MustFocusWindowText
              variant="titleMd"
              tx="label.scan-qr-codes.qr-scanner.click-here-to-scan"
            />
          </Box>
        </Styled.MustFocusWindow>

        <Icon name="qr-code" size={144} textAlign="center" />

        <Text
          mt={16}
          variant="bodyMd"
          tx="label.scan-qr-codes.qr-scanner.title"
          textAlign="center"
        />

        {input}

        {!isFullscreen && (
          <Box absolute bottom={32} right={32}>
            <IconButton
              onClick={toggleSoundFeedback}
              icon={
                enableSoundFeedback
                  ? 'speaker-wave-outline'
                  : 'speaker-x-mark-outline'
              }
            />
          </Box>
        )}
      </Styled.ScanWithQrScannerCard>

      <ScanResultCard />
    </Box>
  );
}

function QrScannerModeContent() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const dispatch = useDispatch();

  const { shareLinkSidebarIsOpen, toggleSidebar } = React.useContext(
    ShareScanLinkSidebarContext,
  );

  React.useEffect(() => {
    return () => {
      dispatch(activityActions.clearScanResultData());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
        <Box flexWrap="wrap" flex gap={16}>
          <Box flex flexDirection="column" gap={4}>
            <Box flex gap={8} relative>
              <IconButton
                mt={4}
                to={`/activities/${activityKey}/consume-tickets/pick-scan-device`}
                icon="chevron-left"
              />

              <Text
                color="pageTitle"
                as="h1"
                tx="title.activities.consume-tickets"
                variant="titleMd"
              />
            </Box>

            <Text
              variant="bodyMd"
              ml={32}
              maxWidth="84ch"
              tx="label.scan-qr-codes.pick-device.header-text"
            />
          </Box>
        </Box>

        <Box gap={16} flex flexWrap>
          <Button
            variant="secondary"
            tx="label.scan-qr-codes.share-scan-link"
            icon={shareLinkSidebarIsOpen ? 'x-mark' : 'link'}
            onClick={toggleSidebar}
          />

          <Button
            icon="video-camera-outline"
            to={`/activities/${activityKey}/consume-tickets/camera`}
            tx="label.scan-qr-codes.qr-scanner.switch-action"
            variant="secondary"
          />
        </Box>
      </Box>

      <ScanWithQrScannerCard />
    </React.Fragment>
  );
}

function ScanWithDeviceToolbar() {
  const { isFullscreen } = useFullscreen();

  const activityTitle = useSelector(ActivityDataSelector.selectTitle);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!activityTitle) {
    return null;
  }

  return (
    <Toolbar
      backgroundColor="tabHeaderBackground"
      zIndex={isFullscreen ? 'unset' : 30}
    >
      <ToolbarContentContainer
        flexAlign="center"
        flexWrap="nowrap"
        flexJustify="between"
      >
        <BreadCrumbs flexGrow={0.4}>
          <BreadCrumb
            to="/activities"
            tx="label.breadcrumbs.activities.activities"
          />
          <BreadCrumb
            maxWidth="28ch"
            overflow="hidden"
            text={activityTitle}
            textOverflow="ellipsis"
            to={`/activities/${activityKey}/description`}
            whiteSpace="nowrap"
          />
          <BreadCrumb
            tx="label.breadcrumbs.activities.pick-scan-device"
            to={`/activities/${activityKey}/consume-tickets/pick-scan-device`}
          />
          <BreadCrumb
            tx="label.breadcrumbs.activities.scan-with-scanner"
            isLast
          />
        </BreadCrumbs>

        <ToggleFullscreenButton />
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function ScanWithDevice() {
  return (
    <ScanQrCodesContainer>
      <ScanWithDeviceToolbar />

      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <QrScannerModeContent />
          </InnerContentContainer>
        </ContentContainer>

        <ShareScanLinkSidebar />
      </InnerPageContainer>
    </ScanQrCodesContainer>
  );
}
