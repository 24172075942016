import { Button, Confirm } from '@orbiapp/components';
import { transitonDuration } from '@orbiapp/theme';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { CreatePostForm } from '../../../models';
import { QuickActionsContext } from '../../quick-actions';
import { CreatePostContext } from './post-form-context';

export function useCloseAndReset(skipConfirm?: boolean) {
  const { createPostModalState } = React.useContext(QuickActionsContext);
  const createPostContext = React.useContext(CreatePostContext);

  const formMethods = useFormContext<CreatePostForm>();

  return () => {
    if (!skipConfirm && formMethods.formState.isDirty) {
      createPostContext.confirm.openConfirm();
      return;
    }

    createPostModalState.closeModal();
    createPostContext.confirm.closeConfirm();

    window.setTimeout(() => {
      formMethods.reset();
      createPostContext.setTargetType(null);
    }, transitonDuration.slow);
  };
}

export function CloseAndResetButton() {
  const closeAndReset = useCloseAndReset(true);

  return (
    <Button tx="button.cancel" variant="tertiary" onClick={closeAndReset} />
  );
}

export function DiscardChangesConfirm() {
  const createPostContext = React.useContext(CreatePostContext);

  const closeAndReset = useCloseAndReset(true);

  return (
    <Confirm
      isOpen={createPostContext.confirm.isOpen}
      onCancel={createPostContext.confirm.closeConfirm}
      onConfirm={closeAndReset}
      titleTx="prompt.discard-post-form.title"
      messageTx="prompt.discard-post-form.message"
      confirmTx="prompt.discard-post-form.confirm"
      cancelTx="prompt.discard-post-form.cancel"
      zIndex={1901}
    />
  );
}
