import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  ContentContainer,
  Icon,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  LAYOUT_Z_INDEX,
  OptionProps,
  Select,
  SolidIconButton,
  Spinner,
  Text,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  useFullscreen,
  useNavigateWithQuery,
} from '@orbiapp/components';
import QrScanner from 'qr-scanner';
import React from 'react';

import { usePlaySound } from '../../../../helpers';
import { UIDRegexp } from '../../../../models';
import {
  ActivityDataSelector,
  ScanTicketSelector,
  activityActions,
  scanTicketThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { ScanQrCodesContainer } from '../shared/scan-qr-codes-container';
import { ScanResultCard } from '../shared/scan-result-card';
import {
  ShareScanLinkSidebar,
  ShareScanLinkSidebarContext,
} from '../shared/share-scan-link-sidebar';
import { ToggleFullscreenButton } from '../shared/toggle-fullscreen-button';
import { Styled } from './scan-with-camera.styled';

const CameraModeContext = React.createContext<{
  permissionState: PermissionState | null;
  requestCameraPermissons: () => Promise<void>;
}>({
  permissionState: null,
  requestCameraPermissons: () => Promise.resolve(),
});

function CameraModeProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [permissionState, setPermissionState] =
    React.useState<PermissionState | null>(null);

  const getAndSetPermissionState = React.useCallback(async () => {
    const res = await window.navigator.permissions.query({
      name: 'camera' as PermissionName,
    });

    setPermissionState(res.state);
  }, []);

  const requestCameraPermissons = async () => {
    try {
      await window.navigator.mediaDevices.getUserMedia({ video: true });
    } catch (err) {
      setPermissionState('denied');
      return;
    }

    getAndSetPermissionState();
  };

  React.useEffect(() => {
    getAndSetPermissionState();
  }, [getAndSetPermissionState]);

  return (
    <CameraModeContext.Provider
      value={{ permissionState, requestCameraPermissons }}
    >
      {children}
    </CameraModeContext.Provider>
  );
}

function PromptState() {
  const { requestCameraPermissons } = React.useContext(CameraModeContext);

  return (
    <Styled.EnableCameraBox
      flex
      flexAlign="center"
      flexJustify="center"
      height="100%"
      width="100%"
      flexDirection="column"
      px={32}
      py={93}
      r={8}
    >
      <Icon name="video-camera-slash-outline" size={144} />

      <Text
        tx="label.scan-qr-codes.web-camera.access-not-granted"
        textAlign="center"
        variant="bodyMd"
      />

      <Button
        mt={16}
        onClick={requestCameraPermissons}
        tx="label.scan-qr-codes.web-camera.enable-camera"
        variant="primary"
      />
    </Styled.EnableCameraBox>
  );
}

function AccessDeniedState() {
  return (
    <Styled.EnableCameraBox
      flex
      flexAlign="center"
      flexJustify="center"
      height="100%"
      width="100%"
      flexDirection="column"
      px={32}
      py={93}
      r={8}
    >
      <Icon name="video-camera-slash-outline" size={144} />

      <Text
        tx="label.scan-qr-codes.web-camera.access-not-granted"
        textAlign="center"
        variant="bodyMd"
      />
    </Styled.EnableCameraBox>
  );
}

function useWebCamScannerState() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const scanResultTicketStatus = useSelector(
    ScanTicketSelector.selectScannedTicketStatus,
  );
  const scanResultTicketError = useSelector(ScanTicketSelector.selectError);

  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();

  const readerRef = React.useRef<HTMLVideoElement>(null);
  const qrScannerRef = React.useRef<QrScanner | null>(null);

  const [cameras, setCameras] = React.useState<QrScanner.Camera[]>([]);
  const [selectedCamera, setSelectedCamera] = React.useState<string>('');

  const allowScans = React.useRef(true);
  const timeout = React.useRef<number | null>(null);

  const { enableSoundFeedback, toggleSoundFeedback, playSound } =
    usePlaySound();

  React.useEffect(() => {
    if (scanResultTicketError) {
      playSound('error');
      return;
    }

    if (!scanResultTicketStatus) {
      return;
    }

    switch (scanResultTicketStatus) {
      case 'alreadyConsumed':
        playSound('failure');
        break;

      case 'successfullyConsumed':
        playSound('success');
        break;

      default:
        playSound('error');
    }
  }, [scanResultTicketStatus, scanResultTicketError, playSound]);

  const onResult = React.useCallback(
    async (result: QrScanner.ScanResult) => {
      if (!activityKey) {
        playSound('error');
        navigate('/activities');
        return;
      }

      if (!allowScans.current) {
        return;
      }

      const ticketPurchaseKey = result.data;

      if (!UIDRegexp.test(ticketPurchaseKey)) return;

      allowScans.current = false;

      await dispatch(scanTicketThunk({ activityKey, ticketPurchaseKey }));

      timeout.current = window.setTimeout(() => {
        allowScans.current = true;
        timeout.current = null;
      }, 3000);
    },
    [activityKey, dispatch, navigate, playSound],
  );

  const switchCamera: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    qrScannerRef.current?.setCamera(e.target.value);
    setSelectedCamera(e.target.value);
  };

  const initWebCameraScanner = React.useCallback(async () => {
    if (!readerRef.current) return;

    const videoElement = readerRef.current;

    qrScannerRef.current = new QrScanner(videoElement, onResult, {});

    const cameras = await QrScanner.listCameras();
    setSelectedCamera(cameras?.[0].label ?? '');
    setCameras(cameras);

    qrScannerRef.current.start();
  }, [onResult]);

  React.useEffect(() => {
    initWebCameraScanner();

    return () => {
      qrScannerRef.current?.stop();
    };
  }, [qrScannerRef]); // eslint-disable-line

  const cameraOptions: OptionProps[] =
    cameras?.map((camera) => ({
      text: camera.label,
      value: camera.id,
    })) ?? [];

  return {
    cameraOptions,
    enableSoundFeedback,
    readerRef,
    selectedCamera,
    switchCamera,
    toggleSoundFeedback,
  };
}

function WebCamera() {
  const { isFullscreen, exitFullscreen } = useFullscreen();

  const {
    cameraOptions,
    enableSoundFeedback,
    readerRef,
    selectedCamera,
    switchCamera,
    toggleSoundFeedback,
  } = useWebCamScannerState();

  return (
    <Styled.WebCameraBox
      isFullscreen={isFullscreen}
      flex
      flexDirection="column"
    >
      <Box relative height="100%">
        {isFullscreen && (
          <Box
            absolute
            onClick={exitFullscreen}
            right={32}
            top={32}
            zIndex={LAYOUT_Z_INDEX.scanTickets.closeScanModalButton}
          >
            <Tooltip placement="left" titleTx="button.close">
              <SolidIconButton icon="x-mark" />
            </Tooltip>
          </Box>
        )}

        <Styled.WebCamera isFullscreen={isFullscreen} ref={readerRef}>
          <Styled.WebCameraOverlay />
        </Styled.WebCamera>

        {!isFullscreen && (
          <Box absolute bottom={32} right={32}>
            <IconButton
              onClick={toggleSoundFeedback}
              icon={
                enableSoundFeedback
                  ? 'speaker-x-mark-outline'
                  : 'speaker-wave-outline'
              }
            />
          </Box>
        )}
      </Box>

      {cameraOptions.length > 0 && !isFullscreen && (
        <Select
          labelTx="label.scan-qr-codes.pick-device.camera"
          mt={10}
          onChange={switchCamera}
          options={cameraOptions}
          value={selectedCamera}
        />
      )}
    </Styled.WebCameraBox>
  );
}

function ScanWithCameraCard() {
  const { permissionState } = React.useContext(CameraModeContext);

  return (
    <Box gap={32} flexWrap="wrap" flex width="100%">
      <Box
        flex
        flexAlign="center"
        flexDirection="column"
        flexJustify="center"
        r={8}
        width={425}
        height={425}
        relative
        maxWidth="100%"
      >
        {permissionState === null && <Spinner />}

        {permissionState === 'denied' && <AccessDeniedState />}

        {permissionState === 'prompt' && <PromptState />}

        {permissionState === 'granted' && <WebCamera />}
      </Box>

      <ScanResultCard />
    </Box>
  );
}

function CameraModeContent() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const dispatch = useDispatch();

  const { shareLinkSidebarIsOpen, toggleSidebar } = React.useContext(
    ShareScanLinkSidebarContext,
  );

  React.useEffect(() => {
    return () => {
      dispatch(activityActions.clearScanResultData());
    };
  }, [dispatch]);

  return (
    <React.Fragment>
      <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
        <Box flexWrap="wrap" flex gap={16}>
          <Box flex flexDirection="column" gap={4}>
            <Box flex gap={8} relative>
              <IconButton
                mt={4}
                to={`/activities/${activityKey}/consume-tickets/pick-scan-device`}
                icon="chevron-left"
              />

              <Text
                color="pageTitle"
                as="h1"
                tx="title.activities.consume-tickets"
                variant="titleMd"
              />
            </Box>

            <Text
              variant="bodyMd"
              ml={32}
              maxWidth="84ch"
              tx="label.scan-qr-codes.pick-device.header-text"
            />
          </Box>
        </Box>

        <Box gap={16} flexWrap flex>
          <Button
            variant="secondary"
            tx="label.scan-qr-codes.share-scan-link"
            icon={shareLinkSidebarIsOpen ? 'x-mark' : 'link'}
            onClick={toggleSidebar}
          />

          <Button
            icon="qr-code"
            to={`/activities/${activityKey}/consume-tickets/device`}
            tx="label.scan-qr-codes.web-camera.switch-action"
            variant="secondary"
          />
        </Box>
      </Box>

      <ScanWithCameraCard />
    </React.Fragment>
  );
}

function ScanWithCameraToolbar() {
  const { isFullscreen } = useFullscreen();
  const { permissionState } = React.useContext(CameraModeContext);

  const activityTitle = useSelector(ActivityDataSelector.selectTitle);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!activityTitle) {
    return null;
  }

  return (
    <Toolbar
      backgroundColor="tabHeaderBackground"
      zIndex={isFullscreen ? 'unset' : 30}
    >
      <ToolbarContentContainer
        flexAlign="center"
        flexWrap="nowrap"
        flexJustify="between"
      >
        <BreadCrumbs flexGrow={0.4}>
          <BreadCrumb
            to="/activities"
            tx="label.breadcrumbs.activities.activities"
          />
          <BreadCrumb
            maxWidth="28ch"
            overflow="hidden"
            text={activityTitle}
            textOverflow="ellipsis"
            to={`/activities/${activityKey}/description`}
            whiteSpace="nowrap"
          />
          <BreadCrumb
            tx="label.breadcrumbs.activities.pick-scan-device"
            to={`/activities/${activityKey}/consume-tickets/pick-scan-device`}
          />
          <BreadCrumb
            tx="label.breadcrumbs.activities.scan-with-camera"
            isLast
          />
        </BreadCrumbs>

        <ToggleFullscreenButton disabled={permissionState !== 'granted'} />
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function ScanWithCamera() {
  return (
    <ScanQrCodesContainer>
      <CameraModeProvider>
        <ScanWithCameraToolbar />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainer>
              <CameraModeContent />
            </InnerContentContainer>
          </ContentContainer>

          <ShareScanLinkSidebar />
        </InnerPageContainer>
      </CameraModeProvider>
    </ScanQrCodesContainer>
  );
}
