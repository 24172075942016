export const QUERY_CUSTOM_TOKEN_KEY = 'customToken';
export const QUERY_WORKSPACE_KEY = 'workspace';

const QUERY_EMULATE_USER_KEY = 'user';

function getQueryParam(key: string) {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
}

function setQueryParam(key: string, value: string) {
  const url = new URL(window.location.href);
  url.searchParams.set(key, value);
  window.history.replaceState({}, '', url.toString());
}

function removeQueryParam(key: string) {
  const url = new URL(window.location.href);
  url.searchParams.delete(key);
  window.history.replaceState({}, '', url.toString());
}

export namespace BrowserUrl {
  export function getWorkspaceKey() {
    return getQueryParam(QUERY_WORKSPACE_KEY);
  }

  export function setWorkspaceKey(workspaceKey: string) {
    if (getWorkspaceKey() === workspaceKey) return;

    setQueryParam(QUERY_WORKSPACE_KEY, workspaceKey);
  }

  export function removeWorkspaceKey() {
    removeQueryParam(QUERY_WORKSPACE_KEY);
  }

  export function getEmulateUserKey() {
    return getQueryParam(QUERY_EMULATE_USER_KEY);
  }

  export function setEmulateUserKey(userKey: string) {
    if (getEmulateUserKey() === userKey) return;

    setQueryParam(QUERY_EMULATE_USER_KEY, userKey);
  }

  export function removeEmulateUserKey() {
    removeQueryParam(QUERY_EMULATE_USER_KEY);
  }

  export function getCustomToken() {
    return getQueryParam(QUERY_CUSTOM_TOKEN_KEY);
  }

  export function deleteCustomToken() {
    removeQueryParam(QUERY_CUSTOM_TOKEN_KEY);
  }
}
