import {
  Box,
  Icon,
  Link,
  NavigateWithQuery,
  SplitScreenContentContainer,
  Text,
} from '@orbiapp/components';
import React from 'react';

import { NotOnboardedToolbar } from '../../../components';
import { ORBI_URL } from '../../../constants';
import { AccountSelector, useSelector } from '../../../store';

function AccessDeniedContent() {
  return (
    <React.Fragment>
      <Icon name="x-circle-solid" size={100} color="accessDeniedIcon" />

      <Box flex flexDirection="column" gap={8}>
        <Text
          color="accessDeniedTitle"
          variant="titleMd"
          tx="label.auth.access-denied.title"
          textAlign="center"
        />
        <Text
          color="accessDeniedSubtitle"
          tx="label.auth.access-denied.subtitle"
          textAlign="center"
        />
      </Box>
    </React.Fragment>
  );
}

export function AccessDenied() {
  const hasCorrectRole = useSelector(AccountSelector.selectHasCorrectRole);

  if (hasCorrectRole) {
    return <NavigateWithQuery to="/activities" />;
  }

  return (
    <React.Fragment>
      <NotOnboardedToolbar />

      <SplitScreenContentContainer>
        <AccessDeniedContent />

        <Box absolute bottom={40}>
          <Link
            tx="link.auth.back-to-orbi"
            href={ORBI_URL}
            variant="secondary"
            small
          />
        </Box>
      </SplitScreenContentContainer>
    </React.Fragment>
  );
}
