export namespace PostErrors {
  export const targetKey = {
    'string.empty': 'errors.select.none-selected',
    'any.required': 'errors.select.none-selected',
  };

  export const targetKeys = {
    'array.min': 'errors.select.none-selected',
  };

  export const message = {
    'string.empty': 'errors.message.empty',
  };
}
