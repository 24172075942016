import {
  Box,
  ControlledSelect,
  ControlledTextarea,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalSubtitle,
  ModalTitle,
  Radio,
  Select,
  Switch,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  CreateActivityPostForm,
  POST_MESSAGE_MAX_LENGTH,
} from '../../../models';
import {
  ActivityRecordsSelector,
  getActivityRecordsThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { getOptionalLabelText } from '../../../utils';
import { QuickActionsContext } from '../../quick-actions';
import {
  CloseAndResetButton,
  CreatePostButton,
  UploadFiles,
  UploadedImages,
} from '../components';

function PickActivitySelect() {
  const getActivityRecordsStatus = useSelector(
    ActivityRecordsSelector.selectStatus,
  );
  const activityRecords = useSelector(ActivityRecordsSelector.selectData);

  if (!activityRecords?.length) {
    return (
      <Select
        disabled={getActivityRecordsStatus === 'pending'}
        labelTx="label.posts.create-post-modal.event"
        value=""
        onChange={() => {}}
        options={[
          {
            notPickable: true,
            tx: 'placeholder.no-upcoming-events',
            value: ' ',
          },
        ]}
        trailingElements={[
          {
            type: 'spinner',
            hidden: getActivityRecordsStatus !== 'pending',
            size: 16,
          },
        ]}
      />
    );
  }

  return (
    <ControlledSelect
      name="targetKey"
      labelTx="label.posts.create-post-modal.event"
      options={activityRecords
        .map((activityRecord) => ({
          text: activityRecord.activityTitle,
          value: activityRecord.activityKey,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))}
    />
  );
}

function NotificationsSelect() {
  const formContext = useFormContext<CreateActivityPostForm>();

  const pushTo = formContext.watch('pushTo');

  const toggleSendPushNotifications = () => {
    formContext.setValue('pushTo', pushTo ? null : 'attendees');
  };

  const selectSendToAttendees = () => {
    formContext.setValue('pushTo', 'attendees');
  };

  const selectSendToFollowers = () => {
    formContext.setValue('pushTo', 'followers');
  };

  return (
    <Box
      p={8}
      gap={16}
      flex
      flexDirection="column"
      backgroundColor="backgroundTeritary"
      r={8}
    >
      <Switch
        text={getOptionalLabelText(
          'label.posts.create-post-modal.send-push-notification',
        )}
        onClick={toggleSendPushNotifications}
        checked={!!pushTo}
      />

      <Radio
        dotPlacement="left"
        tx="label.posts.create-post-modal.attendees"
        subtitleTx="label.posts.create-post-modal.attendees-subtitle"
        onChange={selectSendToAttendees}
        checked={pushTo === 'attendees'}
        disabled={!pushTo}
      />
      <Radio
        dotPlacement="left"
        tx="label.posts.create-post-modal.followers"
        subtitleTx="label.posts.create-post-modal.followers-subtitle"
        onChange={selectSendToFollowers}
        checked={pushTo === 'followers'}
        disabled={!pushTo}
      />
    </Box>
  );
}

export function ActivityPostForm() {
  const dispatch = useDispatch();

  const { createPostModalState } = React.useContext(QuickActionsContext);

  React.useEffect(() => {
    dispatch(getActivityRecordsThunk());
  }, [dispatch]);

  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle tx="label.posts.create-post-modal.new-event-post" />
        <ModalSubtitle
          mt={16}
          tx="label.posts.create-post-modal.event-post-subtitle"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        {!createPostModalState.value && <PickActivitySelect />}

        <ControlledTextarea
          name="message"
          labelTx="label.posts.create-post-modal.message"
          maxLength={POST_MESSAGE_MAX_LENGTH}
        />

        <UploadedImages />

        <NotificationsSelect />
      </ModalBodyContainer>

      <ModalFooterContainer>
        <UploadFiles />

        <Box gap={16} flex>
          <CloseAndResetButton />

          <CreatePostButton />
        </Box>
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}
