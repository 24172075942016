import React from 'react';
import { To } from 'react-router-dom';

import { TxProps } from '../text';
import { Styled } from './menu-button.styled';

interface MenuButtonProps extends TxProps {
  isOpen: boolean;
  onClick?: () => void;
  onToggle: () => void;
  to?: To;
}

function _MenuButton(
  props: MenuButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { isOpen, tx, txArgs, text, onToggle, onClick, to } = props;

  const labelContent = (
    <React.Fragment>
      <Styled.LabelBackground />

      <Styled.LabelIcon name="plus-circle-outline" />
      <Styled.LabelText
        text={text}
        tx={tx}
        txArgs={txArgs}
        variant="buttonSm"
        whiteSpace="nowrap"
      />
    </React.Fragment>
  );

  return (
    <Styled.MenuButton ref={ref}>
      {to ? (
        <Styled.LabelLink onClick={onClick} to={to}>
          {labelContent}
        </Styled.LabelLink>
      ) : (
        <Styled.Label onClick={onClick}>{labelContent}</Styled.Label>
      )}

      <Styled.Toggle
        flex
        flexAlign="center"
        onClick={onToggle}
        px={8}
        role="button"
      >
        <Styled.ToggleBackground />

        <Styled.ToggleIcon name={isOpen ? 'chevron-up' : 'chevron-down'} />
      </Styled.Toggle>
    </Styled.MenuButton>
  );
}

export const MenuButton = React.forwardRef(_MenuButton);
