import { GeneralApiResultWithData } from '@orbiapp/components';

import {
  CreateMembershipTypeForm,
  CreateMembershipTypeResponse,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce } from '../../../api';
import { CUSTOM_TIMEOUTS } from '../../../api.constants';
import { getGeneralApiProblem } from '../../../api.utils';
import { createMembershipTypeMapper } from './create-membership-type.mappers';

export const createMembershipType = async (
  createMembershipTypeForm: CreateMembershipTypeForm,
): Promise<GeneralApiResultWithData<CreateMembershipTypeResponse>> => {
  const res = await apisauce.post<CreateMembershipTypeResponse, any>(
    '/v2/membership-types',
    createMembershipTypeMapper(createMembershipTypeForm),
    { timeout: CUSTOM_TIMEOUTS.createMembership },
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('createMembershipType', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
};
