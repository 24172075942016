import { Button } from '@orbiapp/components';
import { useFormContext } from 'react-hook-form';

import { CreatePostForm } from '../../../models';
import {
  CreatePostSelector,
  createPostThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { useCloseAndReset } from './close-and-reset';

export function CreatePostButton() {
  const isLoading = useSelector(CreatePostSelector.selectIsLoading);

  const dispatch = useDispatch();

  const closeAndReset = useCloseAndReset(true);

  const formContext = useFormContext<CreatePostForm>();

  const createPost = formContext.handleSubmit(async (data) => {
    const res = await dispatch(createPostThunk(data));
    if (res.meta.requestStatus === 'fulfilled') {
      closeAndReset();
    }
  });

  return (
    <Button
      tx="label.posts.create-post-modal.post"
      variant="primary"
      onClick={createPost}
      isLoading={isLoading}
    />
  );
}
