import {
  Box,
  ControlledTextarea,
  InputChip,
  InputChips,
  Menu,
  MenuItem,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalSubtitle,
  ModalTitle,
  Portal,
  Switch,
  Tooltip,
  createFilterContext,
  getListHeight,
  isTxString,
  useAnchoredMenu,
} from '@orbiapp/components';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { List, ListRowRenderer } from 'react-virtualized/dist/es/List';

import {
  CreateMembershipPostForm,
  MembershipTypeListItem,
  POST_MESSAGE_MAX_LENGTH,
} from '../../../models';
import {
  MembershipTypeListItemsSelector,
  getMembershipsTypesThunk,
  useDispatch,
  useSelector,
} from '../../../store';
import { getOptionalLabelText } from '../../../utils';
import {
  CloseAndResetButton,
  CreatePostButton,
  UploadFiles,
  UploadedImages,
} from '../components';

const MembershipsSearchContext = createFilterContext<MembershipTypeListItem>({
  sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
});

const MENU_MAX_HEIGHT = 350;
const MEMBERSHIP_MENU_ITEM_HEIGHT = 40;

function PickMembershipSelect() {
  const filterContext = React.useContext(
    MembershipsSearchContext.FilterContext,
  );
  const entities = useSelector(MembershipTypeListItemsSelector.selectEntities);
  const membershipTypesIsLoading = useSelector(
    MembershipTypeListItemsSelector.selectIsLoading,
  );

  const formContext = useFormContext<CreateMembershipPostForm>();

  const anchoredMenu = useAnchoredMenu({
    placement: 'bottom',
    syncWidth: true,
  });

  const inputRef = React.useRef<HTMLInputElement>(null);

  const targetKeys = formContext.watch('targetKeys');

  const chips: InputChip<string>[] = targetKeys
    .map((membershipTypeKey) => {
      const item = entities[membershipTypeKey];

      if (!item) {
        return null;
      }

      return {
        id: item.membershipTypeKey,
        text: item.name,
        maxWidth: 'unset',
      };
    })
    .filter(Boolean) as InputChip<string>[];

  const removeChip = (membershipTypeKey: string) => {
    formContext.setValue(
      'targetKeys',
      targetKeys.filter((key) => key !== membershipTypeKey),
      {
        shouldValidate: formContext.formState.isSubmitted,
        shouldDirty: true,
      },
    );
  };

  const renderMenuItem: ListRowRenderer = (listRowProps) => {
    const item = filterContext.items[listRowProps.index];

    const toggleMembership = () => {
      formContext.setValue(
        'targetKeys',
        targetKeys.includes(item.membershipTypeKey)
          ? targetKeys.filter((key) => key !== item.membershipTypeKey)
          : [...targetKeys, item.membershipTypeKey],
        {
          shouldValidate: formContext.formState.isSubmitted,
          shouldDirty: true,
        },
      );

      filterContext.resetSearchString();
    };

    const isSelected = targetKeys.includes(item.membershipTypeKey);
    const disableMenuItem =
      !isSelected &&
      !!formContext.getValues('fileUploads').length &&
      targetKeys.length > 0;

    return (
      <MenuItem
        key={item.membershipTypeKey}
        style={listRowProps.style}
        checkbox
        isSelected={isSelected}
        onMouseDown={(e) => e.preventDefault()}
        onClick={toggleMembership}
        text={item.name}
        gap={8}
        disabled={disableMenuItem}
      />
    );
  };

  const listHeight = getListHeight(
    filterContext.items.length,
    MEMBERSHIP_MENU_ITEM_HEIGHT,
    MENU_MAX_HEIGHT,
  );

  const toggleMenu = () => {
    if (anchoredMenu.isOpen) {
      anchoredMenu.closeMenu();
      inputRef.current?.blur();
    } else {
      anchoredMenu.openMenu();
      inputRef.current?.focus();
    }
  };

  const errorTx = isTxString(formContext.formState.errors.targetKeys?.message)
    ? formContext.formState.errors.targetKeys.message
    : undefined;

  return (
    <React.Fragment>
      <Box ref={anchoredMenu.anchorRef}>
        <InputChips
          onChange={filterContext.handleSearchStringChange}
          onFocus={anchoredMenu.openMenu}
          maxChips={100}
          value={filterContext.searchString}
          ref={inputRef}
          chips={chips}
          showInput={anchoredMenu.isOpen}
          onRemoveChip={removeChip}
          onBlur={anchoredMenu.closeMenu}
          disabled={membershipTypesIsLoading}
          errorTx={errorTx}
          leadingElements={[
            {
              type: 'icon',
              name: 'magnifying-glass',
              color: membershipTypesIsLoading ? 'inputIconDisabled' : undefined,
            },
          ]}
          trailingElements={[
            {
              type: 'spinner',
              size: 24,
              hidden: !membershipTypesIsLoading,
            },
            {
              type: 'button',
              icon: anchoredMenu.isOpen ? 'chevron-up' : 'chevron-down',
              onClick: toggleMenu,
              tooltipTx: anchoredMenu.isOpen
                ? 'label.tooltip.collapse'
                : 'label.tooltip.expand',
              tooltipPlacement: 'left',
              hidden: membershipTypesIsLoading,
            },
          ]}
          labelTx="label.posts.create-post-modal.membership"
        />
      </Box>

      <Portal>
        <Menu
          isOpen={anchoredMenu.isOpen}
          ref={anchoredMenu.menuRef}
          mt={errorTx ? -16 : 8}
          hideStrategy="opacity"
          zIndex={10000}
          height={listHeight}
        >
          {filterContext.items.length ? (
            <List
              containerStyle={{ width: '100%', maxWidth: '100%' }}
              style={{ width: '100%' }}
              width={1}
              height={listHeight}
              rowCount={filterContext.items.length}
              rowHeight={MEMBERSHIP_MENU_ITEM_HEIGHT}
              rowRenderer={renderMenuItem}
            />
          ) : (
            <MenuItem tx="placeholder.no-memberships" />
          )}
        </Menu>
      </Portal>
    </React.Fragment>
  );
}

function NotificationsSelect() {
  const formContext = useFormContext<CreateMembershipPostForm>();

  const pushTo = formContext.watch('pushTo');

  const togglePushNotifications = () => {
    formContext.setValue(
      'pushTo',
      pushTo === 'active_members' ? null : 'active_members',
    );
  };

  return (
    <Box
      p={8}
      gap={16}
      flex
      flexDirection="column"
      backgroundColor="backgroundTeritary"
      r={8}
    >
      <Switch
        text={getOptionalLabelText(
          'label.posts.create-post-modal.send-push-notification',
        )}
        subtitleTx="label.posts.create-post-modal.members"
        onClick={togglePushNotifications}
        checked={pushTo === 'active_members'}
      />
    </Box>
  );
}

export function MembershipPostForm() {
  const membershipTypes = useSelector(
    MembershipTypeListItemsSelector.selectAll,
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMembershipsTypesThunk());
  }, [dispatch]);

  const formContext = useFormContext<CreateMembershipPostForm>();
  const disableUploads = !!formContext.watch('targetKeys.1');

  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle tx="label.posts.create-post-modal.new-membership-post" />
        <ModalSubtitle
          mt={16}
          tx="label.posts.create-post-modal.membership-post-subtitle"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <MembershipsSearchContext.Provider
          keys={['name']}
          items={membershipTypes}
        >
          <PickMembershipSelect />
        </MembershipsSearchContext.Provider>

        <ControlledTextarea
          name="message"
          labelTx="label.posts.create-post-modal.message"
          maxLength={POST_MESSAGE_MAX_LENGTH}
        />

        <NotificationsSelect />

        <UploadedImages />
      </ModalBodyContainer>

      <ModalFooterContainer>
        {disableUploads ? (
          <Tooltip
            placement="right"
            titleTx="label.posts.create-post-modal.upload-disabled"
          >
            <UploadFiles disabled />
          </Tooltip>
        ) : (
          <UploadFiles />
        )}

        <Box gap={16} flexJustify="end" flex>
          <CloseAndResetButton />

          <CreatePostButton />
        </Box>
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}
