import {
  Box,
  Confirm,
  DangerIconButton,
  SolidIconButton,
  Tooltip,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';

import { QuickActionsContext } from '../../../../../components';
import {
  ActivityDataSelector,
  ActivitySelector,
  UnpublishActivitySelector,
  unpublishActivityThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { DownloadActivityUsers } from './download-users';

function EditActivityButton() {
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!isActivityOwner) {
    return (
      <Tooltip
        titleTx="label.view-activity.tooltip.no-access"
        placement="bottom"
      >
        <SolidIconButton disabled icon="pencil-square-outline" />
      </Tooltip>
    );
  }

  return (
    <Tooltip titleTx="label.view-activity.tooltip.update" placement="bottom">
      <SolidIconButton
        icon="pencil-square-outline"
        to={`/activities/edit-activity/${activityKey}/description`}
      />
    </Tooltip>
  );
}

function UnpublishActivityButton() {
  const dispatch = useDispatch();

  const { closeConfirm, isOpen, openConfirm } = useConfirm();
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const endDate = useSelector(ActivityDataSelector.selectEndDate);
  const unpublishActivityStatus = useSelector(
    UnpublishActivitySelector.selectStatus,
  );

  if (!isActivityOwner) {
    return (
      <Tooltip titleTx="label.view-activity.tooltip.no-access" placement="left">
        <SolidIconButton disabled icon="power" />
      </Tooltip>
    );
  }

  const unpublishActivity = async () => {
    if (!activityKey) return;

    await dispatch(unpublishActivityThunk(activityKey));

    closeConfirm();
  };

  return (
    <React.Fragment>
      <Tooltip placement="left" titleTx="label.view-activity.tooltip.unpublish">
        <DangerIconButton
          ml={8}
          disabled={!endDate || Date.now() > endDate}
          icon="power"
          isLoading={unpublishActivityStatus === 'pending'}
          onClick={openConfirm}
        />
      </Tooltip>

      <Confirm
        cancelTx="button.cancel"
        confirmTx="prompt.unpublish-activity.confirm"
        isLoading={unpublishActivityStatus === 'pending'}
        isOpen={isOpen}
        messageTx="prompt.unpublish-activity.message"
        onCancel={closeConfirm}
        onConfirm={unpublishActivity}
        titleTx="prompt.unpublish-activity.title"
      />
    </React.Fragment>
  );
}

function DuplicateActivityButton() {
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!isActivityOwner) {
    return (
      <Tooltip
        placement="bottom"
        titleTx="label.view-activity.tooltip.no-access"
      >
        <SolidIconButton
          disabled
          icon="document-duplicate-outline"
          to={`/activities/duplicate-activity/${activityKey}/description`}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="bottom" titleTx="label.view-activity.tooltip.duplicate">
      <SolidIconButton
        icon="document-duplicate-outline"
        to={`/activities/duplicate-activity/${activityKey}/description`}
      />
    </Tooltip>
  );
}

function OpenCreatePostModalButton() {
  const { createPostModalState } = React.useContext(QuickActionsContext);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!isActivityOwner) {
    return (
      <Tooltip
        placement="bottom"
        titleTx="label.view-activity.tooltip.no-access"
      >
        <SolidIconButton icon="megaphone-outline" disabled />
      </Tooltip>
    );
  }

  const openCreatePostModal = () => {
    if (!activityKey) return;

    createPostModalState.openModal({
      targetType: 'activity',
      targetKey: activityKey,
    });
  };

  return (
    <Tooltip placement="bottom" titleTx="button.posts.create">
      <SolidIconButton
        isActiveIcon="megaphone-solid"
        icon="megaphone-outline"
        isActive={false}
        onClick={openCreatePostModal}
      />
    </Tooltip>
  );
}

function ScanTicketsButton() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  return (
    <Tooltip
      placement="bottom"
      titleTx="label.view-activity.menu-actions.scan-qr-codes"
    >
      <SolidIconButton
        icon="qr-code"
        to={`/activities/${activityKey}/consume-tickets/pick-scan-device`}
      />
    </Tooltip>
  );
}

export function EventActions() {
  return (
    <Box flexAlign="center" flexWrap="wrap" flex gap={16}>
      <EditActivityButton />
      <DuplicateActivityButton />
      <OpenCreatePostModalButton />
      <ScanTicketsButton />
      <DownloadActivityUsers />
      <UnpublishActivityButton />
    </Box>
  );
}
