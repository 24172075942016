import { OrbiLoader, useNavigateWithQuery } from '@orbiapp/components';
import React from 'react';

import {
  AuthStateSelector,
  signOutThunk,
  useDispatch,
  useSelector,
} from '../../../store';

export function SignOut() {
  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();

  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);

  React.useEffect(() => {
    if (!authenticated) {
      navigate('/sign-in');
      return;
    }

    dispatch(signOutThunk());
  }, [authenticated, dispatch, navigate]);

  return <OrbiLoader />;
}
