import { Base64File, FileType, ImageData } from '@orbiapp/components';

import { ContactDetails } from '../contact-details';
import { ActivityCategory } from './activity-category';
import { CoHost, CreateCoHostRequest, Host } from './co-hosting';
import { ActivityLocation } from './location';
import { RequestedInfo } from './requested-info';
import { ActivityListItemStats, ActivityStats } from './stats';
import { TicketType } from './ticket-types';

export interface ActivityLink {
  label: string;
  url: string;
}

export interface Activity {
  activityKey: string;
  categories: ActivityCategory[];
  cohosts: CoHost[] | null;
  contactDetails: ContactDetails;
  coverImage: ImageData;
  createdAt: number;
  description: string;
  endDate: number;
  host: Host | null;
  links: ActivityLink[] | null;
  location: ActivityLocation;
  questionsActivated: boolean;
  requestedInfo: RequestedInfo[] | null;
  segmentation: number[];
  showAttendance: boolean;
  startDate: number;
  stats: ActivityStats | null;
  tags: string[] | null;
  ticketTypes: TicketType[] | null;
  title: string;
}

export interface ActivityListItem
  extends Pick<
    Activity,
    'activityKey' | 'startDate' | 'title' | 'description'
  > {
  departmentKey: string;
  endDate: number;
  stats: ActivityListItemStats;
  totalTicketCount: number;
  coverImage: {
    fileName: string;
    fileType: FileType;
    fileUrl: string;
  };
}

export interface DescriptionForm {
  categories: ActivityCategory[];
  contactDetails: ContactDetails;
  description: string;
  endDate: number;
  links: ActivityLink[] | null;
  location: ActivityLocation;
  startDate: number;
  tags: string[] | null;
  title: string;
}

export interface MediaForm {
  coverImage: Base64File | null;
}

export interface ParticipantsForm {
  segmentation: number[];
}

export interface TicketsForm {
  showAttendance: boolean;
  ticketTypes: TicketType[] | null;
}

export interface RequestMoreInfoForm {
  requestedInfo: RequestedInfo[] | null;
}

export interface CoHostingForm {
  cohosts: CreateCoHostRequest[] | null;
}

export interface AddonsForm {
  questionsActivated: boolean;
}

export interface CreateActivityForm {
  description: DescriptionForm;
  media: MediaForm;
  participants: ParticipantsForm;
  tickets: TicketsForm;
  requestMoreInfo: RequestMoreInfoForm;
  coHosting: CoHostingForm;
  addons: AddonsForm;
}

export interface UpdateActivityForm {
  activityKey: string;
  addons: AddonsForm;
  coHosting: CoHostingForm;
  description: DescriptionForm;
  media: MediaForm;
  participants: ParticipantsForm;
  requestMoreInfo: RequestMoreInfoForm;
  tickets: TicketsForm;
}

export type ActivityForm = CreateActivityForm | UpdateActivityForm;

export interface ActivityDraft {
  data: CreateActivityForm;
  key: string;
  lastUpdated: number;
}

export type ActivityOrderByKey = keyof Pick<
  ActivityListItem,
  'title' | 'startDate' | 'endDate'
>;

export const activitySortableKeys: Record<
  ActivityOrderByKey,
  ActivityOrderByKey
> = {
  title: 'title',
  startDate: 'startDate',
  endDate: 'endDate',
};

export interface ActivityRecord {
  activityKey: string;
  activityTitle: string;
}

export interface CreateActivityResponse {
  activityKey: string;
  coverImage: ImageData;
}

export interface UpdateActivityResponse {
  coverImage: ImageData;
}
