import {
  Avatar,
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  Chip,
  ContentContainer,
  EmptyState,
  InnerContentContainer,
  InnerPageContainer,
  PageContainer,
  PageHeader,
  ResponsiveBox,
  SolidIconButton,
  Status,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  Toolbar,
  ToolbarContentContainer,
  Tooltip,
  capitalizeFirstLetter,
  formatDate,
  useClientSort,
} from '@orbiapp/components';
import React from 'react';

import { QuickActionsContext } from '../../../../components';
import { MembershipTypeListItem, MembershipTypeType } from '../../../../models';
import {
  MembershipTypeListItemsSelector,
  getMembershipsTypesThunk,
  useDispatch,
  useSelector,
} from '../../../../store';

function getMembershipTypeTx(membershipType: MembershipTypeType): TxString {
  switch (membershipType) {
    case 'invite_only':
      return 'label.memberships.dashboard.table.invite-only';
    case 'needs_approval':
      return 'label.memberships.dashboard.table.needs-approval';
    case 'standard':
      return 'label.memberships.dashboard.table.standard';
  }
}

const TABLE_COLUMN_WIDTHS = {
  name: 300,
  totalMemberCount: 100,
  type: 200,
  createdAt: 200,
  source: 200,
  pendingApplicationCount: 300,
  actions: 50,
};

const TABLE_COLUMN_NAMES = {
  name: 'name',
  totalMemberCount: 'totalMemberCount',
  type: 'type',
  createdAt: 'createdAt',
  source: 'source',
  pendingApplicationCount: 'pendingApplicationCount',
};

function renderMembershipTableRow(
  membershipTypeListItem: MembershipTypeListItem,
) {
  return (
    <TableRow
      key={membershipTypeListItem.membershipTypeKey}
      to={`/memberships/${membershipTypeListItem.membershipTypeKey}/overview`}
    >
      <TableCell width={TABLE_COLUMN_WIDTHS.name}>
        <Box gap={8} flexAlign="center" flex>
          <Avatar src={membershipTypeListItem.logo.thumbnail64.url} />
          <Text as="span" variant="bodySm" text={membershipTypeListItem.name} />
        </Box>
      </TableCell>
      <TableCell
        width={TABLE_COLUMN_WIDTHS.totalMemberCount}
        text={membershipTypeListItem.totalMemberCount}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.type}>
        {membershipTypeListItem.source !== 'orbi' ? (
          ''
        ) : (
          <Chip
            variant={1}
            tx={getMembershipTypeTx(membershipTypeListItem.type)}
          />
        )}
      </TableCell>
      <TableCell
        width={TABLE_COLUMN_WIDTHS.createdAt}
        text={formatDate(membershipTypeListItem.createdAt, 'DD MMM YYYY HH:mm')}
      />
      <TableCell width={TABLE_COLUMN_WIDTHS.source}>
        {membershipTypeListItem.source === 'orbi' ? (
          ''
        ) : (
          <Chip
            variant={2}
            text={capitalizeFirstLetter(membershipTypeListItem.source)}
          />
        )}
      </TableCell>

      <TableCell width={TABLE_COLUMN_WIDTHS.pendingApplicationCount}>
        {membershipTypeListItem.pendingApplicationCount ? (
          <Status
            variant="warning"
            tx="label.memberships.dashboard.table.pending-applications-count"
            txArgs={{ count: membershipTypeListItem.pendingApplicationCount }}
          />
        ) : (
          ''
        )}
      </TableCell>

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
    </TableRow>
  );
}

function MembershipsTableContent() {
  const membershipListItems = useSelector(
    MembershipTypeListItemsSelector.selectAll,
  );

  const { items, sortOrder, orderBy, onSort } = useClientSort(
    membershipListItems,
    {
      defaultOrderBy: TABLE_COLUMN_NAMES.createdAt,
      defaultSortOrder: 'desc',
    },
  );

  return (
    <Table>
      <TableHeader
        sortableColumns={Object.values(TABLE_COLUMN_NAMES)}
        onSort={onSort}
        sortOrder={sortOrder}
        orderBy={orderBy}
      >
        <TableRow>
          <TableHead
            tx="label.memberships.dashboard.table.name"
            name={TABLE_COLUMN_NAMES.name}
          />
          <TableHead
            tx="label.memberships.dashboard.table.members"
            name={TABLE_COLUMN_NAMES.totalMemberCount}
          />
          <TableHead
            tx="label.memberships.dashboard.table.membership-type"
            name={TABLE_COLUMN_NAMES.type}
          />
          <TableHead
            tx="label.memberships.dashboard.table.created-at"
            name={TABLE_COLUMN_NAMES.createdAt}
          />
          <TableHead
            tx="label.memberships.dashboard.table.more-info"
            name={TABLE_COLUMN_NAMES.source}
          />
          <TableHead
            tx="label.memberships.dashboard.table.pending-applications"
            name={TABLE_COLUMN_NAMES.pendingApplicationCount}
          />
          <TableHead fixedRight />
        </TableRow>
      </TableHeader>

      <TableBody>{items.map(renderMembershipTableRow)}</TableBody>
    </Table>
  );
}

function MembershipsTable() {
  const { createMembershipModalState } = React.useContext(QuickActionsContext);

  const membershipListItems = useSelector(
    MembershipTypeListItemsSelector.selectAll,
  );
  const membershipListItemsStatus = useSelector(
    MembershipTypeListItemsSelector.selectStatus,
  );
  const isLoading = membershipListItemsStatus === 'pending';

  if (isLoading && membershipListItems.length === 0) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.dashboard.table.name" />
            <TableHead tx="label.memberships.dashboard.table.members" />
            <TableHead tx="label.memberships.dashboard.table.membership-type" />
            <TableHead tx="label.memberships.dashboard.table.created-at" />
            <TableHead tx="label.memberships.dashboard.table.more-info" />
            <TableHead tx="label.memberships.dashboard.table.pending-applications" />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (membershipListItems.length === 0) {
    return (
      <EmptyState
        buttonOnClick={createMembershipModalState.openModal}
        buttonTx="button.memberships.new-membership"
        titleTx="label.memberships.dashboard.empty-state"
      />
    );
  }

  return <MembershipsTableContent />;
}

function MembershipsContent() {
  const { createMembershipModalState } = React.useContext(QuickActionsContext);
  const membershipsCount = useSelector(
    MembershipTypeListItemsSelector.selectCount,
  );

  return (
    <React.Fragment>
      <Box
        flex
        flexWrap="wrap"
        gap={16}
        flexJustify="between"
        flexAlign="start"
      >
        <PageHeader
          headingTx="title.memberships.dashboard"
          subtitleTx="title.memberships.subtitle"
        />
        {membershipsCount > 0 && (
          <ResponsiveBox
            xs={
              <Button
                onClick={createMembershipModalState.openModal}
                tx="button.memberships.new-membership"
                variant="primary"
              />
            }
          >
            <Tooltip
              placement="left"
              titleTx="button.memberships.new-membership"
            >
              <SolidIconButton
                icon="plus-circle-outline"
                onClick={createMembershipModalState.openModal}
                isActive={createMembershipModalState.isOpen}
              />
            </Tooltip>
          </ResponsiveBox>
        )}
      </Box>

      <MembershipsTable />
    </React.Fragment>
  );
}

function MembershipsToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer flexJustify="between">
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.memberships.memberships" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function Memberships() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getMembershipsTypesThunk());
  }, [dispatch]);

  return (
    <React.Fragment>
      <PageContainer>
        <MembershipsToolbar />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainer>
              <MembershipsContent />
            </InnerContentContainer>
          </ContentContainer>
        </InnerPageContainer>
      </PageContainer>
    </React.Fragment>
  );
}
