import {
  INITIAL_MODAL_STATE,
  ModalState,
  useModalState,
} from '@orbiapp/components';
import React from 'react';

import { CreateActivityPostForm } from '../../models';

type CreatePostModalStateValue = Pick<
  CreateActivityPostForm,
  'targetType' | 'targetKey'
>;

interface QuickActionsContextType {
  createMembershipModalState: ModalState;
  createPostModalState: ModalState<CreatePostModalStateValue>;
}

export const QuickActionsContext = React.createContext<QuickActionsContextType>(
  {
    createMembershipModalState: INITIAL_MODAL_STATE,
    createPostModalState: INITIAL_MODAL_STATE,
  },
);

export function QuickActionsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const createMembershipModalState = useModalState();
  const createPostModalState = useModalState<CreatePostModalStateValue>();

  return (
    <QuickActionsContext.Provider
      value={{
        createMembershipModalState,
        createPostModalState,
      }}
    >
      {children}
    </QuickActionsContext.Provider>
  );
}
