import {
  Box,
  Menu,
  MenuItem,
  SolidIconButton,
  Tooltip,
  downloadFileContent,
  getUID,
  useMenu,
} from '@orbiapp/components';

import { DownloadableFileType } from '../../../../../../models';
import {
  ActivityDataSelector,
  ActivitySelector,
  ActivityUsersDataSelector,
  ActivityUsersSelector,
  getActivityUsersDataThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import { isAnyPending } from '../../../../../../utils';

export const DownloadActivityUsers = () => {
  const activityTitle = useSelector(ActivityDataSelector.selectTitle);
  const activity = useSelector(ActivityDataSelector.selectData);
  const activityUsersStatus = useSelector(ActivityUsersSelector.selectStatus);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const activityUsersDataStatus = useSelector(
    ActivityUsersDataSelector.selectStatus,
  );
  const soldTicketCount = useSelector(
    ActivityDataSelector.selectSoldTicketCount,
  );

  const dispatch = useDispatch();

  const menuState = useMenu();

  const filename = activityTitle ?? getUID();

  const downloadActivityUsers = (type: DownloadableFileType) => async () => {
    if (!activityKey) return;

    const res = await dispatch(
      getActivityUsersDataThunk({ activityKey, type }),
    );

    if (res.payload instanceof Blob) {
      downloadFileContent(res.payload, `${filename}.${type}`);
    }
  };

  const isLoading = isAnyPending(activityUsersDataStatus, activityUsersStatus);

  if (!activity || soldTicketCount === 0 || !isActivityOwner) {
    return (
      <Tooltip
        placement="bottom"
        titleTx={
          isActivityOwner
            ? 'button.download-activity-users'
            : 'label.view-activity.tooltip.no-access'
        }
      >
        <SolidIconButton
          disabled
          isLoading={isLoading}
          icon="arrow-download-tray"
        />
      </Tooltip>
    );
  }

  return (
    <Box ref={menuState.clickOutsideRef} relative>
      <Tooltip titleTx="button.download-activity-users" placement="bottom">
        <SolidIconButton
          isActive={menuState.isOpen}
          onClick={menuState.toggleMenu}
          icon="arrow-download-tray"
          isLoading={isLoading}
        />
      </Tooltip>

      <Menu
        ref={menuState.menuRef}
        top="100%"
        minWidth={200}
        mt={8}
        right={0}
        absolute
        isOpen={menuState.isOpen}
      >
        <MenuItem
          flex
          flexAlign="center"
          gap={8}
          notPickable={isLoading}
          onClick={downloadActivityUsers('csv')}
          tx="link.general.download-as-csv"
        />
        <MenuItem
          flex
          flexAlign="center"
          gap={8}
          notPickable={isLoading}
          onClick={downloadActivityUsers('xlsx')}
          tx="link.general.download-as-xls"
        />
      </Menu>
    </Box>
  );
};
