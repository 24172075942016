import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  Checkbox,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledInput,
  EmptyState,
  Icon,
  IconButton,
  InnerContentContainer,
  Radio,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  getUID,
  isTxString,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  ActivityCheckbox,
  ActivityFreeText,
  ActivityMultiChoice,
  ActivityRequestedInfoOption,
  CheckboxValidation,
  FreeTextValidation,
  MultiChoiceValidation,
  REQUESTED_INFO_OPTION_LABEL_MAX_LENGTH,
  REQUESTED_INFO_QUESTION_MAX_LENGTH,
  RequestedInfo,
  RequestedInfoType,
  TicketType,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import { OrbiPaySettingsSelector, useSelector } from '../../../../../store';
import {
  getCurrencyLabelText,
  getOptionalLabelText,
  getRequestedInfoTypeTx,
} from '../../../../../utils';
import {
  EventSubmitButton,
  GoBackButton,
  SaveActivityAsDraftButton,
  useRootPath,
} from '../components';
import { ActivityFormContext } from '../create-activity.context';
import { RequestedInfoFormContextType } from './request-more-info.types';

const PickRequestedInfoTypeContext = React.createContext({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => {},
});

export function PickRequestedInfoTypeProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <PickRequestedInfoTypeContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </PickRequestedInfoTypeContext.Provider>
  );
}

const RequestedInfoFormContext =
  React.createContext<RequestedInfoFormContextType>({
    data: { defaultValues: null, mode: 'create' },
    setData: () => {},
  });

export function RequestedInfoFormProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [data, setData] = React.useState<RequestedInfoFormContextType['data']>({
    defaultValues: null,
    mode: 'create',
  });

  return (
    <RequestedInfoFormContext.Provider value={{ data, setData }}>
      {children}
    </RequestedInfoFormContext.Provider>
  );
}

function PickTickets() {
  const activityFormContext = React.useContext(ActivityFormContext);

  const ticketTypes = activityFormContext.getValues('tickets.ticketTypes');

  const { formState, setValue, watch } = useFormContext<RequestedInfo>();

  const ticketTypeKeys = watch('ticketTypeKeys');

  if (!ticketTypes?.length) {
    return (
      <Text
        mx="auto"
        variant="bodyMd"
        color="errorLabel"
        tx="errors.tickets.empty"
      />
    );
  }

  const allSelected = ticketTypeKeys.length === ticketTypes.length;

  const toggleAll = () => {
    setValue(
      'ticketTypeKeys',
      allSelected
        ? []
        : ticketTypes.map((ticketType) => ticketType.ticketTypeKey),
      {
        shouldDirty: true,
        shouldValidate: formState.isSubmitted,
      },
    );
  };

  const renderTicketTypeCheckbox = (ticketType: TicketType) => {
    const isChecked = ticketTypeKeys.includes(ticketType.ticketTypeKey);

    const toggleCheckbox = () => {
      setValue(
        'ticketTypeKeys',
        isChecked
          ? ticketTypeKeys.filter(
              (ticketTypeKey) => ticketTypeKey !== ticketType.ticketTypeKey,
            )
          : [...ticketTypeKeys, ticketType.ticketTypeKey],
        {
          shouldValidate: formState.isSubmitted,
          shouldDirty: true,
        },
      );
    };

    return (
      <Checkbox
        checked={isChecked}
        key={ticketType.ticketTypeKey}
        onChange={toggleCheckbox}
        text={ticketType.name}
      />
    );
  };

  return (
    <Box flex flexDirection="column" gap={16}>
      <Text
        variant="bodyMdBold"
        tx="label.create-activity.request-more-info.ask-question-on.label"
      />

      {isTxString(formState.errors.ticketTypeKeys?.message) && (
        <Text
          variant="bodyMd"
          color="errorLabel"
          tx={formState.errors.ticketTypeKeys?.message}
        />
      )}

      <Checkbox
        checked={allSelected}
        onChange={toggleAll}
        tx="label.create-activity.participants.target-group.pick-grade-radio-all"
      />

      <Box flex flexWrap="wrap" gap={16}>
        {ticketTypes.map(renderTicketTypeCheckbox)}
      </Box>
    </Box>
  );
}

function RequestedInfoItems() {
  const formContext = React.useContext(ActivityFormContext);

  const pickRequestedInfoTypeContext = React.useContext(
    PickRequestedInfoTypeContext,
  );
  const requestedInfoFormContext = React.useContext(RequestedInfoFormContext);
  const activityFormContext = React.useContext(ActivityFormContext);

  const firstTicketType = activityFormContext.getValues(
    'tickets.ticketTypes.0',
  );

  const addRequestedInfoItem = () => {
    pickRequestedInfoTypeContext.setIsOpen(true);
  };

  const requestedInfoItems = formContext.watch('requestMoreInfo.requestedInfo');

  const disabled =
    !firstTicketType ||
    pickRequestedInfoTypeContext.isOpen ||
    !!requestedInfoFormContext.data.defaultValues;

  const renderRequestedInfoTableRow = (requestedInfo: RequestedInfo) => {
    const selectRow = () => {
      requestedInfoFormContext.setData({
        mode: 'edit',
        defaultValues: requestedInfo,
      });
    };

    const removeRequestedInfo: React.MouseEventHandler<HTMLButtonElement> = (
      e,
    ) => {
      e.stopPropagation();

      if (
        requestedInfoFormContext.data.defaultValues?.id === requestedInfo.id
      ) {
        requestedInfoFormContext.setData({
          mode: 'create',
          defaultValues: null,
        });
      }

      formContext.setValue(
        'requestMoreInfo.requestedInfo',
        (requestedInfoItems ?? []).filter(({ id }) => id !== requestedInfo.id),
      );
    };

    const selectedTicketTypeNames = activityFormContext
      .getValues('tickets.ticketTypes')!
      .map((ticketType) =>
        requestedInfo.ticketTypeKeys.includes(ticketType.ticketTypeKey)
          ? ticketType.name
          : null,
      )
      .filter((ticketName): ticketName is string => !!ticketName)
      .join(', ');

    return (
      <TableRow
        highlight={
          requestedInfo.id === requestedInfoFormContext.data.defaultValues?.id
        }
        onClick={selectRow}
        key={requestedInfo.id}
      >
        <TableCell tx={getRequestedInfoTypeTx(requestedInfo.type)} />
        <TableCell text={requestedInfo.question} />
        {selectedTicketTypeNames ? (
          <TableCell text={selectedTicketTypeNames} />
        ) : (
          <TableCell>
            <Icon color="errorIcon" mr={8} name="exclamation-triangle-solid" />
            <Text
              variant="bodySm"
              color="errorLabel"
              tx="errors.tickets.empty"
            />
          </TableCell>
        )}
        <TableCell width={40} hoverCell fixedRight>
          <IconButton icon="trash-outline" onClick={removeRequestedInfo} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Box flexJustify="between" gap={16} flexWrap="wrap" flex>
        <Box
          height="fit-content"
          flexAlign="center"
          gap={16}
          flexWrap="wrap"
          flex
        >
          <GoBackButton />

          <Text
            as="h1"
            color="pageTitle"
            tx="label.create-activity.tabs.requested-info.label"
            variant="titleMd"
          />
        </Box>

        <Box flex flexWrap="wrap" flexAlign="center" gap={16}>
          <SaveActivityAsDraftButton />

          <EventSubmitButton />
        </Box>
      </Box>

      <Box gap={16} flex flexDirection="column">
        <Box flex flexJustify="between">
          <Text
            text={getOptionalLabelText(
              'label.create-activity.request-more-info.steps.1',
            )}
            variant="bodyMdBold"
          />

          <Tooltip
            placement="left"
            titleTx="label.create-activity.request-more-info.add.label"
          >
            <IconButton
              disabled={disabled}
              icon="plus-circle-outline"
              onClick={addRequestedInfoItem}
            />
          </Tooltip>
        </Box>

        {requestedInfoItems?.length ? (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead tx="label.create-activity.request-more-info.table.type" />
                <TableHead tx="label.create-activity.request-more-info.table.question" />
                <TableHead tx="label.create-activity.request-more-info.table.tickets" />
                <TableHead fixedRight />
              </TableRow>
            </TableHeader>

            <TableBody>
              {requestedInfoItems.map(renderRequestedInfoTableRow)}
            </TableBody>
          </Table>
        ) : (
          <EmptyState
            buttonTx="label.create-activity.request-more-info.add.label"
            buttonOnClick={addRequestedInfoItem}
            disabled={disabled}
            titleTx={
              firstTicketType
                ? 'label.create-activity.request-more-info.empty-state.requested-info-empty-label'
                : 'label.create-activity.request-more-info.empty-state.tickets-empty-label'
            }
          />
        )}
      </Box>
    </React.Fragment>
  );
}

function ToggleIsRequired() {
  const { formState, watch, setValue, trigger } =
    useFormContext<RequestedInfo>();

  const isRequired = watch('isRequired');

  const toggleIsRequired = () => {
    setValue('isRequired', !isRequired, {
      shouldDirty: true,
      shouldValidate: formState.isSubmitted,
    });

    if (formState.isSubmitted) {
      trigger('options');
    }
  };

  return (
    <Switch
      checked={isRequired}
      onClick={toggleIsRequired}
      tx="label.create-activity.request-more-info.toggle.label"
    />
  );
}

function FreeTextForm(props: { defaultValues: ActivityFreeText }) {
  const { defaultValues } = props;

  const formMethods = React.useContext(ActivityFormContext);

  const requestedInfoFormContext = React.useContext(RequestedInfoFormContext);

  const freetextFormMethods = useForm<ActivityFreeText>({
    defaultValues,
    resolver: joiResolver(FreeTextValidation),
  });

  const saveFreetext = freetextFormMethods.handleSubmit(
    (data) => {
      const currentState =
        formMethods.getValues('requestMoreInfo.requestedInfo') ?? [];

      if (requestedInfoFormContext.data.mode === 'create') {
        formMethods.setValue(
          'requestMoreInfo.requestedInfo',
          [...currentState, data],
          {
            shouldDirty: true,
          },
        );
      } else {
        const index = currentState.findIndex((item) => item.id === data.id);

        if (index === -1) {
          return;
        }

        currentState[index] = data;

        formMethods.setValue('requestMoreInfo.requestedInfo', currentState, {
          shouldDirty: true,
        });
      }

      requestedInfoFormContext.setData({
        mode: 'create',
        defaultValues: null,
      });
    },
    (err) => {
      Logger.warning('saveEventFreetextQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    freetextFormMethods.reset(defaultValues);
  }, [freetextFormMethods, defaultValues]);

  return (
    <React.Fragment>
      <ContentSidebarContentContainer>
        <Text
          variant="bodyLgBold"
          tx="label.create-activity.request-more-info.options.free-text"
        />

        <FormProvider {...freetextFormMethods}>
          <ToggleIsRequired />

          <ControlledInput
            labelTx="label.create-activity.request-more-info.item.question.label"
            maxLength={REQUESTED_INFO_QUESTION_MAX_LENGTH}
            name="question"
            required
          />

          <PickTickets />
        </FormProvider>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button onClick={saveFreetext} tx="button.save" variant="secondary" />
      </ContentSidebarFooterContainer>
    </React.Fragment>
  );
}

function CheckboxForm(props: { defaultValues: ActivityCheckbox }) {
  const { defaultValues } = props;

  const formMethods = React.useContext(ActivityFormContext);
  const requestedInfoFormContext = React.useContext(RequestedInfoFormContext);

  const checkboxFormMethods = useForm<ActivityCheckbox>({
    defaultValues,
    resolver: joiResolver(CheckboxValidation),
  });

  const saveCheckbox = checkboxFormMethods.handleSubmit(
    (data) => {
      const currentState =
        formMethods.getValues('requestMoreInfo.requestedInfo') ?? [];

      if (requestedInfoFormContext.data.mode === 'create') {
        formMethods.setValue(
          'requestMoreInfo.requestedInfo',
          [...currentState, data],
          {
            shouldDirty: true,
          },
        );
      } else {
        const index = currentState.findIndex((item) => item.id === data.id);

        if (index === -1) {
          return;
        }

        currentState[index] = data;

        formMethods.setValue('requestMoreInfo.requestedInfo', currentState, {
          shouldDirty: true,
        });
      }

      requestedInfoFormContext.setData({
        mode: 'create',
        defaultValues: null,
      });
    },
    (err) => {
      Logger.warning('saveEventCheckboxQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    checkboxFormMethods.reset(defaultValues);
  }, [checkboxFormMethods, defaultValues]);

  return (
    <React.Fragment>
      <ContentSidebarContentContainer>
        <Text
          variant="bodyLgBold"
          tx="label.create-activity.request-more-info.options.checkbox"
        />

        <FormProvider {...checkboxFormMethods}>
          <ControlledInput
            labelTx="label.create-activity.request-more-info.item.question.label"
            maxLength={REQUESTED_INFO_QUESTION_MAX_LENGTH}
            name="question"
            required
          />

          <PickTickets />
        </FormProvider>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button onClick={saveCheckbox} tx="button.save" variant="secondary" />
      </ContentSidebarFooterContainer>
    </React.Fragment>
  );
}

function ToggleEnablePriceIncrement() {
  const { watch, setValue } = useFormContext<ActivityMultiChoice>();

  const enablePriceIncrement = watch('enablePriceIncrement');

  const toggleEnablePriceIncrement = () => {
    setValue('enablePriceIncrement', !enablePriceIncrement);
  };

  return (
    <Switch
      checked={enablePriceIncrement}
      onClick={toggleEnablePriceIncrement}
      tx="label.create-activity.request-more-info.price-increment.label"
    />
  );
}

function MultiChoiceFormOptions() {
  const { formState, watch, setValue } = useFormContext<ActivityMultiChoice>();

  const options = watch('options');
  const enablePriceIncrement = watch('enablePriceIncrement');
  const allowPaidTickets = useSelector(
    OrbiPaySettingsSelector.selectAllowPaidTickets,
  );
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const renderMultiChoiceOption = (
    option: ActivityRequestedInfoOption,
    index: number,
  ) => {
    const removeOption = () => {
      setValue(
        'options',
        options.filter(({ id }) => id !== option.id),
      );
    };

    return (
      <Box key={option.id} gap={16} flex>
        <ControlledInput
          labelTx="label.create-activity.request-more-info.item.options.option"
          labelTxArgs={{ index: index + 1 }}
          maxLength={REQUESTED_INFO_OPTION_LABEL_MAX_LENGTH}
          name={`options.${index}.label`}
          required
        />

        <ControlledInput
          disabled={!allowPaidTickets || !enablePriceIncrement}
          label={getCurrencyLabelText(
            'label.create-activity.request-more-info.item.options.additional-cost',
            currency,
          )}
          name={`options.${index}.priceIncrement`}
          required
        />

        <IconButton
          disabled={options.length <= 2}
          icon="trash-outline"
          mt={20}
          onClick={removeOption}
        />
      </Box>
    );
  };

  return (
    <Box flex flexDirection="column" gap={16}>
      {isTxString(formState.errors.options?.root?.message) && (
        <Text
          tx={formState.errors.options?.root?.message}
          variant="bodyMd"
          color="errorLabel"
        />
      )}

      {options.map(renderMultiChoiceOption)}
    </Box>
  );
}

function MultiChoiceForm(props: { defaultValues: ActivityMultiChoice }) {
  const { defaultValues } = props;

  const formMethods = React.useContext(ActivityFormContext);
  const requestedInfoFormContext = React.useContext(RequestedInfoFormContext);

  const multichoiceFormMethods = useForm<ActivityMultiChoice>({
    defaultValues,
    resolver: joiResolver(MultiChoiceValidation),
  });

  const saveMultichoice = multichoiceFormMethods.handleSubmit(
    (data) => {
      const currentState =
        formMethods.getValues('requestMoreInfo.requestedInfo') ?? [];

      if (requestedInfoFormContext.data.mode === 'create') {
        formMethods.setValue(
          'requestMoreInfo.requestedInfo',
          [...currentState, data],
          {
            shouldDirty: true,
          },
        );
      } else {
        const index = currentState.findIndex((item) => item.id === data.id);

        if (index === -1) {
          return;
        }

        currentState[index] = data;

        formMethods.setValue('requestMoreInfo.requestedInfo', currentState, {
          shouldDirty: true,
        });
      }

      requestedInfoFormContext.setData({
        mode: 'create',
        defaultValues: null,
      });
    },
    (err) => {
      Logger.warning('saveEventMultichoiceQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  const addOption = () => {
    const options = multichoiceFormMethods.getValues('options') ?? [];

    const newOption: ActivityRequestedInfoOption = {
      id: getUID(),
      index: options.length,
      isNew: true,
      label: '',
      priceIncrement: 0,
    };

    multichoiceFormMethods.setValue('options', [...options, newOption], {
      shouldDirty: true,
    });
  };

  React.useEffect(() => {
    multichoiceFormMethods.reset(defaultValues);
  }, [multichoiceFormMethods, defaultValues]);

  return (
    <React.Fragment>
      <ContentSidebarContentContainer>
        <Text
          variant="bodyLgBold"
          tx="label.create-activity.request-more-info.options.multi-choice"
        />

        <FormProvider {...multichoiceFormMethods}>
          <Box gap={16} flexWrap="wrap" flex>
            <ToggleIsRequired />

            <ToggleEnablePriceIncrement />
          </Box>

          <ControlledInput
            labelTx="label.create-activity.request-more-info.item.question.label"
            maxLength={REQUESTED_INFO_QUESTION_MAX_LENGTH}
            name="question"
            required
          />

          <PickTickets />

          <MultiChoiceFormOptions />
        </FormProvider>

        <Button
          mx="auto"
          onClick={addOption}
          tx="label.create-activity.request-more-info.add-option"
          variant="secondary"
        />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          onClick={saveMultichoice}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </React.Fragment>
  );
}

function RequestedInfoForm() {
  const requestedInfoFormContext = React.useContext(RequestedInfoFormContext);

  const defaultValues = requestedInfoFormContext.data.defaultValues;

  switch (defaultValues?.type) {
    case RequestedInfoType.FreeText:
      return <FreeTextForm defaultValues={defaultValues} />;

    case RequestedInfoType.MultiChoice:
      return <MultiChoiceForm defaultValues={defaultValues} />;

    case RequestedInfoType.Checkbox:
      return <CheckboxForm defaultValues={defaultValues} />;

    default:
      return null;
  }
}

export function PickRequestedInfoFormSidebar() {
  const requestedInfoFormContext = React.useContext(RequestedInfoFormContext);

  const location = useLocation();

  const closeSidebar = () => {
    requestedInfoFormContext.setData({
      mode: 'create',
      defaultValues: null,
    });
  };

  if (!location.pathname.endsWith('/request-more-info')) {
    return null;
  }

  return (
    <ContentSidebar
      isOpen={!!requestedInfoFormContext.data.defaultValues}
      onClose={closeSidebar}
      width={470}
    >
      <RequestedInfoForm />
    </ContentSidebar>
  );
}

function getRequestedInfoDefaultValues(
  requestedInfoType: RequestedInfoType,
  index: number,
): RequestedInfo {
  switch (requestedInfoType) {
    case RequestedInfoType.FreeText:
      const freetext: ActivityFreeText = {
        id: getUID(),
        index,
        isNew: true,
        isRequired: false,
        question: '',
        ticketTypeKeys: [],
        type: RequestedInfoType.FreeText,
      };

      return freetext;

    case RequestedInfoType.MultiChoice:
      const multichoice: ActivityMultiChoice = {
        enablePriceIncrement: false,
        id: getUID(),
        index,
        isNew: true,
        isRequired: false,
        options: [
          {
            id: getUID(),
            index: 0,
            isNew: true,
            label: '',
            priceIncrement: 0,
          },
          {
            id: getUID(),
            index: 1,
            isNew: true,
            label: '',
            priceIncrement: 0,
          },
        ],
        question: '',
        ticketTypeKeys: [],
        type: RequestedInfoType.MultiChoice,
      };

      return multichoice;

    case RequestedInfoType.Checkbox:
      const checkbox: ActivityCheckbox = {
        enablePriceIncrement: false,
        id: getUID(),
        index,
        isNew: true,
        priceIncrement: 0,
        question: '',
        ticketTypeKeys: [],
        type: RequestedInfoType.Checkbox,
      };

      return checkbox;
  }
}

export function PickRequestedInfoTypeSidebar() {
  const location = useLocation();

  const requestedInfoFormContext = React.useContext(RequestedInfoFormContext);

  const pickRequestedInfoTypeContext = React.useContext(
    PickRequestedInfoTypeContext,
  );

  const formMethods = React.useContext(ActivityFormContext);

  const closeSidebar = () => pickRequestedInfoTypeContext.setIsOpen(false);

  const [requestedInfoType, setSelectedRequestedInfoType] = React.useState(
    RequestedInfoType.FreeText,
  );

  const selectFreeText = () => {
    setSelectedRequestedInfoType(RequestedInfoType.FreeText);
  };

  const selectMultiChoice = () => {
    setSelectedRequestedInfoType(RequestedInfoType.MultiChoice);
  };

  const selectCheckbox = () => {
    setSelectedRequestedInfoType(RequestedInfoType.Checkbox);
  };

  const continueToForm = () => {
    pickRequestedInfoTypeContext.setIsOpen(false);

    requestedInfoFormContext.setData({
      mode: 'create',
      defaultValues: getRequestedInfoDefaultValues(
        requestedInfoType,
        formMethods.getValues('requestMoreInfo.requestedInfo')?.length ?? 0,
      ),
    });

    setSelectedRequestedInfoType(RequestedInfoType.FreeText);
  };

  if (!location.pathname.endsWith('/request-more-info')) {
    return null;
  }

  return (
    <ContentSidebar
      isOpen={pickRequestedInfoTypeContext.isOpen}
      onClose={closeSidebar}
      width={470}
    >
      <ContentSidebarContentContainer>
        <Text
          variant="bodyLgBold"
          tx="label.create-activity.request-more-info.pick-request-type.header"
        />

        <Box flexAlign="center" flex gap={16}>
          <Radio
            checked={requestedInfoType === RequestedInfoType.FreeText}
            onChange={selectFreeText}
            tx="label.create-activity.request-more-info.options.free-text"
          />

          <Tooltip
            placement="left"
            titleTx="label.create-activity.request-more-info.pick-request-type.free-text"
          >
            <Icon color="tooltipIcon" name="question-mark-circle-solid" />
          </Tooltip>
        </Box>

        <Box flexAlign="center" flex gap={16}>
          <Radio
            checked={requestedInfoType === RequestedInfoType.MultiChoice}
            onChange={selectMultiChoice}
            tx="label.create-activity.request-more-info.options.multi-choice"
          />

          <Tooltip
            placement="left"
            titleTx="label.create-activity.request-more-info.pick-request-type.multi-choice"
          >
            <Icon color="tooltipIcon" name="question-mark-circle-solid" />
          </Tooltip>
        </Box>

        <Box flexAlign="center" flex gap={16}>
          <Radio
            checked={requestedInfoType === RequestedInfoType.Checkbox}
            onChange={selectCheckbox}
            tx="label.create-activity.request-more-info.options.checkbox"
          />

          <Tooltip
            placement="left"
            titleTx="label.create-activity.request-more-info.pick-request-type.checkbox"
          >
            <Icon color="tooltipIcon" name="question-mark-circle-solid" />
          </Tooltip>
        </Box>
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          onClick={continueToForm}
          tx="button.continue"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </ContentSidebar>
  );
}

function TabFooter() {
  const rootPath = useRootPath();

  return (
    <Box p={32} flex flexJustify="between" gap={16}>
      <Tooltip titleTx="button.previous" placement="right">
        <IconButton
          to={`${rootPath}/tickets`}
          icon="arrow-left-circle-outline"
        />
      </Tooltip>

      <Tooltip placement="left" titleTx="button.continue">
        <IconButton
          to={`${rootPath}/co-hosting`}
          icon="arrow-right-circle-outline"
        />
      </Tooltip>
    </Box>
  );
}

export function CreateActivityRequestMoreInfo() {
  return (
    <React.Fragment>
      <InnerContentContainer>
        <RequestedInfoItems />
      </InnerContentContainer>

      <TabFooter />
    </React.Fragment>
  );
}
