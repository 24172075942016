import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  EmptyState,
  Icon,
  IconButton,
  InnerContentContainer,
  Link,
  List,
  ListItem,
  NavigateWithQuery,
  Radio,
  SearchInput,
  SelectionCard,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  createUniqueArray,
  flattenFieldErrorsObject,
  getUID,
  isTxString,
  useNavigateWithQuery,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { LEARN_MORE_ABOUT_MEMBERSHIPS_URL } from '../../../../../constants';
import {
  useMembershipSearch,
  useSuggestedMembershipApplicationRequirements,
} from '../../../../../helpers';
import {
  CreateMembershipTypeForm,
  MembershipApplicationRequirement,
  MembershipTypeType,
  PartialMembershipType,
  SettingsForm,
  SettingsFormValidation,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  getSuggestedMembershipsThunk,
  searchActions,
  useDispatch,
} from '../../../../../store';
import { getOptionalLabelText } from '../../../../../utils';
import { CreateMembershipTypeFormNavBlocker } from '../components';

const SettingsContext = React.createContext({
  sidebarIsOpen: false,
  openSidebar: () => {},
  closeSidebar: () => {},
});

function SettingsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [sidebarIsOpen, setSidebarIsOpen] = React.useState(false);

  const openSidebar = () => {
    setSidebarIsOpen(true);
  };

  const closeSidebar = () => {
    setSidebarIsOpen(false);
  };

  const value = {
    sidebarIsOpen,
    openSidebar,
    closeSidebar,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

function SelectMembershipType() {
  const { clearErrors, formState, watch, setValue } =
    useFormContext<SettingsForm>();

  const type = watch('type');

  const handleSelectionCardClick = (type: MembershipTypeType) => () => {
    clearErrors('type');
    setValue('type', type);
  };

  return (
    <Box>
      <Box flex flexDirection="column" gap={24}>
        <Box flexWrap="wrap" flex gap={32}>
          <SelectionCard
            titleTx="label.memberships.create-membership.settings.membership-type.standard"
            titleVariant="titleSm"
            toggled={type === 'standard'}
            onClick={handleSelectionCardClick('standard')}
            gap={24}
            listItems={[
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.ask-questions-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.ask-questions',
              },
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.take-payments-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.take-payments',
              },
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.invite-members-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.invite-members',
              },
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.require-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.require',
              },
            ]}
          />

          <SelectionCard
            titleTx="label.memberships.create-membership.settings.membership-type.needs-approval"
            titleVariant="titleSm"
            toggled={type === 'needs_approval'}
            onClick={handleSelectionCardClick('needs_approval')}
            gap={24}
            listItems={[
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.standard-features-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.standard-features',
              },
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.review-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.review',
              },
            ]}
          />

          <SelectionCard
            titleTx="label.memberships.create-membership.settings.membership-type.invite-only"
            titleVariant="titleSm"
            toggled={type === 'invite_only'}
            onClick={handleSelectionCardClick('invite_only')}
            chipTx="label.memberships.create-membership.settings.membership-type.hidden-pill"
            gap={24}
            listItems={[
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.standard-features-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.standard-features',
              },
              {
                tooltipTx:
                  'label.memberships.create-membership.settings.membership-type.hidden-tooltip',
                labelTx:
                  'label.memberships.create-membership.settings.membership-type.hidden',
              },
            ]}
          />
        </Box>
      </Box>

      {isTxString(formState.errors.type?.message) && (
        <Text
          color="errorLabel"
          mt={16}
          tx={formState.errors.type?.message}
          variant="bodyMd"
        />
      )}
    </Box>
  );
}

function ToggleRequiredMemberships() {
  const { watch, setValue } = useFormContext<SettingsForm>();

  const requiredMembershipTypes = watch('requiredMembershipTypes');

  const toggleRequiredMemberships = () => {
    setValue(
      'requiredMembershipTypes',
      requiredMembershipTypes === null
        ? {
            combinationType: 'all',
            membershipApplicationRequirements: [],
          }
        : null,
    );
  };

  return (
    <Box flex flexAlign="center" gap={8}>
      <Switch
        checked={requiredMembershipTypes !== null}
        tx="label.memberships.create-membership.settings.combination-type.label"
        onClick={toggleRequiredMemberships}
      />

      <Tooltip
        placement="left"
        titleTx="label.memberships.create-membership.settings.combination-type.tooltip"
      >
        <Icon color="checkmarkIcon" name="question-mark-circle-solid" />
      </Tooltip>
    </Box>
  );
}

function AddRequiredMembershipButton() {
  const { sidebarIsOpen, openSidebar } = React.useContext(SettingsContext);

  const { watch } = useFormContext<SettingsForm>();

  const requiredMembershipTypes = watch(
    'requiredMembershipTypes.membershipApplicationRequirements',
  );

  return (
    <Tooltip
      placement="left"
      titleTx="label.memberships.required-memberships.empty-state.button"
    >
      <IconButton
        disabled={requiredMembershipTypes === null || sidebarIsOpen}
        icon="plus-circle-outline"
        onClick={openSidebar}
      />
    </Tooltip>
  );
}

function PickCombinationType() {
  const { setValue, watch } = useFormContext<SettingsForm>();

  const [combinationType, membershipApplicationRequirements] = watch([
    'requiredMembershipTypes.combinationType',
    'requiredMembershipTypes.membershipApplicationRequirements',
  ]);

  const disabled =
    !membershipApplicationRequirements ||
    (membershipApplicationRequirements &&
      membershipApplicationRequirements.length < 2);

  const selectCombinationTypeAll = () => {
    setValue('requiredMembershipTypes.combinationType', 'all');
  };

  const selectCombinationTypeAny = () => {
    setValue('requiredMembershipTypes.combinationType', 'any');
  };

  return (
    <Box flex flexAlign="center" flexJustify="between" flexWrap="wrap" gap={24}>
      <Box flex gap={16} flexWrap="wrap">
        <Radio
          checked={combinationType === 'all'}
          disabled={disabled}
          onChange={selectCombinationTypeAll}
          tx="label.memberships.create-membership.settings.combination-type.all"
        />

        <Radio
          checked={combinationType === 'any'}
          disabled={disabled}
          onChange={selectCombinationTypeAny}
          tx="label.memberships.create-membership.settings.combination-type.any"
        />
      </Box>
    </Box>
  );
}

function AddRequiredMembershipsEmptyState() {
  const { sidebarIsOpen, openSidebar } = React.useContext(SettingsContext);
  const { watch } = useFormContext<SettingsForm>();

  const requiredMembershipTypes = watch('requiredMembershipTypes');

  return (
    <EmptyState
      buttonOnClick={openSidebar}
      buttonTx="label.memberships.required-memberships.empty-state.button"
      disabled={requiredMembershipTypes === null || sidebarIsOpen}
      titleTx="label.memberships.required-memberships.empty-state.label"
    />
  );
}

function SettingsContent() {
  const { watch, setValue } = useFormContext<SettingsForm>();

  const requiredMembershipTypes = watch('requiredMembershipTypes');

  if (!requiredMembershipTypes?.membershipApplicationRequirements.length) {
    return <AddRequiredMembershipsEmptyState />;
  }

  const renderApplicationRequirementRow = (
    membershipApplicationRequirement: MembershipApplicationRequirement,
  ) => {
    const removeApplicationRequirement = () => {
      setValue(
        'requiredMembershipTypes.membershipApplicationRequirements',
        requiredMembershipTypes.membershipApplicationRequirements.filter(
          ({ membershipTypeKey }) =>
            membershipTypeKey !==
            membershipApplicationRequirement.membershipTypeKey,
        ),
      );
    };

    return (
      <TableRow
        key={`required-membership-${membershipApplicationRequirement.membershipTypeKey}`}
      >
        <TableCell text={membershipApplicationRequirement.name} />
        <TableCell text={membershipApplicationRequirement.departmentName} />
        <TableCell width={40} hoverCell fixedRight>
          <IconButton
            icon="trash-outline"
            onClick={removeApplicationRequirement}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead tx="label.memberships.create-membership.settings.membership-name.label" />
          <TableHead tx="label.memberships.create-membership.settings.department-name.label" />
          <TableHead fixedRight />
        </TableRow>
      </TableHeader>
      <TableBody>
        {requiredMembershipTypes.membershipApplicationRequirements.map(
          renderApplicationRequirementRow,
        )}
      </TableBody>
    </Table>
  );
}

function SettingsGridAndCombinationType() {
  return (
    <React.Fragment>
      <Box flex flexDirection="column" gap={24}>
        <Text
          text={getOptionalLabelText(
            'label.memberships.create-membership.settings.steps.2',
          )}
          variant="bodyMdBold"
        />

        <ToggleRequiredMemberships />
      </Box>

      <Box flex flexDirection="column" gap={24}>
        <Box flexAlign="center" flex flexJustify="between">
          <Text
            text={getOptionalLabelText(
              'label.memberships.create-membership.settings.steps.3',
            )}
            variant="bodyMdBold"
          />

          <AddRequiredMembershipButton />
        </Box>

        <PickCombinationType />
      </Box>

      <SettingsContent />
    </React.Fragment>
  );
}

function RequiredMembershipsFormContent(props: {
  onSave: (data: MembershipApplicationRequirement[]) => void;
  alreadySelectedMembershipTypeKeys: string[];
}) {
  const { alreadySelectedMembershipTypeKeys, onSave } = props;

  const dispatch = useDispatch();

  const { search, searchResult, searchString, setSearchString } =
    useMembershipSearch(['invite_only', 'needs_approval', 'standard']);

  const [selectedMemberships, setSelectedMemberships] = React.useState<
    MembershipApplicationRequirement[]
  >([]);

  const suggestedMemberships = useSuggestedMembershipApplicationRequirements(
    5,
    [
      ...alreadySelectedMembershipTypeKeys,
      ...selectedMemberships.map((item) => item.membershipTypeKey),
    ],
  );

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setSearchString(e.target.value);
    search(e.target.value);
  };

  const renderSearchResultMembership = (
    searchResultMembership: PartialMembershipType,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) => [
        ...prev,
        {
          departmentName: searchResultMembership.departmentName,
          logoUrl: searchResultMembership.logo.thumbnail64.url,
          membershipTypeKey: searchResultMembership.membershipTypeKey,
          name: searchResultMembership.name,
          membershipApplicationRequirementKey: getUID(),
        },
      ]);
    };

    return (
      <ListItem
        key={`search-result-membership-${searchResultMembership.membershipTypeKey}`}
        avatarSrc={searchResultMembership.logo.thumbnail64.url}
        onClick={addMembershipToRequiredMemberships}
        subtitle={searchResultMembership.departmentName}
        title={searchResultMembership.name}
      />
    );
  };

  const renderSuggestedMembership = (
    suggestedMembership: MembershipApplicationRequirement,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) => [
        ...prev,
        {
          departmentName: suggestedMembership.departmentName,
          logoUrl: suggestedMembership.logoUrl,
          membershipTypeKey: suggestedMembership.membershipTypeKey,
          name: suggestedMembership.name,
          membershipApplicationRequirementKey: getUID(),
        },
      ]);
    };

    return (
      <ListItem
        key={`suggested-membership-${suggestedMembership.membershipTypeKey}`}
        avatarSrc={suggestedMembership.logoUrl}
        onClick={addMembershipToRequiredMemberships}
        subtitle={suggestedMembership.departmentName}
        title={suggestedMembership.name}
      />
    );
  };

  const renderSelectedMembership = (
    selectedMembership: MembershipApplicationRequirement,
  ) => {
    const removeMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) =>
        prev.filter(
          (item) =>
            item.membershipTypeKey !== selectedMembership.membershipTypeKey,
        ),
      );
    };

    return (
      <ListItem
        icon="check-circle-solid"
        iconColor="listItemIcon"
        key={`selected-membership-${selectedMembership.membershipTypeKey}`}
        avatarSrc={selectedMembership.logoUrl}
        onClick={removeMembershipToRequiredMemberships}
        subtitle={selectedMembership.departmentName}
        title={selectedMembership.name}
      />
    );
  };

  const handleSave = () => {
    onSave(selectedMemberships);
    setSelectedMemberships([]);
    setSearchString('');
    dispatch(searchActions.clearMembershipTypes());
  };

  React.useEffect(() => {
    dispatch(
      getSuggestedMembershipsThunk({
        includeHidden: false,
      }),
    );
  }, [dispatch]);

  const selectedMembershipTypeKeysSet = new Set([
    ...selectedMemberships.map((item) => item.membershipTypeKey),
    ...alreadySelectedMembershipTypeKeys,
  ]);

  const uniqueSearchResults = createUniqueArray(
    searchResult,
    'membershipTypeKey',
  ).filter(
    (item) => !selectedMembershipTypeKeysSet.has(item.membershipTypeKey),
  );

  const shouldRenderSearchResults = uniqueSearchResults.length > 0;
  const shouldRenderSelectedMemberships = selectedMemberships.length > 0;
  const shouldRenderSuggestedMemberships =
    uniqueSearchResults.length === 0 &&
    Array.isArray(suggestedMemberships) &&
    suggestedMemberships.length > 0;

  return (
    <React.Fragment>
      <Box p={32} flex flexDirection="column" gap={32}>
        <Text
          tx="label.create-activity.tickets.add-required-memberships"
          variant="bodyLgBold"
        />

        <SearchInput
          onChange={handleSearchChange}
          placeholderTx="placeholder.search-for-memberships"
          value={searchString}
        />
      </Box>

      <ContentSidebarContentContainer pt={0}>
        {searchString.length > 0 && !shouldRenderSearchResults && (
          <Text
            textAlign="center"
            tx="placeholder.no-search-results"
            variant="bodySm"
          />
        )}

        {shouldRenderSearchResults && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.search-results"
            />

            <List>{uniqueSearchResults.map(renderSearchResultMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSuggestedMemberships && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.suggested-memberships"
            />

            <List>{suggestedMemberships.map(renderSuggestedMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSelectedMemberships && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.required-memberships"
            />

            <List>{selectedMemberships.map(renderSelectedMembership)}</List>
          </React.Fragment>
        )}
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button tx="button.save" onClick={handleSave} variant="primary" />
      </ContentSidebarFooterContainer>
    </React.Fragment>
  );
}

function SettingsSidebar() {
  const { closeSidebar, sidebarIsOpen } = React.useContext(SettingsContext);

  const { setValue, getValues } = useFormContext<SettingsForm>();

  const saveMembershipApplicationRequirements = (
    data: MembershipApplicationRequirement[],
  ) => {
    setValue('requiredMembershipTypes.membershipApplicationRequirements', [
      ...getValues('requiredMembershipTypes.membershipApplicationRequirements'),
      ...data,
    ]);

    closeSidebar();
  };

  const alreadyUsedKeys =
    getValues('requiredMembershipTypes.membershipApplicationRequirements')?.map(
      (membershipApplicationRequirement) =>
        membershipApplicationRequirement.membershipTypeKey,
    ) ?? [];

  return (
    <ContentSidebar width={470} isOpen={sidebarIsOpen} onClose={closeSidebar}>
      <RequiredMembershipsFormContent
        alreadySelectedMembershipTypeKeys={alreadyUsedKeys}
        onSave={saveMembershipApplicationRequirements}
      />
    </ContentSidebar>
  );
}

export function CreateMembershipSettings() {
  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const navigate = useNavigateWithQuery();
  const dispatch = useDispatch();

  const [requiredMembershipTypes, type] =
    createMembershipTypeFormContext.getValues([
      'requiredMembershipTypes',
      'type',
    ]);

  const formMethods = useForm<SettingsForm>({
    resolver: joiResolver(SettingsFormValidation),
    defaultValues: {
      requiredMembershipTypes,
      type,
    },
  });

  React.useEffect(() => {
    return () => {
      dispatch(searchActions.clearMembershipTypes());
    };
  }, [dispatch]);

  if (!createMembershipTypeFormContext.formState.isDirty) {
    return (
      <NavigateWithQuery to="/memberships/create-membership/general-info" />
    );
  }

  const handlePrevious = () => {
    createMembershipTypeFormContext.setValue(
      'requiredMembershipTypes',
      formMethods.getValues('requiredMembershipTypes'),
    );
    createMembershipTypeFormContext.setValue(
      'type',
      formMethods.getValues('type'),
    );

    navigate('/memberships/create-membership/general-info');
  };

  const handleNext = formMethods.handleSubmit(
    (data) => {
      const options = { shouldDirty: true };

      createMembershipTypeFormContext.setValue(
        'requiredMembershipTypes',
        data.requiredMembershipTypes,
        options,
      );
      createMembershipTypeFormContext.setValue('type', data.type, options);

      navigate('/memberships/create-membership/questions');
    },
    (err) => {
      Logger.warning('createMembershipSettings Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <FormProvider {...formMethods}>
      <CreateMembershipTypeFormNavBlocker shouldBlock />

      <SettingsProvider>
        <ContentContainer>
          <InnerContentContainer>
            <Text
              color="pageTitle"
              as="h1"
              tx="label.memberships.create-membership.tabs.settings"
              variant="titleMd"
            />

            <Box flex flexDirection="column" gap={24}>
              <Box flex flexDirection="column" gap={4}>
                <Text
                  tx="label.memberships.create-membership.settings.steps.1"
                  variant="bodyMdBold"
                />

                <Box width="fit-content">
                  <Link
                    target="_blank"
                    href={LEARN_MORE_ABOUT_MEMBERSHIPS_URL}
                    tx="label.memberships.create-membership.settings.faq-link"
                  />
                </Box>
              </Box>

              <SelectMembershipType />

              <SettingsGridAndCombinationType />
            </Box>
          </InnerContentContainer>

          <Box p={32} flex flexJustify="between" gap={16}>
            <Tooltip titleTx="button.previous" placement="right">
              <IconButton
                onClick={handlePrevious}
                icon="arrow-left-circle-outline"
              />
            </Tooltip>

            <Tooltip placement="left" titleTx="button.continue">
              <IconButton
                icon="arrow-right-circle-outline"
                onClick={handleNext}
              />
            </Tooltip>
          </Box>
        </ContentContainer>

        <SettingsSidebar />
      </SettingsProvider>
    </FormProvider>
  );
}
