import { CreatePostDto } from 'dtos/student-dashboard/v1/post/post.dto';

import {
  CreateActivityPostForm,
  CreateDepartmentPostForm,
  CreateMembershipPostForm,
  CreatePostFormFileUpload,
  GetPostCommentsPageParams,
} from '../../../../../models';

function fileUploadsMapper(fileUploads: CreatePostFormFileUpload[]) {
  const fileUploadKeys = fileUploads
    .map((fileUpload) => fileUpload.fileUploadKey)
    .filter((key): key is string => key !== null);

  if (!fileUploadKeys.length) {
    return null;
  }

  return fileUploadKeys;
}

export function createPostMapper(
  createPostForm: CreateActivityPostForm | CreateDepartmentPostForm,
): CreatePostDto {
  const fileUploadKeys = fileUploadsMapper(createPostForm.fileUploads);

  return {
    fileUploadKeys,
    message: createPostForm.message.length ? createPostForm.message : null,
    pushTo: createPostForm.pushTo,
    target: {
      key: createPostForm.targetKey,
      type: createPostForm.targetType,
    },
  };
}

export function membershipPostMapper(
  postForm: CreateMembershipPostForm,
): CreatePostDto[] {
  return postForm.targetKeys.map((targetKey): CreatePostDto => {
    const fileUploadKeys = fileUploadsMapper(postForm.fileUploads);

    return {
      message: postForm.message,
      pushTo: postForm.pushTo,

      fileUploadKeys,

      target: {
        type: 'membership_type',
        key: targetKey,
      },
    };
  });
}

export function getPostCommentsMapper(
  params: GetPostCommentsPageParams,
): GetPostCommentsPageParams {
  return {
    ...params,
    activityKey: undefined,
  };
}
