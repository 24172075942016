import {
  INITIAL_MODAL_STATE,
  ImageSliderModal,
  translate,
  useModalState,
} from '@orbiapp/components';
import React from 'react';

import { assets } from '../../assets';
import { Analytics } from '../../services';
import { AccountSelector, AuthStateSelector, useSelector } from '../../store';
import { QuickActionsContext } from '../quick-actions';

const ModalContext = React.createContext({
  postsImprovedModalState: INITIAL_MODAL_STATE,
  postsInMembershipsState: INITIAL_MODAL_STATE,
});

const POSTS_IMPROVED_MODAL_KEY = 'postsImprovedModal';
const POSTS_IN_MEMBERSHIPS_MODAL_KEY = 'postsInMembershipsModal';

export function ModalProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const postsImprovedModalState = useModalState({
    isOpen: !localStorage.getItem(POSTS_IMPROVED_MODAL_KEY),
  });

  const postsInMembershipsState = useModalState({
    isOpen: !localStorage.getItem(POSTS_IN_MEMBERSHIPS_MODAL_KEY),
  });

  return (
    <ModalContext.Provider
      value={{ postsImprovedModalState, postsInMembershipsState }}
    >
      {children}
    </ModalContext.Provider>
  );
}

const releaseDate = new Date('2024-12-10').getTime();
const endDate = new Date('2025-02-01').getTime();
const now = Date.now();
function PostsImprovedModal() {
  const createdAt = useSelector(AccountSelector.selectCreatedAt);

  const { postsImprovedModalState } = React.useContext(ModalContext);

  const accountCreatedAfterRelease = createdAt && createdAt > releaseDate;
  const hasEnded = now > endDate;

  if (accountCreatedAfterRelease || hasEnded) {
    return null;
  }

  const handleClose = () => {
    postsImprovedModalState.closeModal();
    localStorage.setItem(POSTS_IMPROVED_MODAL_KEY, 'false');
  };

  const dismissModal = () => {
    handleClose();
    Analytics.track('dismiss_posts_improved_modal');
  };

  const continueToPosts = () => {
    handleClose();
    Analytics.track('continue_posts_improved_modal');
  };

  return (
    <ImageSliderModal
      width={700}
      isOpen={postsImprovedModalState.isOpen}
      onClose={dismissModal}
      slides={[
        {
          type: 'image',
          src: assets.postsImprovedModalImage,
          descriptionTx: 'label.posts-welcome-modal.description',
          alt: translate('label.posts-welcome-modal.description'),
        },
      ]}
      titleTx="label.posts-welcome-modal.title"
      primaryButtonTx="label.posts-welcome-modal.button"
      primaryButtonOnClick={continueToPosts}
      primaryButtonTo="/posts"
    />
  );
}

function PostsInMembershipsModal() {
  const { postsInMembershipsState, postsImprovedModalState } =
    React.useContext(ModalContext);
  const { createPostModalState } = React.useContext(QuickActionsContext);

  if (postsImprovedModalState.isOpen) {
    return null;
  }

  const dismissModal = () => {
    postsInMembershipsState.closeModal();
    localStorage.setItem(POSTS_IN_MEMBERSHIPS_MODAL_KEY, 'false');
  };

  const explorePostsInMembership = () => {
    dismissModal();

    createPostModalState.openModal();
  };

  return (
    <ImageSliderModal
      width={700}
      isOpen={postsInMembershipsState.isOpen}
      onClose={dismissModal}
      slides={[
        {
          type: 'image',
          src: assets.postsInMembershipModalImage,
          descriptionTx: 'label.posts-in-membership-welcome-modal.description',
          alt: translate('label.posts-in-membership-welcome-modal.description'),
        },
      ]}
      titleTx="label.posts-in-membership-welcome-modal.title"
      primaryButtonTx="label.posts-in-membership-welcome-modal.button"
      primaryButtonOnClick={explorePostsInMembership}
    />
  );
}

export function FeatureReleaseModals() {
  const authenticated = useSelector(AuthStateSelector.selectAuthenticated);
  if (!authenticated) {
    return null;
  }

  return (
    <ModalProvider>
      <PostsImprovedModal />
      <PostsInMembershipsModal />
    </ModalProvider>
  );
}
