import { Box, Text } from '@orbiapp/components';
import styled, { css } from 'styled-components';

const SelectOptionBox = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      background-color: ${theme.colors.selectOptionBoxBackground};
      border: 1px solid ${theme.colors.selectOptionBoxBorder};
    `;
  }}
`;

const ActivityCard = styled(Box)`
  ${(props) => {
    const { theme } = props;

    return css`
      &:not(:last-of-type) {
        border-bottom: 1px solid ${theme.colors.borderPrimary};
      }
    `;
  }}
`;

const ActivityCardDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  word-break: break-word;
`;

export const Styled = {
  SelectOptionBox,
  ActivityCard,
  ActivityCardDescription,
};
