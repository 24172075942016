import {
  AvatarTableCell,
  Box,
  Chip,
  EmptyState,
  IconButton,
  Link,
  Menu,
  MenuItem,
  PageSize,
  ReactionsGroup,
  SearchInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  TinySelectInput,
  formatDate,
  getAvatarVariantFromString,
  paginatorOptions,
  translate,
  useDebounce,
  useFilter,
  useMenu,
  useScroll,
} from '@orbiapp/components';
import { PostTarget } from 'dtos/student-dashboard/v1/post/post.dto';
import React from 'react';

import {
  ActivityRecord,
  GetPostsPageParams,
  MembershipTypeListItem,
  PostOrderByKey,
  postSortableKeys,
  postTargetTypes,
} from '../../models';
import {
  ActivityRecordsSelector,
  MembershipTypeListItemsSelector,
  PostSelector,
  PostsSelector,
  getActivityRecordsThunk,
  getMembershipsTypesThunk,
  getPostThunk,
  getPostsThunk,
  initialPostsState,
  postsActions,
  useDispatch,
  useSelector,
} from '../../store';
import { theme } from '../../theme';
import { getActorAvatarSrc } from '../../utils';
import { DeletePostContext } from '../delete-post-confirm';
import { PostTargetChip } from '../post-target-chip';

const TABLE_COLUMN_WIDTHS = {
  type: 125,
  name: 225,
  message: 300,
  actor: 200,
  commentCount: 50,
  reactions: 50,
  publishedAt: 250,
  actions: 50,
};

interface PostKeyProps {
  postKey: string;
}

const PostsTableContext = React.createContext<{
  enableFiltering?: boolean;
  activityKeys: GetPostsPageParams['activityKeys'];
}>([] as any);

function useSortPostsTable() {
  const orderBy = useSelector(PostsSelector.selectOrderBy);
  const sortOrder = useSelector(PostsSelector.selectSortOrder);
  const pageSize = useSelector(PostsSelector.selectPageSize);

  const dispatch = useDispatch();

  return (newOrderBy: PostOrderByKey) => {
    dispatch(
      getPostsThunk({
        orderBy: newOrderBy,
        sortOrder:
          newOrderBy !== orderBy
            ? 'desc'
            : sortOrder === 'asc'
              ? 'desc'
              : 'asc',
        first: pageSize,
      }),
    );
  };
}

function usePostsTable() {
  const endCursor = useSelector(PostsSelector.selectEndCursor);
  const startCursor = useSelector(PostsSelector.selectStartCursor);
  const pageSize = useSelector(PostsSelector.selectPageSize);

  const dispatch = useDispatch();

  const changePageSize = (pageSize: PageSize) => {
    dispatch(getPostsThunk({ first: pageSize }));
  };

  const goToNextPage = () => {
    dispatch(getPostsThunk({ first: pageSize, after: endCursor ?? undefined }));
  };

  const goToPreviousPage = () => {
    dispatch(
      getPostsThunk({ last: pageSize, before: startCursor ?? undefined }),
    );
  };

  const goToLastPage = () => {
    dispatch(getPostsThunk({ last: pageSize }));
  };

  const goToFirstPage = () => {
    dispatch(getPostsThunk({ first: pageSize }));
  };

  return {
    changePageSize,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPreviousPage,
  };
}

function useInitPostsTable() {
  const orderBy = useSelector(PostsSelector.selectOrderBy);
  const sortOrder = useSelector(PostsSelector.selectSortOrder);
  const pageSize = useSelector(PostsSelector.selectPageSize);

  const { activityKeys } = React.useContext(PostsTableContext);

  const dispatch = useDispatch();

  const hasRunRef = React.useRef(false);

  React.useEffect(() => {
    if (hasRunRef.current) return;

    dispatch(postsActions.resetPosts());
    dispatch(postsActions.resetPost());

    dispatch(
      getPostsThunk({
        orderBy: initialPostsState.posts.orderBy,
        sortOrder: initialPostsState.posts.sortOrder,
        first: initialPostsState.posts.pageSize,
        activityKeys,
      }),
    );

    hasRunRef.current = true;
  }, [dispatch, orderBy, sortOrder, pageSize, activityKeys]);
}

function getPostTargetName(postTarget: PostTarget) {
  switch (postTarget.type) {
    case 'activity':
      return postTarget.activity.title;

    case 'department':
      return '';

    case 'membership_type':
      return postTarget.membershipType.name;
  }
}

function PostsTableRow(props: PostKeyProps) {
  const dispatch = useDispatch();

  const { openConfirm } = React.useContext(DeletePostContext);

  const selectedItemKey = useSelector(PostSelector.selectPostKey);
  const post = useSelector((state) =>
    PostsSelector.selectById(state, props.postKey),
  );

  const getItem = () => dispatch(getPostThunk(post.postKey));

  const openDeletePostConfirm: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    openConfirm(post.postKey);
  };

  return (
    <TableRow
      highlight={selectedItemKey === props.postKey}
      onClick={getItem}
      key={post.postKey}
    >
      <TableCell width={TABLE_COLUMN_WIDTHS.type}>
        <PostTargetChip {...post.target} />
      </TableCell>

      <TableCell
        width={TABLE_COLUMN_WIDTHS.name}
        text={getPostTargetName(post.target)}
      />

      <TableCell
        width={TABLE_COLUMN_WIDTHS.message}
        text={post.message ?? ''}
      />

      <AvatarTableCell
        avatarSrc={getActorAvatarSrc(post.actor)}
        text={post.actor.actorName}
        width={TABLE_COLUMN_WIDTHS.actor}
        avatarVariant={getAvatarVariantFromString(post.actor.actorKey)}
        fallbackLetter={post.actor.actorName.charAt(0)}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.commentCount}>
        <Chip variant={3} text={post.postCommentCount} />
      </TableCell>

      <TableCell width={TABLE_COLUMN_WIDTHS.reactions}>
        <ReactionsGroup
          selectedReaction={post.reactions.self}
          reactions={post.reactions}
        />
      </TableCell>

      <TableCell
        width={TABLE_COLUMN_WIDTHS.publishedAt}
        text={formatDate(post.createdAt, 'DD MMM YYYY HH:mm')}
      />

      <TableCell width={TABLE_COLUMN_WIDTHS.actions} hoverCell fixedRight>
        {!post.isDeleted && (
          <Box flex flexJustify="center" width={50}>
            <IconButton icon="trash-outline" onClick={openDeletePostConfirm} />
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}

function renderPostTableRow(postKey: string) {
  return <PostsTableRow key={postKey} postKey={postKey} />;
}

function PostsTablePagination() {
  const hasNextPage = useSelector(PostsSelector.selectHasNextPage);
  const hasPreviousPage = useSelector(PostsSelector.selectHasPreviousPage);
  const pageSize = useSelector(PostsSelector.selectPageSize);

  const {
    changePageSize,
    goToFirstPage,
    goToLastPage,
    goToNextPage,
    goToPreviousPage,
  } = usePostsTable();

  return (
    <TablePagination
      tx="label.general.rows-per-page"
      hasNextPage={hasNextPage}
      hasPrevPage={hasPreviousPage}
      currentPage={0}
      onPageSizeChange={changePageSize}
      onGoToNextPage={goToNextPage}
      onGoToPreviousPage={goToPreviousPage}
      onGoToFirstPage={goToFirstPage}
      onGoToLastPage={goToLastPage}
      pageSize={pageSize}
      paginatorOptions={paginatorOptions}
    />
  );
}

function getPostTargetTypeTx(type: GetPostsPageParams['targetType']): TxString {
  switch (type) {
    case 'activity':
      return 'label.posts.table.activity';

    case 'department':
      return 'label.posts.table.department';

    case 'membership_type':
      return 'label.posts.table.membership';

    default:
      return 'label.general.all';
  }
}

function FilterByTargetType() {
  const targetType = useSelector(PostsSelector.selectTargetType);
  const activityKeys = useSelector(PostsSelector.selectActivityKeys);
  const pageSize = useSelector(PostsSelector.selectPageSize);

  const dispatch = useDispatch();

  const menuState = useMenu();

  const renderPostTargetType = (type: GetPostsPageParams['targetType']) => {
    const selectType = () => {
      dispatch(
        getPostsThunk({
          first: pageSize,
          targetType: targetType === type ? undefined : type,
          activityKeys: targetType === 'department' ? undefined : activityKeys,
        }),
      );
    };

    return (
      <MenuItem
        key={type}
        onClick={selectType}
        isSelected={type === targetType}
        tx={getPostTargetTypeTx(type)}
      />
    );
  };

  return (
    <Box ref={menuState.clickOutsideRef} relative>
      <TinySelectInput
        placeholderTx="label.posts.table.type"
        width={130}
        onClick={menuState.toggleMenu}
        chevronIconSize={20}
        isOpen={menuState.isOpen}
        value={
          targetType
            ? translate(getPostTargetTypeTx(targetType))
            : translate('label.posts.table.type')
        }
      />

      <Menu
        maxHeight={300}
        isOpen={menuState.isOpen}
        ref={menuState.menuRef}
        absolute
        top="100%"
        mt={8}
      >
        {(['all', ...Object.values(postTargetTypes)] as const).map(
          renderPostTargetType,
        )}
      </Menu>
    </Box>
  );
}

function FilterByActivity() {
  const activityRecords = useSelector(ActivityRecordsSelector.selectData);
  const activityKeys = useSelector(PostsSelector.selectActivityKeys);

  const dispatch = useDispatch();

  const menuState = useMenu();

  const menuHeaderRef = React.useRef<HTMLDivElement>(null);
  useScroll(menuState.menuRef, (value) => {
    if (!menuHeaderRef.current) return;

    menuHeaderRef.current.style.boxShadow = value.isAtTop
      ? 'none'
      : theme.elevation.strong;
  });

  React.useEffect(() => {
    dispatch(getActivityRecordsThunk());
  }, [dispatch]);

  const renderActivityRecord = (activityRecord: ActivityRecord) => {
    const selectActivity = () => {
      dispatch(
        getPostsThunk({
          activityKeys: activityKeys?.includes(activityRecord.activityKey)
            ? activityKeys?.filter((key) => key !== activityRecord.activityKey)
            : [...(activityKeys ?? []), activityRecord.activityKey],
        }),
      );
    };

    const isSelected =
      activityKeys?.includes(activityRecord.activityKey) ?? false;

    return (
      <MenuItem
        key={activityRecord.activityKey}
        onClick={selectActivity}
        isSelected={isSelected}
        gap={8}
        checkbox
        text={activityRecord.activityTitle}
      />
    );
  };

  const filter = useFilter(activityRecords ?? [], ['activityTitle'], (a, b) =>
    a.activityTitle.localeCompare(b.activityTitle),
  );
  const hasActivities = !!activityRecords?.length;

  return (
    <Box ref={menuState.clickOutsideRef} relative>
      <TinySelectInput
        value={
          !!activityKeys?.length
            ? translate('label.posts.filtering.activities', {
                count: activityKeys.length,
              })
            : translate('label.posts.table.activity')
        }
        onClick={menuState.toggleMenu}
        maxWidth={180}
        overflow="hidden"
        isOpen={menuState.isOpen}
        chevronIconSize={20}
      />

      <Menu
        maxHeight={380}
        isOpen={menuState.isOpen}
        ref={menuState.menuRef}
        mt={8}
        width={300}
        absolute
        top="100%"
      >
        {hasActivities && activityRecords.length > 8 && (
          <Box
            ref={menuHeaderRef}
            backgroundColor="backgroundPrimary"
            p={8}
            sticky
            top={0}
            zIndex={4}
          >
            <SearchInput
              value={filter.searchString}
              onChange={filter.handleSearchStringChange}
              placeholderTx="placeholder.search"
            />
          </Box>
        )}

        {hasActivities ? (
          filter.items?.map(renderActivityRecord)
        ) : (
          <MenuItem
            textVariant="bodySm"
            tx="label.posts.table.no-events"
            notPickable
          />
        )}
      </Menu>
    </Box>
  );
}

function FilterByMembershipType() {
  const membershipTypes = useSelector(
    MembershipTypeListItemsSelector.selectAll,
  );
  const membershipTypeKeys = useSelector(
    PostsSelector.selectMembershipTypeKeys,
  );

  const dispatch = useDispatch();

  const menuState = useMenu();

  const menuHeaderRef = React.useRef<HTMLDivElement>(null);
  useScroll(menuState.menuRef, (value) => {
    if (!menuHeaderRef.current) return;

    menuHeaderRef.current.style.boxShadow = value.isAtTop
      ? 'none'
      : theme.elevation.strong;
  });

  React.useEffect(() => {
    if (membershipTypes.length === 0) {
      dispatch(getMembershipsTypesThunk());
    }
  }, [dispatch, membershipTypes.length]);

  const renderMembershipType = (membershipType: MembershipTypeListItem) => {
    const selectMembershipType = () => {
      dispatch(
        getPostsThunk({
          membershipTypeKeys: membershipTypeKeys?.includes(
            membershipType.membershipTypeKey,
          )
            ? membershipTypeKeys?.filter(
                (key) => key !== membershipType.membershipTypeKey,
              )
            : [...(membershipTypeKeys ?? []), membershipType.membershipTypeKey],
        }),
      );
    };

    const isSelected =
      membershipTypeKeys?.includes(membershipType.membershipTypeKey) ?? false;

    return (
      <MenuItem
        key={membershipType.membershipTypeKey}
        onClick={selectMembershipType}
        isSelected={isSelected}
        gap={8}
        checkbox
        text={membershipType.name}
      />
    );
  };

  const filter = useFilter(membershipTypes ?? [], ['name'], (a, b) =>
    a.name.localeCompare(b.name),
  );
  const hasMembershipTypes = !!membershipTypes?.length;

  return (
    <Box ref={menuState.clickOutsideRef} relative>
      <TinySelectInput
        value={
          !!membershipTypeKeys?.length
            ? translate('label.posts.filtering.memberships', {
                count: membershipTypeKeys.length,
              })
            : translate('label.posts.table.membership')
        }
        onClick={menuState.toggleMenu}
        maxWidth={180}
        overflow="hidden"
        isOpen={menuState.isOpen}
        chevronIconSize={20}
      />

      <Menu
        maxHeight={380}
        isOpen={menuState.isOpen}
        ref={menuState.menuRef}
        mt={8}
        width={300}
        absolute
        top="100%"
      >
        {hasMembershipTypes && membershipTypes.length > 8 && (
          <Box
            ref={menuHeaderRef}
            backgroundColor="backgroundPrimary"
            p={8}
            sticky
            top={0}
            zIndex={4}
          >
            <SearchInput
              value={filter.searchString}
              onChange={filter.handleSearchStringChange}
              placeholderTx="placeholder.search"
            />
          </Box>
        )}

        {hasMembershipTypes ? (
          filter.items?.map(renderMembershipType)
        ) : (
          <MenuItem
            textVariant="bodySm"
            tx="label.posts.table.no-memberships"
            notPickable
          />
        )}
      </Menu>
    </Box>
  );
}

function PostsTableFiltering() {
  const q = useSelector(PostsSelector.selectQ);
  const targetType = useSelector(PostsSelector.selectTargetType);
  const activityKeys = useSelector(PostsSelector.selectActivityKeys);
  const pageSize = useSelector(PostsSelector.selectPageSize);
  const isFilteringByActivity = useSelector(
    PostsSelector.selectIsFilteringByActivity,
  );
  const isFilteringByMembershipType = useSelector(
    PostsSelector.selectIsFilteringByMembershipType,
  );

  const { enableFiltering } = React.useContext(PostsTableContext);

  const searchInputRef = React.useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const debounce = useDebounce();

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const q = e.target.value.length ? e.target.value : undefined;
    dispatch(postsActions.setQ(q));

    debounce(() => {
      dispatch(getPostsThunk({ q }));
    });
  };

  const handleClearSearchAndFocus = () => {
    dispatch(getPostsThunk({ q: undefined, first: pageSize }));

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  const handleResetFilters = () => {
    dispatch(
      getPostsThunk({
        first: pageSize,
        q: undefined,
        targetType: undefined,
        activityKeys: undefined,
      }),
    );
  };

  const canFilter = !targetType || targetType === 'all';

  const canFilterByActivity =
    !isFilteringByMembershipType && (canFilter || targetType === 'activity');

  const canFilterByMembershipType =
    !isFilteringByActivity && (canFilter || targetType === 'membership_type');

  return (
    <React.Fragment>
      <Box width={500} maxWidth="100%" flex flexDirection="column" gap={8}>
        <SearchInput
          value={q ?? ''}
          ref={searchInputRef}
          onChange={handleSearchChange}
          leadingElements={[
            {
              type: 'icon',
              name: 'magnifying-glass',
            },
          ]}
          trailingElements={[
            {
              type: 'button',
              icon: 'x-mark',
              onClick: handleClearSearchAndFocus,
              hidden: !q?.length,
              size: 'sm',
            },
          ]}
          placeholderTx="placeholder.search"
        />

        {enableFiltering && (
          <Box gap={8} flex flexDirection="column">
            <Box flex gap={8} width="fit-content">
              <FilterByTargetType />
              {canFilterByActivity && <FilterByActivity />}
              {canFilterByMembershipType && <FilterByMembershipType />}
            </Box>

            {(!!activityKeys?.length || targetType) && (
              <Box width="fit-content">
                <Link
                  small
                  onClick={handleResetFilters}
                  tx="button.reset-filters"
                  variant="tertiary"
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

function PostsTableContent() {
  useInitPostsTable();

  const ids = useSelector(PostsSelector.selectIds);
  const isLoading = useSelector(PostsSelector.selectIsLoading);
  const orderBy = useSelector(PostsSelector.selectOrderBy);
  const sortOrder = useSelector(PostsSelector.selectSortOrder);
  const pageSize = useSelector(PostsSelector.selectPageSize);
  const totalCount = useSelector(PostsSelector.selectTotalCount);

  const sortItems = useSortPostsTable();

  return (
    <React.Fragment>
      <PostsTableFiltering />

      {totalCount !== 0 || isLoading ? (
        <React.Fragment>
          <Table>
            <TableHeader
              onSort={sortItems}
              orderBy={orderBy}
              sortableColumns={Object.values(postSortableKeys)}
              sortOrder={sortOrder}
            >
              <TableRow>
                <TableHead
                  tx="label.posts.table.type"
                  name={postSortableKeys.targetType}
                />
                <TableHead tx="label.posts.table.name" />
                <TableHead
                  tx="label.posts.table.message"
                  name={postSortableKeys.message}
                />
                <TableHead
                  tx="label.posts.table.author"
                  name={postSortableKeys.authorName}
                />
                <TableHead
                  tx="label.posts.table.comment-count"
                  name={postSortableKeys.commentCount}
                />
                <TableHead
                  tx="label.posts.table.reactions"
                  name={postSortableKeys.reactionCount}
                />
                <TableHead
                  tx="label.posts.table.published-at"
                  name={postSortableKeys.createdAt}
                />

                <TableHead fixedRight />
              </TableRow>
            </TableHeader>

            <TableBody>
              {isLoading ? (
                <TablePlaceholderRows
                  rowCount={pageSize}
                  layout={Object.values(TABLE_COLUMN_WIDTHS)}
                />
              ) : (
                ids.map(renderPostTableRow)
              )}
            </TableBody>
          </Table>

          <PostsTablePagination />
        </React.Fragment>
      ) : (
        <EmptyState
          titleTx="label.posts.empty-state.title"
          buttonTx="label.posts.empty-state.button"
        />
      )}
    </React.Fragment>
  );
}

interface PostsTableProviderProps {
  activityKeys?: string[];
  enableFiltering?: boolean;
}

export function PostsTable(props: PostsTableProviderProps) {
  return (
    <PostsTableContext.Provider
      value={{
        enableFiltering: props.enableFiltering,
        activityKeys: props.activityKeys,
      }}
    >
      <PostsTableContent />
    </PostsTableContext.Provider>
  );
}
