import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store.types';
import {
  membershipApplicationsAdapter,
  membershipInvitesAdapater,
  membershipMembersAdapter,
  membershipPurchaseListItemsAdapter,
  membershipTypeListItemsAdapter,
} from './memberships.adapter';

export namespace CreateMembershipTypeSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.createMembershipType.status;

  export const selectData = (state: RootState) =>
    state.memberships.createMembershipType.data;
}

export namespace MembershipTypeListItemsSelector {
  const membershipTypeListItemsSelectors =
    membershipTypeListItemsAdapter.getSelectors(
      (state: RootState) => state.memberships.membershipsTypeListItems.data,
    );

  export const selectAll = membershipTypeListItemsSelectors.selectAll;

  export const selectCount = membershipTypeListItemsSelectors.selectTotal;

  export const selectEntities = membershipTypeListItemsSelectors.selectEntities;

  export const selectStatus = (state: RootState) =>
    state.memberships.membershipsTypeListItems.status;

  export const selectIsLoading = createSelector(
    selectStatus,
    (status) => status === 'pending',
  );
}

export namespace MembershipTypeSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.membershipType.status;

  export const selectError = (state: RootState) =>
    state.memberships.membershipType.error;

  export const selectMembershipTypeKey = (state: RootState) =>
    state.memberships.membershipType.data?.membershipType.membershipTypeKey ??
    null;

  export const selectIsNull = (state: RootState) =>
    !state.memberships.membershipType.data?.membershipType;

  export const selectIsIntegrated = (state: RootState) =>
    state.memberships.membershipType.data?.type === 'integration';

  export const selectLogo = (state: RootState) =>
    state.memberships.membershipType.data?.membershipType.logo ?? null;

  export const selectBackgroundImage = (state: RootState) =>
    state.memberships.membershipType.data?.membershipType.backgroundImage ??
    null;

  export const selectMembershipTypeName = (state: RootState) =>
    state.memberships.membershipType.data?.membershipType.name ?? null;

  export const selectMembershipTypeDescription = (state: RootState) =>
    state.memberships.membershipType.data?.membershipType.description ?? null;

  export const selectContactDetails = (state: RootState) =>
    state.memberships.membershipType.data?.membershipType.contactDetails ??
    null;

  export const selectMembershipTypeData = (state: RootState) => {
    if (state.memberships.membershipType.data?.type === 'integration') {
      return null;
    }

    return state.memberships.membershipType.data?.membershipType ?? null;
  };

  export const selectGeneralInfo = createSelector(
    [
      selectBackgroundImage,
      selectLogo,
      selectMembershipTypeDescription,
      selectMembershipTypeName,
      selectContactDetails,
    ],
    (backgroundImage, logo, description, name, contactDetails) => {
      return { backgroundImage, contactDetails, description, logo, name };
    },
  );

  export const selectMembershipCardData = createSelector(
    [
      selectBackgroundImage,
      selectLogo,
      selectMembershipTypeName,
      selectIsIntegrated,
    ],
    (backgroundImage, logo, name, isIntegrated) => {
      return {
        backgroundImageUrl: backgroundImage?.thumbnail256.url ?? null,
        logoUrl: logo?.thumbnail64.url ?? null,
        name,
        isIntegrated,
      };
    },
  );

  export const selectPendingApplicationCount = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return null;
      }

      return membershipType.pendingApplicationCount ?? null;
    },
  );

  export const selectHasQuestions = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return false;
      }

      if (!membershipType.membershipQuestions) {
        return false;
      }

      return membershipType.membershipQuestions.length > 0;
    },
  );

  export const selectMembershipType = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return null;
      }

      return membershipType.type;
    },
  );

  export const selectCombinationType = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return null;
      }

      return membershipType.requiredMembershipTypes?.combinationType ?? null;
    },
  );

  export const selectRequiredMembershipsCount = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return 0;
      }

      return (
        membershipType.requiredMembershipTypes
          ?.membershipApplicationRequirements.length ?? 0
      );
    },
  );

  export const selectPendingInvitationsCount = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return 0;
      }

      return membershipType.pendingInvitationCount ?? 0;
    },
  );

  export const selectMembershipQuestions = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return null;
      }

      return membershipType.membershipQuestions;
    },
  );

  export const selectMembershipPeriods = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return null;
      }

      return membershipType.periods;
    },
  );

  export const selectMembershipApplicationRequirements = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return null;
      }

      return membershipType.requiredMembershipTypes
        ?.membershipApplicationRequirements;
    },
  );

  export const selectMembershipApplicationRequirementKeys = createSelector(
    selectMembershipTypeData,
    (membershipType) => {
      if (!membershipType) {
        return null;
      }

      const membershipTypeKey = membershipType.membershipTypeKey;

      if (!membershipTypeKey) {
        return null;
      }

      const membershipApplicationRequirements =
        membershipType.requiredMembershipTypes
          ?.membershipApplicationRequirements;

      if (!membershipApplicationRequirements) {
        return [membershipTypeKey];
      }

      return [
        membershipTypeKey,
        ...membershipApplicationRequirements.map(
          (requirement) => requirement.membershipTypeKey,
        ),
      ];
    },
  );
}

export namespace DynamicMembershipLinkSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.dynamicMembershipLink;

  export const selectDynamicLink = createSelector(
    selectSelf,
    (dynamicMembershipLink) => dynamicMembershipLink.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (dynamicMembershipLink) => dynamicMembershipLink.status,
  );
}

export namespace DeleteMembershipTypeSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.deleteMembershipType;

  export const selectStatus = createSelector(
    selectSelf,
    (deleteMembershipType) => deleteMembershipType.status,
  );
}

export namespace AcceptApplicationSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.acceptMembershipApplication.status;
}

export namespace RejectApplicationSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.rejectMembershipApplication.status;
}

export namespace RequestMoreInfoOnMembershipApplicationSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.requestMoreInfoOnMembershipApplication.status;
}

export namespace MembershipApplicationsSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.membershipApplications;

  const selectData = createSelector(
    selectSelf,
    (membershipApplications) => membershipApplications.data,
  );

  const membershipApplicationsSelectors =
    membershipApplicationsAdapter.getSelectors(selectData);

  export const selectAll = membershipApplicationsSelectors.selectAll;

  export const selectStatus = createSelector(
    selectSelf,
    (membershipApplications) => membershipApplications.status,
  );

  export const selectPagination = createSelector(
    selectSelf,
    (membershipApplications) => membershipApplications.pagination,
  );

  export const selectIncludeStatus = createSelector(
    selectSelf,
    (membershipApplications) => membershipApplications.includeStatus,
  );
}

export namespace MembershipApplicationSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.membershipApplication;

  export const selectStatus = createSelector(
    selectSelf,
    (membershipApplication) => membershipApplication.status,
  );

  const selectData = createSelector(
    selectSelf,
    (membershipApplication) => membershipApplication.data,
  );

  export const selectMembershipApplicationKey = createSelector(
    selectData,
    (data) => data?.membershipApplicationKey ?? null,
  );

  export const selectFullName = createSelector(
    selectData,
    (data) => data?.fullName ?? null,
  );

  export const selectEmail = createSelector(
    selectData,
    (data) => data?.email ?? null,
  );

  export const selectAnswers = createSelector(
    selectData,
    (data) => data?.answers ?? null,
  );

  export const selectPeriods = createSelector(
    selectData,
    (data) => data?.periods ?? null,
  );

  export const selectMembershipId = createSelector(
    selectData,
    (data) => data?.membershipId ?? null,
  );

  export const selectSegmentation = createSelector(
    selectData,
    (data) => data?.segmentation ?? null,
  );

  export const selectSemester = createSelector(
    selectData,
    (data) => data?.semester ?? null,
  );

  export const selectApplicationStatus = createSelector(
    selectData,
    (data) => data?.status ?? null,
  );

  export const selectStatusUpdates = createSelector(
    selectData,
    (data) => data?.statusUpdates ?? null,
  );
}

export namespace DeleteCheckboxQuestionSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.deleteCheckboxQuestion.status;
}

export namespace CreateCheckboxSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.createCheckboxQuestion.status;
}

export namespace UpdateCheckboxSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.updateCheckboxQuestion.status;
}

export namespace CreateFreetextSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.createFreetextQuestion.status;
}

export namespace UpdateFreetextSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.updateFreetextQuestion.status;
}

export namespace DeleteFreetextQuestionSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.deleteFreetextQuestion.status;
}

export namespace CreateMultichoiceSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.createMultichoiceQuestion.status;
}

export namespace UpdateMultichoiceSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.updateMultichoiceQuestion.status;
}

export namespace DeleteMultichoiceQuestionSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.deleteMultichoiceQuestion.status;
}

export namespace UpdateGeneralInfoSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.updateGeneralInfo.status;
}

export namespace CheckIntegrationSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.checkIntegration.status;
}

export namespace CreateIntegratedMembershipTypeSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.createIntegratedMembershipType;

  export const selectStatus = createSelector(
    selectSelf,
    (createIntegratedMembershipType) => createIntegratedMembershipType.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (createIntegratedMembershipType) => createIntegratedMembershipType.data,
  );
}

export namespace MembershipInvitesSelector {
  const selectSelf = (state: RootState) => state.memberships.membershipInvites;

  export const selectStatus = createSelector(
    selectSelf,
    (membershipInvites) => membershipInvites.status,
  );

  export const selectPagination = createSelector(
    selectSelf,
    (membershipInvites) => membershipInvites.pagination,
  );

  const selectData = createSelector(
    selectSelf,
    (membershipInvites) => membershipInvites.data,
  );

  const membershipInvitesSelectors =
    membershipInvitesAdapater.getSelectors(selectData);

  export const selectAll = membershipInvitesSelectors.selectAll;

  export const selectIncludeStatus = createSelector(
    selectSelf,
    (membershipInvites) => membershipInvites.includeStatus,
  );
}

export namespace CreateMembershipInvitationsSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.createMembershipInvitations;

  export const selectStatus = createSelector(
    selectSelf,
    (createMembershipInvitations) => createMembershipInvitations.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (createMembershipInvitations) => createMembershipInvitations.data,
  );

  export const selectSuccessfulInvitesCount = createSelector(
    selectData,
    (createMembershipInvitations) => {
      if (!createMembershipInvitations?.invites) {
        return 0;
      }

      return createMembershipInvitations.invites.filter(
        (invite) => invite.result === 'invited',
      ).length;
    },
  );
}

export namespace DeleteMembershipInvitationSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.withdrawInvite.status;
}

export namespace MembershipMembersSelector {
  const selectSelf = (state: RootState) => state.memberships.membershipMembers;

  export const selectStatus = createSelector(
    selectSelf,
    (membershipMembers) => membershipMembers.status,
  );

  export const selectError = createSelector(
    selectSelf,
    (membershipMembers) => membershipMembers.error,
  );

  export const selectPagination = createSelector(
    selectSelf,
    (membershipMembers) => membershipMembers.pagination,
  );

  const selectData = createSelector(
    selectSelf,
    (membershipMembers) => membershipMembers.data,
  );

  const membershipMembersSelectors =
    membershipMembersAdapter.getSelectors(selectData);

  export const selectAll = membershipMembersSelectors.selectAll;

  export const selectSearch = createSelector(
    selectSelf,
    (membershipMembers) => membershipMembers.search,
  );
}

export namespace MembershipMemberSelector {
  const selectSelf = (state: RootState) => state.memberships.membershipMember;

  export const selectStatus = createSelector(
    selectSelf,
    (membershipMember) => membershipMember.status,
  );

  const selectData = createSelector(
    selectSelf,
    (membershipMember) => membershipMember.data,
  );

  export const selectUserKey = createSelector(
    selectData,
    (membershipMember) => membershipMember?.userKey,
  );

  export const selectFullName = createSelector(
    selectData,
    (membershipMember) => membershipMember?.fullName,
  );

  export const selectEmail = createSelector(
    selectData,
    (membershipMember) => membershipMember?.email,
  );

  export const selectAnswers = createSelector(
    selectData,
    (membershipMember) => membershipMember?.answers,
  );

  export const selectSegmentation = createSelector(
    selectData,
    (membershipMember) => membershipMember?.segmentation,
  );

  export const selectSemester = createSelector(
    selectData,
    (membershipMember) => membershipMember?.semester,
  );

  export const selectPeriods = createSelector(
    selectData,
    (membershipMember) => membershipMember?.periods,
  );

  export const selectMembershipId = createSelector(
    selectData,
    (membershipMember) => membershipMember?.membershipId,
  );
}

export namespace UpdateCombinationTypeSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.updateCombinationType.status;
}

export namespace DeleteRequirementSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.deleteRequirement.status;
}

export namespace CreateMembershipApplicationRequirementsSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.createMembershipApplicationRequirements.status;
}

export namespace MembershipMemberListItemStatsSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.membershipMemberListItemStats;

  export const selectData = createSelector(
    selectSelf,
    (membershipMemberListItemStats) => membershipMemberListItemStats.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (membershipMemberListItemStats) => membershipMemberListItemStats.status,
  );
}

export namespace MembershipTypeStatsSelector {
  const selectMembershipTypeStats = (state: RootState) =>
    state.memberships.membershipTypeStats.data;

  export const selectActiveMembersCount = createSelector(
    selectMembershipTypeStats,
    (stats) => stats?.activeMemberCount ?? 0,
  );

  export const selectTotalMembersCount = createSelector(
    selectMembershipTypeStats,
    (stats) => stats?.totalMemberCount ?? 0,
  );

  export const selectTotalInactiveMembersCount = createSelector(
    selectMembershipTypeStats,
    (stats) => {
      if (!stats) {
        return 0;
      }

      return stats.totalMemberCount - stats.activeMemberCount;
    },
  );

  export const selectNewMembersMonthly = createSelector(
    [selectMembershipTypeStats],
    (stats) => {
      if (!stats?.newMembersMonthly) {
        return [];
      }

      return [...stats.newMembersMonthly].sort((a, b) => a.month - b.month);
    },
  );
}

export namespace DeletePeriodSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.deletePeriod.status;
}

export namespace CreatePeriodSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.createPeriod.status;
}

export namespace UpdatePeriodSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.updatePeriod.status;
}

export namespace MembershipPurchaseListItemsSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.membershipPurchaseListItems;

  export const selectStatus = createSelector(
    selectSelf,
    (membershipPurchaseListItems) => membershipPurchaseListItems.status,
  );

  export const selectPagination = createSelector(
    selectSelf,
    (membershipPurchaseListItems) => membershipPurchaseListItems.pagination,
  );

  const selectData = createSelector(
    selectSelf,
    (membershipPurchaseListItems) => membershipPurchaseListItems.data,
  );

  const membershipPurchaseListItemsSelector =
    membershipPurchaseListItemsAdapter.getSelectors(selectData);

  export const selectAll = membershipPurchaseListItemsSelector.selectAll;
}

export namespace RefundMembershipPurchaseSelector {
  export const selectStatus = (state: RootState) =>
    state.memberships.refundMembershipPurchase.status;
}

export namespace SuggestedMembershipsSelector {
  const selectSelf = (state: RootState) =>
    state.memberships.getSuggestedMemberships;

  export const selectStatus = createSelector(
    selectSelf,
    (suggestedMemberships) => suggestedMemberships.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (suggestedMemberships) => suggestedMemberships.data,
  );
}
