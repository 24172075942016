import {
  BreadcrumbListItem,
  BreadcrumbsToolbar,
  ContentContainer,
  InnerPageContainer,
  LoadingContainer,
  PageContainer,
  getFileFromUrl,
  useFetch,
  useNavigateWithQuery,
} from '@orbiapp/components';
import React from 'react';
import { useParams } from 'react-router-dom';

import { CreateOfferForm } from '../../../../models';
import { Logger } from '../../../../services';
import {
  CountriesSelector,
  LocationsSelector,
  OfferCategoriesSelector,
  OfferSelector,
  getCountriesThunk,
  getLocationsThunk,
  getOfferCategoriesThunk,
  getOfferThunk,
  offersActions,
  useDispatch,
  useSelector,
} from '../../../../store';
import { isAnyPending } from '../../../../utils';
import { NotFound } from '../../../shared';
import { OfferForm } from './view-offer-form';

const DUPLICATE_OFFER_BREADCRUMBS: BreadcrumbListItem[] = [
  {
    to: '/offers',
    tx: 'label.breadcrumbs.offers.offers',
  },
  {
    to: '/offers/create-offer',
    tx: 'label.breadcrumbs.offers.create-offer',
  },
];

function useDefaultValues() {
  const offer = useSelector(OfferSelector.selectData);

  const navigate = useNavigateWithQuery();

  const getDefaultValues =
    React.useCallback(async (): Promise<CreateOfferForm | null> => {
      if (!offer) {
        return null;
      }

      const coverImage = await getFileFromUrl(offer.coverImage.original.url);
      if (!coverImage) {
        Logger.log('Offer cover image not found', { offerKey: offer.offerKey });
        navigate('/not-found');
        return null;
      }

      let consumeWaitMinutes: number | null = null;
      let files: CreateOfferForm['files'] = null;
      let link: string | null = null;
      let code: string | null = null;
      let isConsumable = false;

      if (offer.type === 'online') {
        link = offer.link;

        if (offer.code) {
          if (offer.code.type === 'many') {
            files = offer.code.files;
          } else {
            code = offer.code.code;
          }
        }
      }

      if (offer.type === 'consumable') {
        consumeWaitMinutes = offer.consumeWaitMinutes;
        isConsumable = true;
      }

      const updateOfferForm: CreateOfferForm = {
        consumeWaitMinutes,
        files,
        isConsumable,
        link,
        code,
        contactEmail: offer.contactEmail,
        contactName: offer.contactName,
        coverImage,
        description: offer.description,
        endDate: offer.endDate,
        locations: offer.locations,
        offerCategoryKey: offer.offerCategory.offerCategoryKey,
        requireStudentVerification: offer.requireStudentVerification,
        requiredMembershipTypes: offer.requiredMembershipTypes,
        startDate: offer.startDate,
        title: offer.title,
        countryKeys: offer.countryKeys,
      };

      return updateOfferForm;
    }, [offer, navigate]);

  const defaultValues = useFetch<CreateOfferForm>(getDefaultValues);

  return defaultValues;
}

export function DuplicateOffer() {
  const { offerKey } = useParams<{ offerKey: string }>();

  const dispatch = useDispatch();

  const offerCategoriesStatus = useSelector(
    OfferCategoriesSelector.selectStatus,
  );
  const locationsStatus = useSelector(LocationsSelector.selectStatus);
  const countriesStatus = useSelector(CountriesSelector.selectStatus);

  const offerError = useSelector(OfferSelector.selectError);

  const defaultValues = useDefaultValues();

  React.useEffect(() => {
    if (!offerKey) return;

    dispatch(getOfferCategoriesThunk());
    dispatch(getLocationsThunk());
    dispatch(getOfferThunk(offerKey));
    dispatch(getCountriesThunk());

    return () => {
      dispatch(offersActions.resetCreateOffer());
    };
  }, [dispatch, offerKey]);

  if (offerError) {
    return <NotFound continueTo="/offers" />;
  }

  if (
    !defaultValues ||
    isAnyPending(offerCategoriesStatus, locationsStatus, countriesStatus)
  ) {
    return <LoadingContainer />;
  }

  return (
    <PageContainer>
      <BreadcrumbsToolbar breadcrumbListItems={DUPLICATE_OFFER_BREADCRUMBS} />

      <InnerPageContainer>
        <ContentContainer>
          <OfferForm defaultValues={defaultValues} />
        </ContentContainer>
      </InnerPageContainer>
    </PageContainer>
  );
}
