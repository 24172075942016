import React from 'react';
import { To } from 'react-router-dom';

import { Avatar, AvatarProps } from '../avatar';
import { Box, BoxProps } from '../box';
import { Icon, IconName } from '../icon';
import { OnDarkIconButton } from '../icon-button';
import { Text, TxProps } from '../text';
import { Styled } from './profile-menu.styled';

interface ProfileMenuHeaderProps {
  avatarVariant?: AvatarProps['variant'];
  email: string;
  fullName: string;
  onEditProfile?: () => void;
  profilePictureSrc: string;
}

export function ProfileMenuHeader(props: ProfileMenuHeaderProps) {
  const { avatarVariant, email, fullName, onEditProfile, profilePictureSrc } =
    props;

  return (
    <Styled.ProfileMenuHeader
      height={112}
      px={16}
      py={24}
      shape={{ tl: 8, tr: 8 }}
      flex
      flexAlign="center"
    >
      <Box width="100%" flexAlign="center" flex gap={16}>
        <Avatar
          fallbackLetter={fullName[0]}
          height={64}
          minHeight={64}
          minWidth={64}
          src={profilePictureSrc}
          variant={avatarVariant}
          width={64}
        />

        <Box overflow="hidden">
          <Text
            color="profileMenuHeaderTitle"
            overflow="hidden"
            text={fullName}
            textOverflow="ellipsis"
            variant="bodyMdBold"
            whiteSpace="nowrap"
          />

          <Text
            color="profileMenuHeaderSubtitle"
            overflow="hidden"
            text={email}
            textOverflow="ellipsis"
            variant="bodySm"
            whiteSpace="nowrap"
          />
        </Box>

        {onEditProfile && (
          <OnDarkIconButton
            icon="pencil-square-outline"
            ml="auto"
            onClick={onEditProfile}
          />
        )}
      </Box>
    </Styled.ProfileMenuHeader>
  );
}

export function ProfileMenuList(props: React.PropsWithChildren) {
  const { children } = props;

  return <Box role="list">{children}</Box>;
}

interface ProfileMenuListItemProps extends React.PropsWithChildren<TxProps> {
  href?: string;
  icon?: IconName;
  onClick?: () => void;
  to?: To;
}

export function ProfileMenuListItem(props: ProfileMenuListItemProps) {
  const { href, icon, to, onClick, tx, text, txArgs, children } = props;

  const profileMenuListItemContent = children ?? (
    <React.Fragment>
      {icon && <Icon color="profileMenuListItemIcon" name={icon} />}
      <Text
        color="profileMenuListItemLabel"
        text={text}
        tx={tx}
        txArgs={txArgs}
        variant="bodySm"
      />
    </React.Fragment>
  );

  if (href) {
    return (
      <Styled.ProfileMenuListItem
        cursor="pointer"
        onClick={onClick}
        role="listitem"
      >
        <Styled.ProfileMenuListItemLink plain href={href}>
          {profileMenuListItemContent}
        </Styled.ProfileMenuListItemLink>
      </Styled.ProfileMenuListItem>
    );
  }

  if (to) {
    return (
      <Styled.ProfileMenuListItem
        cursor="pointer"
        onClick={onClick}
        role="listitem"
      >
        <Styled.ProfileMenuListItemLink plain to={to}>
          {profileMenuListItemContent}
        </Styled.ProfileMenuListItemLink>
      </Styled.ProfileMenuListItem>
    );
  }

  return (
    <Styled.ProfileMenuListItem
      cursor="pointer"
      flex
      flexAlign="center"
      gap={8}
      onClick={onClick}
      p={8}
      role="listitem"
    >
      {profileMenuListItemContent}
    </Styled.ProfileMenuListItem>
  );
}

function _ProfileMenu(
  props: BoxProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const { children, ...rest } = props;

  return (
    <Styled.ProfileMenu mt={8} ref={ref} r={8} width={288} {...rest}>
      {children}
    </Styled.ProfileMenu>
  );
}

export const ProfileMenu = React.forwardRef(_ProfileMenu);
