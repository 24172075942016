import styled, { css } from 'styled-components';

import { LinkWithQuery } from '../link';

const LastBreadCrumb = styled(LinkWithQuery)`
  ${(props) => {
    const { theme } = props;

    return css`
      align-items: center;
      display: flex;
      color: ${theme.colors.breadcrumbLabel};
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    `;
  }}
`;

const BreadCrumb = styled(LinkWithQuery)`
  ${(props) => {
    const { theme } = props;

    return css`
      align-items: center;
      display: flex;
      gap: 14px;
      text-decoration: none;
      color: ${theme.colors.breadcrumbLabelActive};

      &:hover {
        text-decoration: underline;
      }
    `;
  }}
`;

export const Styled = { BreadCrumb, LastBreadCrumb };
