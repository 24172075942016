import { ScrollProvider, SidebarContainer } from '@orbiapp/components';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import {
  MainSidebar,
  PayoutsSnackbarGate,
  SignedInToolbar,
} from '../../components';
import { AccountSelector, AuthStateSelector, useSelector } from '../../store';

function _DashboardOutlet() {
  return (
    <ScrollProvider>
      <PayoutsSnackbarGate />

      <SignedInToolbar />

      <SidebarContainer>
        <MainSidebar />

        <Outlet />
      </SidebarContainer>
    </ScrollProvider>
  );
}
const DashboardOutlet = React.memo(_DashboardOutlet);
DashboardOutlet.displayName = 'DashboardOutlet';

export function SignedInOutlet() {
  const accountVerified = useSelector(AuthStateSelector.selectAccountVerified);
  const hasCorrectRole = useSelector(AccountSelector.selectHasCorrectRole);
  const onlyHasStudentWorkspace = useSelector(
    AccountSelector.selectOnlyHasStudentWorkspace,
  );
  const onlyHasCompanyWorkspace = useSelector(
    AccountSelector.selectOnlyHasCompanyWorkspace,
  );
  const lastUsedCompanyWorkspaceKey = useSelector(
    AccountSelector.selectLastUsedCompanyWorkspaceKey,
  );

  if (onlyHasStudentWorkspace) {
    return <Navigate to="/access-denied-student" />;
  }

  if (onlyHasCompanyWorkspace) {
    return (
      <Navigate to={`/workspace/company/${lastUsedCompanyWorkspaceKey}`} />
    );
  }

  if (!hasCorrectRole) {
    return <Navigate to="/access-denied" />;
  }

  if (!accountVerified) {
    return <Navigate to="/email-not-verified" />;
  }

  return <DashboardOutlet />;
}
