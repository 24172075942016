import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Button,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledTextarea,
  EmptyState,
  InnerContentContainer,
  InnerPageContainer,
  Status,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePlaceholderRows,
  TableRow,
  Text,
  flattenFieldErrorsObject,
  formatDate,
  useFeatureFlag,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import {
  ANSWER_MAX_LENGTH,
  Question,
  QuestionForm,
  QuestionValidation,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  AccountSelector,
  ActivityDataSelector,
  ActivityQuestionsSelector,
  ActivitySelector,
  UpdateQuestionSelector,
  getQuestionsByActivityThunk,
  setQuestionToggleIsHiddenThunk,
  updateQuestionThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { EventActions } from '../components';
import { QuestionsContextType } from './questions.types';

const TABLE_COLUMN_WIDTHS = {
  status: 150,
  createdAt: 150,
  email: 150,
  questionText: '18ch',
  answeredAt: 150,
  actions: 50,
};

const QuestionsContext = React.createContext<QuestionsContextType>({
  selectedQuestion: null,
  setSelectedQuestion: () => {},
});

function QuestionsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [selectedQuestion, setSelectedQuestion] =
    React.useState<Question | null>(null);

  return (
    <QuestionsContext.Provider
      value={{ selectedQuestion, setSelectedQuestion }}
    >
      {children}
    </QuestionsContext.Provider>
  );
}

function getQuestionStatusTx(question: Question): TxString {
  if (question.isHidden) {
    return 'label.questions.questions-table-body.hidden';
  }

  if (question.answer) {
    return 'label.questions.questions-table-body.answered';
  }

  return 'label.questions.questions-table-body.open';
}

function QuestionsTable() {
  const { selectedQuestion, setSelectedQuestion } =
    React.useContext(QuestionsContext);
  const questions = useSelector(ActivityQuestionsSelector.selectAll);
  const questionsStatus = useSelector(ActivityQuestionsSelector.selectStatus);

  const isLoading = questionsStatus === 'pending';

  const renderQuestionTableRow = (question: Question) => {
    const openQuestionsSidebar = () => setSelectedQuestion(question);

    return (
      <TableRow
        highlight={question.questionKey === selectedQuestion?.questionKey}
        onClick={openQuestionsSidebar}
        key={question.questionKey}
      >
        <TableCell width={TABLE_COLUMN_WIDTHS.status}>
          <Status
            variant={
              question.isHidden
                ? 'info'
                : question.answer
                  ? 'success'
                  : 'warning'
            }
            tx={getQuestionStatusTx(question)}
          />
        </TableCell>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.createdAt}
          text={formatDate(question.createdAt, 'DD MMM YYYY HH:mm')}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.email}
          text={question.askedByEmail ?? ''}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.questionText}
          text={question.question}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.answeredAt}
          text={
            question.answeredAt
              ? formatDate(question.answeredAt, 'DD MMM YYYY HH:mm')
              : ''
          }
        />

        <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
      </TableRow>
    );
  };

  if (isLoading && questions.length === 0) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.questions.questions-table-header.status" />
            <TableHead tx="label.questions.questions-table-header.created-at" />
            <TableHead tx="label.questions.questions-table-header.email" />
            <TableHead tx="label.questions.questions-table-header.question-text" />
            <TableHead tx="label.questions.questions-table-header.answered-at" />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  if (questions.length === 0) {
    return <EmptyState titleTx="placeholder.table.questions" />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead tx="label.questions.questions-table-header.status" />
          <TableHead tx="label.questions.questions-table-header.created-at" />
          <TableHead tx="label.questions.questions-table-header.email" />
          <TableHead tx="label.questions.questions-table-header.question-text" />
          <TableHead tx="label.questions.questions-table-header.answered-at" />
          <TableHead fixedRight />
        </TableRow>
      </TableHeader>

      <TableBody>{questions.map(renderQuestionTableRow)}</TableBody>
    </Table>
  );
}

function QuestionsContent() {
  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          as="h1"
          color="pageTitle"
          tx="label.view-activity.tabs.questions"
          variant="titleMd"
        />

        <EventActions />
      </Box>

      <QuestionsTable />
    </React.Fragment>
  );
}

function QuestionIsHiddenSwitch() {
  const { selectedQuestion, setSelectedQuestion } =
    React.useContext(QuestionsContext);

  const dispatch = useDispatch();

  const toggleQuestionIsHidden = async () => {
    if (!selectedQuestion) return;

    const res = await dispatch(
      setQuestionToggleIsHiddenThunk({
        activityKey: selectedQuestion.activityKey,
        questionKey: selectedQuestion.questionKey,
        isHidden: !selectedQuestion.isHidden,
      }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      setSelectedQuestion({
        ...selectedQuestion,
        isHidden: !selectedQuestion.isHidden,
      });
    }
  };

  return (
    <Switch
      checked={selectedQuestion?.isHidden}
      onClick={toggleQuestionIsHidden}
      tx="label.questions.toggle"
    />
  );
}

function UpdateQuestionFormContent() {
  const { selectedQuestion } = React.useContext(QuestionsContext);

  const dispatch = useDispatch();

  const fullName = useSelector(AccountSelector.selectFullName);

  const updateQuestionStatus = useSelector(UpdateQuestionSelector.selectStatus);

  const isLoading = updateQuestionStatus === 'pending';

  const formMethods = useForm<QuestionForm>({
    resolver: joiResolver(QuestionValidation),
    defaultValues: {
      answer: selectedQuestion?.answer ?? '',
    },
  });

  const updateQuestion = formMethods.handleSubmit(
    (data) => {
      if (!selectedQuestion || !fullName) return;

      dispatch(
        updateQuestionThunk({
          activityKey: selectedQuestion.activityKey,
          answer: data.answer,
          answeredByFullName: fullName,
          questionKey: selectedQuestion.questionKey,
        }),
      );
    },
    (err) => {
      Logger.warning('updateEventQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      answer: selectedQuestion?.answer ?? '',
    });
  }, [formMethods, selectedQuestion?.questionKey, selectedQuestion?.answer]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <Text variant="bodyLgBold" text={selectedQuestion?.question} />

        <ControlledTextarea
          disabled={isLoading}
          labelTx="label.questions.answer"
          maxLength={ANSWER_MAX_LENGTH}
          name="answer"
        />

        <QuestionIsHiddenSwitch />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={updateQuestion}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}

function QuestionsSidebar() {
  const { selectedQuestion, setSelectedQuestion } =
    React.useContext(QuestionsContext);

  const isOpen = selectedQuestion !== null;
  const closeQuestionsSidebar = () => setSelectedQuestion(null);

  return (
    <ContentSidebar isOpen={isOpen} onClose={closeQuestionsSidebar} width={400}>
      <UpdateQuestionFormContent />
    </ContentSidebar>
  );
}

export function ViewActivityQuestions() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const dispatch = useDispatch();

  const showQuestions = useFeatureFlag('student_dashboard_show_questions');

  React.useEffect(() => {
    if (!activityKey) return;

    dispatch(getQuestionsByActivityThunk(activityKey));
  }, [activityKey, dispatch]);

  if (!isActivityOwner || !showQuestions) {
    return <Navigate to={`/activities/${activityKey}/description`} />;
  }

  return (
    <QuestionsProvider>
      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <QuestionsContent />
          </InnerContentContainer>
        </ContentContainer>

        <QuestionsSidebar />
      </InnerPageContainer>
    </QuestionsProvider>
  );
}
