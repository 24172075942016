import { Box, Link } from '@orbiapp/components';

import { PostSelector, useSelector } from '../../store';
import { PostTargetChip } from '../post-target-chip';

export function PostSidebarHeader() {
  const target = useSelector(PostSelector.selectPostTarget);

  if (!target) {
    return null;
  }

  return (
    <Box flexAlign="center" gap={8} flex>
      <PostTargetChip {...target} />

      {target.type === 'activity' && (
        <Box overflow="hidden">
          <Link
            to={`/activities/${target.activity.activityKey}/posts`}
            text={target.activity.title}
            variant="secondary"
          />
        </Box>
      )}

      {target.type === 'membership_type' && (
        <Box overflow="hidden">
          <Link
            to={`/memberships/${target.membershipType.membershipTypeKey}`}
            text={target.membershipType.name}
            variant="secondary"
          />
        </Box>
      )}
    </Box>
  );
}
