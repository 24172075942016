import {
  BrowserUrl,
  getLastUsedWorkspace,
  getWorkspacesOfType,
} from '@orbiapp/components';

import {
  createAccountByInvitationThunk,
  getAccountThunk,
  switchWorkspaceThunk,
  updateAccountByInvitationThunk,
  updateAccountThunk,
  updateWorkspaceThunk,
} from './account.actions';
import { AccountBuilder } from './account.types';

const getAccountBuild = (builder: AccountBuilder) => {
  return builder
    .addCase(getAccountThunk.fulfilled, (state, action) => {
      state.account.status = 'completed';
      state.account.data = action.payload;

      let workspaceKey = BrowserUrl.getWorkspaceKey();

      if (workspaceKey) {
        workspaceKey =
          getWorkspacesOfType(action.payload.workspaces, 'department').find(
            (workspace) => workspace.departmentKey === workspaceKey,
          )?.departmentKey ?? null;
      }

      if (!workspaceKey) {
        workspaceKey =
          getLastUsedWorkspace(
            getWorkspacesOfType(action.payload.workspaces, 'department'),
            'department',
          )?.departmentKey ?? null;
      }

      if (workspaceKey) {
        BrowserUrl.setWorkspaceKey(workspaceKey);
      }
    })
    .addCase(getAccountThunk.pending, (state) => {
      state.account.status = 'pending';
      state.account.error = null;
    })
    .addCase(getAccountThunk.rejected, (state, action) => {
      state.account.status = 'idle';
      state.account.error = action.error;
    });
};

const createAccountByInvitationBuild = (builder: AccountBuilder) => {
  return builder
    .addCase(createAccountByInvitationThunk.fulfilled, (state, action) => {
      state.createAccountByInvitation.status = 'completed';
      state.createAccountByInvitation.data = action.payload;
    })
    .addCase(createAccountByInvitationThunk.pending, (state) => {
      state.createAccountByInvitation.status = 'pending';
      state.createAccountByInvitation.error = null;
    })
    .addCase(createAccountByInvitationThunk.rejected, (state, action) => {
      state.createAccountByInvitation.status = 'idle';
      state.createAccountByInvitation.error = action.error;
    });
};

const updateAccountByInvitationBuild = (builder: AccountBuilder) => {
  return builder
    .addCase(updateAccountByInvitationThunk.fulfilled, (state) => {
      state.updateAccountByInvitation.status = 'completed';
    })
    .addCase(updateAccountByInvitationThunk.pending, (state) => {
      state.updateAccountByInvitation.status = 'pending';
      state.updateAccountByInvitation.error = null;
    })
    .addCase(updateAccountByInvitationThunk.rejected, (state, action) => {
      state.updateAccountByInvitation.status = 'idle';
      state.updateAccountByInvitation.error = action.error;
    });
};

const updateAccountBuild = (builder: AccountBuilder) => {
  return builder
    .addCase(updateAccountThunk.fulfilled, (state, action) => {
      state.updateAccount.status = 'idle';

      if (state.account.data) {
        state.account.data.firstName =
          action.meta.arg.firstName ?? state.account.data.firstName;

        state.account.data.lastName =
          action.meta.arg.lastName ?? state.account.data.lastName;

        state.account.data.language =
          action.meta.arg.language ?? state.account.data.language;
      }
    })
    .addCase(updateAccountThunk.pending, (state) => {
      state.updateAccount.status = 'pending';
      state.updateAccount.error = null;
    })
    .addCase(updateAccountThunk.rejected, (state, action) => {
      state.updateAccount.status = 'idle';
      state.updateAccount.error = action.error;
    });
};

const updateWorkspaceBuild = (builder: AccountBuilder) => {
  return builder
    .addCase(updateWorkspaceThunk.fulfilled, (state) => {
      state.updateWorkspace.status = 'idle';
    })
    .addCase(updateWorkspaceThunk.pending, (state) => {
      state.updateWorkspace.status = 'pending';
      state.updateWorkspace.error = null;
    })
    .addCase(updateWorkspaceThunk.rejected, (state, action) => {
      state.updateWorkspace.status = 'idle';
      state.updateWorkspace.error = action.error;
    });
};

const switchWorkspaceBuild = (builder: AccountBuilder) => {
  return builder
    .addCase(switchWorkspaceThunk.fulfilled, (state) => {
      state.switchWorkspace.status = 'idle';
    })
    .addCase(switchWorkspaceThunk.pending, (state) => {
      state.switchWorkspace.status = 'pending';
      state.switchWorkspace.error = null;
    })
    .addCase(switchWorkspaceThunk.rejected, (state, action) => {
      state.switchWorkspace.status = 'idle';
      state.switchWorkspace.error = action.error;
    });
};

export const extraReducers = (builder: AccountBuilder) => {
  getAccountBuild(builder);

  createAccountByInvitationBuild(builder);
  updateAccountByInvitationBuild(builder);

  updateAccountBuild(builder);

  updateWorkspaceBuild(builder);
  switchWorkspaceBuild(builder);
};
