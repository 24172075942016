import {
  LoadingContainer,
  getFileFromUrl,
  useFeatureFlag,
  useFetch,
} from '@orbiapp/components';
import React from 'react';
import { useParams } from 'react-router-dom';

import { ActivityForm } from '../../../../models';
import {
  ActivityDataSelector,
  ActivitySelector,
  getActivityThunk,
  useDispatch,
  useSelector,
} from '../../../../store';
import { getCoHostRequests } from '../../../../utils';
import { NotFound } from '../../../shared';
import { CreateActivity } from '../create-activity/create-activity';

function useDefaultValues() {
  const activity = useSelector(ActivityDataSelector.selectData);
  const enableQuestions = useFeatureFlag('student_dashboard_show_questions');

  const getDefaultValues =
    React.useCallback(async (): Promise<ActivityForm | null> => {
      if (!activity) {
        return null;
      }

      const coverImage = await getFileFromUrl(activity.coverImage.original.url);

      const cohosts = !!activity?.cohosts?.length
        ? getCoHostRequests(activity.cohosts, 'not-sent')
        : null;

      const activityForm: ActivityForm = {
        description: {
          categories: activity.categories,
          contactDetails: activity.contactDetails,
          description: activity.description,
          endDate: activity.endDate,
          links: activity.links,
          location: activity.location,
          startDate: activity.startDate,
          tags: activity.tags,
          title: activity.title,
        },
        media: { coverImage },
        participants: { segmentation: activity.segmentation },
        tickets: {
          ticketTypes: activity.ticketTypes,
          showAttendance: activity.showAttendance,
        },
        requestMoreInfo: { requestedInfo: activity.requestedInfo },
        coHosting: { cohosts },
        addons: {
          questionsActivated: enableQuestions
            ? activity.questionsActivated
            : false,
        },
      };

      if (activity.categories) {
        activityForm.description.categories = activity.categories;
      }

      return activityForm;
    }, [activity, enableQuestions]);

  const defaultValues = useFetch(getDefaultValues);

  return defaultValues;
}

export function DuplicateActivity() {
  const { activityKey } = useParams<{ activityKey: string }>();

  const activityStatus = useSelector(ActivitySelector.selectStatus);
  const activityError = useSelector(ActivitySelector.selectError);

  const dispatch = useDispatch();

  const defaultValues = useDefaultValues();

  React.useEffect(() => {
    if (!activityKey) return;

    dispatch(getActivityThunk(activityKey));
  }, [activityKey, dispatch]);

  if (activityError) {
    return <NotFound continueTo="/activities" />;
  }

  if (activityStatus === 'pending' || !activityKey || !defaultValues) {
    return <LoadingContainer />;
  }

  return <CreateActivity defaultValues={defaultValues} mode="duplicate" />;
}
