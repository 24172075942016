import { StatsigEnv } from '@orbiapp/components';

const env_vars = {
  API_URL: import.meta.env.VITE_APP_API_URL,

  COMPANY_DASHBOARD_URL: import.meta.env.VITE_APP_COMPANY_DASHBOARD_URL,

  FIREBASE_API_KEY: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  FIREBASE_APP_ID: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
  FIREBASE_PROJECT_ID: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,

  ENV: 'production',

  STATSIG_SDK_KEY: import.meta.env.VITE_APP_STATSIG_SDK_KEY,
};

if (env_vars.FIREBASE_PROJECT_ID === 'orbi-testing') {
  env_vars.ENV = 'testing';
}

if (!import.meta.env.PROD) {
  Object.keys(env_vars).forEach((key) => {
    if (env_vars[key as keyof typeof env_vars] === undefined) {
      throw new Error(
        `Error. Value of ${key} must be set in order for application to run properly.`,
      );
    }
  });
}

export const firebaseConfig = {
  apiKey: env_vars.FIREBASE_API_KEY,
  authDomain: env_vars.FIREBASE_AUTH_DOMAIN,
  projectId: env_vars.FIREBASE_PROJECT_ID,
  appId: env_vars.FIREBASE_APP_ID,
  measurementId: env_vars.FIREBASE_MEASUREMENT_ID,
};

export const ENV = env_vars.ENV as 'testing' | 'production';

export const AUTH_URL = env_vars.API_URL + '/auth';
export const API_URL = env_vars.API_URL + '/student-dashboard';

export const COMPANY_DASHBOARD_URL = env_vars.COMPANY_DASHBOARD_URL;
export const ADMIN_DASHBOARD_URL =
  ENV === 'testing'
    ? 'https://orbi-testing-admin-dashboard-lltbplzj7q-ez.a.run.app'
    : 'https://orbi-production-admin-dashboard-57fnn2bggq-ez.a.run.app';

export const STATSIG_SDK_KEY = env_vars.STATSIG_SDK_KEY;
export const STATSIG_ENV: StatsigEnv =
  ENV === 'production' ? 'production' : 'staging';

export const BRANCH_IO_KEY =
  ENV === 'production'
    ? 'key_live_euhTFFUIqKf2TRFYJm5EeafdqqlzkrWq'
    : 'key_test_dAnPsCKLCRd4IJt1Tf8tpgdaDvhsnE8P';

export const USER_PILOT_SDK_KEY =
  ENV === 'production' ? 'NX-de05274d' : 'STG-NX-de05274d';

export const DEEP_LINK_BASE_URL =
  ENV === 'production'
    ? 'https://go.orbiapp.io'
    : 'https://orbiapp.test-app.link';
