import { sumOver } from '@orbiapp/components';
import { createSelector } from '@reduxjs/toolkit';

import { semesters } from '../../models';
import { DepartmentSelector } from '../department';
import { RootState } from '../store.types';
import {
  activityListItemAdapter,
  coHostingAdapter,
  draftAdapter,
  freetextListItemsAdapter,
  qrScanTokensAdapter,
  ticketsAdapter,
  ticketsHistoryAdapter,
} from './activities.adapter';

export namespace ActivityDataSelector {
  const selectSelf = (state: RootState) => state.activity.activity;

  export const selectData = createSelector(
    selectSelf,
    (activity) => activity.data,
  );

  export const selectActivityKey = createSelector(
    selectData,
    (data) => data?.activityKey ?? null,
  );

  export const selectCohosts = createSelector(
    [selectData, DepartmentSelector.selectDepartmentKey],
    (data, departmentKey) => {
      if (!data?.cohosts || !departmentKey) {
        return null;
      }

      return data.cohosts.filter(
        (cohost) => cohost.departmentKey !== departmentKey,
      );
    },
  );

  export const selectCategories = createSelector(
    selectData,
    (data) => data?.categories ?? null,
  );

  export const selectContactDetails = createSelector(
    selectData,
    (data) => data?.contactDetails ?? null,
  );

  export const selectCoverImageUrl = createSelector(
    selectData,
    (data) => data?.coverImage.thumbnail512.url,
  );

  export const selectStartDate = createSelector(
    selectData,
    (data) => data?.startDate ?? null,
  );

  export const selectEndDate = createSelector(
    selectData,
    (data) => data?.endDate ?? null,
  );

  export const selectDescription = createSelector(
    selectData,
    (data) => data?.description ?? null,
  );

  export const selectHost = createSelector(
    selectData,
    (data) => data?.host ?? null,
  );

  export const selectLocation = createSelector(
    selectData,
    (data) => data?.location ?? null,
  );

  export const selectSegmentation = createSelector(
    selectData,
    (data) => data?.segmentation ?? [],
  );

  export const selectSemesters = createSelector(
    selectSegmentation,
    (segmentation) =>
      (segmentation ?? []).map(
        (segmentationValue) => semesters[segmentationValue - 1],
      ),
  );

  export const selectTags = createSelector(
    selectData,
    (data) => data?.tags ?? [],
  );

  export const selectTicketTypes = createSelector(
    selectData,
    (data) => data?.ticketTypes ?? null,
  );

  export const selectRequestedInfo = createSelector(
    selectData,
    (data) => data?.requestedInfo ?? null,
  );

  export const selectTitle = createSelector(
    selectData,
    (data) => data?.title ?? null,
  );

  export const selectTicketCount = createSelector(
    selectTicketTypes,
    (ticketTypes) => sumOver(ticketTypes ?? [], 'ticketCount'),
  );

  export const selectHasPayedTickets = createSelector(
    selectTicketTypes,
    (ticketTypes) => sumOver(ticketTypes ?? [], 'price') > 0,
  );

  export const selectTicketTypeCount = createSelector(
    selectTicketTypes,
    (ticketTypes) => ticketTypes?.length ?? 0,
  );

  export const selectStats = createSelector(
    selectData,
    (data) => data?.stats ?? null,
  );

  export const selectTotalTicketCount = createSelector(
    selectTicketTypes,
    (ticketTypes) => sumOver(ticketTypes ?? [], 'ticketCount'),
  );

  export const selectNumberOfSaved = createSelector(
    selectStats,
    (stats) => stats?.numberOfSaved ?? 0,
  );

  export const selectNumberOfClicks = createSelector(
    selectStats,
    (stats) => stats?.numberOfClicks ?? 0,
  );

  export const selectTicketTypeStats = createSelector(
    selectStats,
    (stats) => stats?.ticketTypeStats ?? [],
  );

  export const selectNumberOfSoldTickets = createSelector(
    selectTicketTypeStats,
    (ticketTypeStats) => sumOver(ticketTypeStats ?? [], 'numberOfSoldTickets'),
  );

  export const selectTotalRevenue = createSelector(
    selectTicketTypeStats,
    (ticketTypeStats) => sumOver(ticketTypeStats ?? [], 'incomeSum'),
  );

  export const selectConsumedTicketCount = createSelector(
    selectTicketTypeStats,
    (ticketTypeStats) => sumOver(ticketTypeStats ?? [], 'consumedTicketCount'),
  );

  export const selectSoldTicketCount = createSelector(
    selectTicketTypeStats,
    (ticketTypeStats) => sumOver(ticketTypeStats ?? [], 'numberOfSoldTickets'),
  );

  export const selectMinTicketCounts = createSelector(
    selectTicketTypeStats,
    (ticketTypeStats) => {
      const minTicketCounts: Record<string, number> = {};

      ticketTypeStats.forEach((ticketType) => {
        minTicketCounts[ticketType.ticketTypeKey] =
          ticketType.numberOfSoldTickets;
      });

      return minTicketCounts;
    },
  );
}

export namespace ActivitySelector {
  const selectSelf = (state: RootState) => state.activity;

  export const selectStatus = createSelector(
    selectSelf,
    (activity) => activity.activity.status,
  );

  export const selectRequestedInfoStats = createSelector(
    selectSelf,
    (activity) => activity.requestedInfoStats.data,
  );

  export const selectError = createSelector(
    selectSelf,
    (activity) => activity.activity.error,
  );

  export const selectIsActivityOwner = createSelector(
    [ActivityDataSelector.selectHost, DepartmentSelector.selectDepartmentKey],
    (host, departmentKey) => host?.departmentKey === departmentKey,
  );
}

export namespace CreateActivitySelector {
  const selectSelf = (state: RootState) => state.activity.createActivity;

  export const selectActivityKey = createSelector(
    selectSelf,
    (createActivity) => createActivity.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (createActivity) => createActivity.status,
  );

  export const selectIsLoading = createSelector(
    selectStatus,
    (status) => status === 'pending',
  );

  const selectOriginalError = createSelector(
    selectSelf,
    (createActivity) => createActivity.originalError,
  );

  export const selectMissingMembershipTypeKeys = createSelector(
    selectOriginalError,
    (originalError) => {
      if (typeof originalError === 'object' && 'metadata' in originalError) {
        return originalError.metadata?.membershipTypeKeys ?? [];
      }

      return [];
    },
  );
}

export namespace UpdateActivitySelector {
  const selectSelf = (state: RootState) => state.activity.updateActivity;

  export const selectActivityKey = createSelector(
    selectSelf,
    (updateActivity) => updateActivity.data,
  );

  export const selectError = createSelector(
    selectSelf,
    (updateActivity) => updateActivity.error,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (updateActivity) => updateActivity.status,
  );

  export const selectIsLoading = createSelector(
    selectStatus,
    (status) => status === 'pending',
  );
}

export namespace UnpublishActivitySelector {
  export const selectStatus = (state: RootState) =>
    state.activity.unpublishActivity.status;
}

export namespace DynamicActivityLinkSelector {
  const selectSelf = (state: RootState) => state.activity.dynamicActivityLink;

  export const selectDynamicLink = createSelector(
    selectSelf,
    (dynamicActivityLink) => dynamicActivityLink.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (dynamicActivityLink) => dynamicActivityLink.status,
  );
}

export namespace ActivityRecordsSelector {
  const selectSelf = (state: RootState) => state.activity.activityRecords;

  export const selectStatus = createSelector(
    selectSelf,
    (activityRecords) => activityRecords.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (activityRecords) => activityRecords.data,
  );

  export const selectCount = createSelector(
    selectSelf,
    (activityRecords) => activityRecords.data?.length ?? 0,
  );
}

export namespace CoHostingRequestsSelector {
  const selectSelf = (state: RootState) => state.activity.coHostingRequests;

  const selectData = createSelector(
    selectSelf,
    (coHostingRequests) => coHostingRequests.data,
  );

  const coHostingSelectors = coHostingAdapter.getSelectors(selectData);

  export const selectAll = coHostingSelectors.selectAll;

  export const selectTotal = coHostingSelectors.selectTotal;

  export const selectStatus = createSelector(
    selectSelf,
    (coHostingRequests) => coHostingRequests.status,
  );
}

export namespace UpdateCoHostingRequestSelector {
  export const selectStatus = (state: RootState) =>
    state.activity.updateCoHostingRequest.status;
}

export namespace ActivityDraftsSelector {
  const selectSelf = (state: RootState) => state.activity.activityDrafts;

  const selectData = createSelector(
    selectSelf,
    (activityDrafts) => activityDrafts.data,
  );

  const draftSelectors = draftAdapter.getSelectors(selectData);

  export const selectAll = draftSelectors.selectAll;

  export const selectCount = draftSelectors.selectTotal;

  export const selectStatus = createSelector(
    selectSelf,
    (activityDrafts) => activityDrafts.status,
  );
}

export namespace CreateActivityDraftSelector {
  export const selectStatus = (state: RootState) =>
    state.activity.createActivityDraft.status;
}

export namespace ActivityDraftSelector {
  const selectSelf = (state: RootState) => state.activity.activityDraft;

  export const selectData = createSelector(
    selectSelf,
    (activityDraft) => activityDraft.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (activityDraft) => activityDraft.status,
  );

  export const selectKey = createSelector(
    selectData,
    (data) => data?.key ?? null,
  );
}

export namespace ActivitiesSelector {
  const selectSelf = (state: RootState) => state.activity.activities;

  const selectData = createSelector(
    selectSelf,
    (activities) => activities.data,
  );

  const activityListItemSelectors =
    activityListItemAdapter.getSelectors(selectData);

  export const selectAll = activityListItemSelectors.selectAll;

  export const selectCount = activityListItemSelectors.selectTotal;

  export const selectPagination = createSelector(
    selectSelf,
    (activities) => activities.pagination,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (activities) => activities.status,
  );

  export const isLoading = createSelector(
    selectSelf,
    (activities) => activities.status === 'pending',
  );
}

export namespace CreateQrScanTokenSelector {
  const selectSelf = (state: RootState) => state.activity.createQrScanToken;

  export const selectData = createSelector(
    selectSelf,
    (createQrScanToken) => createQrScanToken.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (createQrScanToken) => createQrScanToken.status,
  );
}

export namespace GetQrScanTokensSelector {
  const selectSelf = (state: RootState) => state.activity.qrScanTokens;

  const selectData = createSelector(
    selectSelf,
    (qrScanTokens) => qrScanTokens.data,
  );

  const qrScanTokensSelector = qrScanTokensAdapter.getSelectors(selectData);

  export const selectAll = qrScanTokensSelector.selectAll;

  export const selectStatus = createSelector(
    selectSelf,
    (qrScanTokens) => qrScanTokens.status,
  );
}

export namespace ActivityUsersSelector {
  const selectSelf = (state: RootState) => state.activity.activityUsers;

  export const selectData = createSelector(
    selectSelf,
    (activityUsers) => activityUsers.data,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (activityUsers) => activityUsers.status,
  );
}

export namespace FreeTextSelector {
  const selectSelf = (state: RootState) => state.activity.freetextListItems;

  const selectData = createSelector(
    selectSelf,
    (freetextListItems) => freetextListItems.data,
  );

  const freetextListItemsSelectors =
    freetextListItemsAdapter.getSelectors(selectData);

  export const selectFreeTextListItemsStatus = createSelector(
    selectSelf,
    (freetextListItems) => freetextListItems.status,
  );

  export const selectAll = freetextListItemsSelectors.selectAll;

  export const selectPagination = createSelector(
    selectSelf,
    (freetextListItems) => freetextListItems.pagination,
  );
}

export namespace ActivityDiscountStatsSelector {
  const selectSelf = (state: RootState) => state.activity.discountStats;

  export const selectStatus = createSelector(
    selectSelf,
    (discountStats) => discountStats.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (discountStats) => discountStats.data,
  );
}

export namespace TicketListItemsSelector {
  const selectSelf = (state: RootState) => state.activity.ticketListItems;

  const selectData = createSelector(
    selectSelf,
    (ticketListItems) => ticketListItems.data,
  );

  const ticketsSelectors = ticketsAdapter.getSelectors(selectData);

  export const selectAll = ticketsSelectors.selectAll;

  export const selectPagination = createSelector(
    selectSelf,
    (ticketListItems) => ticketListItems.pagination,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (ticketListItems) => ticketListItems.status,
  );

  export const selectSearch = createSelector(
    selectSelf,
    (ticketListItems) => ticketListItems.search,
  );
}

export namespace SelectedTicketListItemSelector {
  const selectSelf = (state: RootState) =>
    state.activity.selectedTicketListItem;

  export const selectData = selectSelf;

  export const selectSoldTicketKey = createSelector(
    selectSelf,
    (selectedTicketListItem) => selectedTicketListItem?.ticketKey,
  );

  export const selectIsConsumed = createSelector(
    selectSelf,
    (selectedTicketListItem) => !!selectedTicketListItem?.consumedAt,
  );

  export const selectConsumedAt = createSelector(
    selectSelf,
    (selectedTicketListItem) => selectedTicketListItem?.consumedAt,
  );

  export const selectUserIsDeleted = createSelector(
    selectSelf,
    (selectedTicketListItem) => !selectedTicketListItem?.email,
  );
}

export namespace SelectedTicketDetailsSelector {
  const selectSelf = (state: RootState) =>
    state.activity.selectedTicketListItem?.details;

  export const selectRequestedInfoAnswers = createSelector(
    selectSelf,
    (ticketDetails) => ticketDetails?.requestedInfoAnswers ?? null,
  );

  export const selectTicketTransferHistory = createSelector(
    selectSelf,
    (ticketDetails) => ticketDetails?.ticketTransferHistory ?? null,
  );

  export const selectedIsTransferred = createSelector(
    selectSelf,
    (ticketDetails) => !!ticketDetails?.ticketTransferHistory,
  );

  export const selectIsEmailTransferred = createSelector(
    selectSelf,
    (ticketDetails) =>
      ticketDetails?.ticketTransferHistory?.find(
        (ticketTransferHistoryItem) =>
          ticketTransferHistoryItem.transferType === 'email',
      ) !== undefined,
  );
}

export namespace TicketDetailsSelector {
  export const selectStatus = (state: RootState) =>
    state.activity.ticketDetails.status;
}

export namespace UndoConsumeTicketSelector {
  export const selectStatus = (state: RootState) =>
    state.activity.undoConsumeTicket.status;
}

export namespace ConsumeTicketSelector {
  export const selectStatus = (state: RootState) =>
    state.activity.consumeTicket.status;
}

export namespace RefundTicketSelector {
  export const selectStatus = (state: RootState) =>
    state.activity.refundTicket.status;
}

export namespace ScanTicketSelector {
  const selectSelf = (state: RootState) => state.activity.scanTicket;

  export const selectData = createSelector(
    selectSelf,
    (scanTicket) => scanTicket.data,
  );

  export const selectError = createSelector(
    selectSelf,
    (scanTicket) => scanTicket?.error,
  );

  export const selectStatus = createSelector(
    selectSelf,
    (scanTicket) => scanTicket.status,
  );

  export const selectRequiredMemberships = createSelector(
    selectData,
    (data) => data?.requiredMemberships ?? null,
  );

  export const selectRequiredMembershipCount = createSelector(
    selectRequiredMemberships,
    (requiredMemberships) => requiredMemberships?.length ?? 0,
  );

  export const selectTicketHolderType = createSelector(
    selectData,
    (data) => data?.ticketHolder?.type ?? null,
  );

  export const selectScannedTicketStatus = createSelector(
    selectData,
    (data) => data?.status,
  );
}

export namespace UndoScanTicketSelector {
  export const selectStatus = (state: RootState) =>
    state.activity.undoScanTicket.status;
}

export namespace TicketsHistorySelector {
  const selectSelf = (state: RootState) => state.activity.ticketsHistory;

  const selectData = createSelector(
    selectSelf,
    (ticketsHistory) => ticketsHistory.data,
  );

  const ticketsHistorySelectors =
    ticketsHistoryAdapter.getSelectors(selectData);

  export const selectAll = ticketsHistorySelectors.selectAll;

  export const selectStatus = createSelector(
    selectSelf,
    (ticketsHistory) => ticketsHistory.status,
  );
}

export namespace ActivityUsersDataSelector {
  const selectSelf = (state: RootState) => state.activity.activityUsersData;

  export const selectStatus = createSelector(
    selectSelf,
    (activityUsersCsvData) => activityUsersCsvData.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (activityUsersCsvData) => activityUsersCsvData.data,
  );
}

export namespace ActivityCategoriesSelector {
  const selectSelf = (state: RootState) => state.activity.activityCategories;

  export const selectStatus = createSelector(
    selectSelf,
    (activityCategories) => activityCategories.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (activityCategories) => activityCategories.data,
  );
}

export namespace SuggestActivityCategoriesSelector {
  const selectSelf = (state: RootState) =>
    state.activity.suggestActivityCategories;

  export const selectStatus = createSelector(
    selectSelf,
    (activityCategories) => activityCategories.status,
  );

  export const selectData = createSelector(
    selectSelf,
    (activityCategories) => activityCategories.data,
  );
}
