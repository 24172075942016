import React from 'react';

import { ENV } from '../config';
import { AccountSelector, useSelector } from '../store';

export function usePendo() {
  const userKey = useSelector(AccountSelector.selectUserKey);

  const firstName = useSelector(AccountSelector.selectFirstName);
  const lastName = useSelector(AccountSelector.selectLastName);

  const email = useSelector(AccountSelector.selectEmail);
  const department = useSelector(AccountSelector.selectCurrentWorkspace);

  React.useEffect(() => {
    if (ENV !== 'testing') return;

    if (!userKey || !department || !email || !firstName || !lastName) return;

    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: userKey,
          email: email,
          firstName: firstName,
          lastName: lastName,
        },
        account: {
          id: department.departmentKey,
          accountName: department.name,
          payingStatus: department.role,
        },
      });
    }
  }, [userKey, department, email, firstName, lastName]);
}
