import {
  Box,
  ContentContainer,
  InnerContentContainer,
  InnerPageContainer,
  NavigateWithQuery,
  Text,
} from '@orbiapp/components';
import React from 'react';

import { PostsSidebar, PostsTable } from '../../../../../components';
import {
  ActivityDataSelector,
  ActivitySelector,
  useSelector,
} from '../../../../../store';
import { EventActions } from '../components';

function ActivityPostsContent() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  return (
    <React.Fragment>
      <Box flex flexWrap="wrap" gap={16} flexJustify="between">
        <Text
          as="h1"
          color="pageTitle"
          tx="label.view-activity.tabs.posts"
          variant="titleMd"
        />

        <EventActions />
      </Box>

      <PostsTable activityKeys={activityKey ? [activityKey] : undefined} />
    </React.Fragment>
  );
}

export function ViewActivityPosts() {
  const isActivityOwner = useSelector(ActivitySelector.selectIsActivityOwner);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  if (!isActivityOwner) {
    return <NavigateWithQuery to={`/activities/${activityKey}/description`} />;
  }

  return (
    <React.Fragment>
      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <ActivityPostsContent />
          </InnerContentContainer>
        </ContentContainer>

        <PostsSidebar />
      </InnerPageContainer>
    </React.Fragment>
  );
}
