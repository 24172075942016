import React from 'react';
import {
  NavigateOptions,
  Link as ReactLink,
  LinkProps as ReactLinkProps,
  Navigate as ReactNavigate,
  To,
  useNavigate as reactUseNavigate,
} from 'react-router-dom';

import { NavigateProps } from './link.types';

function getUrlWithSearch(to: To): To {
  if (typeof to === 'string') {
    return {
      search: window.location.search,
      pathname: to,
    };
  }

  return {
    pathname: to.pathname,
    search: window.location.search,
  };
}

export function useNavigateWithQuery() {
  const navigate = reactUseNavigate();

  return React.useCallback(
    (to: To, options?: NavigateOptions) =>
      navigate(getUrlWithSearch(to), options),
    [navigate],
  );
}

export function LinkWithQuery(props: ReactLinkProps) {
  const { to, ...rest } = props;

  return <ReactLink to={getUrlWithSearch(to)} {...rest} />;
}

export function NavigateWithQuery(props: NavigateProps) {
  const { to, ...rest } = props;

  return <ReactNavigate to={getUrlWithSearch(to)} {...rest} />;
}
