import {
  Button,
  ButtonProps,
  FileType,
  POST_IMAGE_CRITERIA,
  Tooltip,
  UploadContainer,
  UploadError,
  UploadResult,
  getUID,
} from '@orbiapp/components';
import { useFormContext, useWatch } from 'react-hook-form';

import { CreatePostForm, POST_MAX_FILE_UPLOADS } from '../../../models';
import { setAlert, uploadFileThunk, useDispatch } from '../../../store';
import { imageMeetsCriteria } from '../../../utils';

export function usePostUpload() {
  const dispatch = useDispatch();

  const formContext = useFormContext<CreatePostForm>();

  const handleUploadError = (err: UploadError) => {
    if (err.message === 'accept') {
      dispatch(setAlert('file-type-not-allowed'));
      return;
    }

    const error = imageMeetsCriteria(POST_IMAGE_CRITERIA, {
      height: err.height,
      width: err.width,
      size: err.size,
    });

    if (error) {
      dispatch(setAlert(error.alertType, error.args));
    }
  };

  const uploadFile = async (result: UploadResult, file: File) => {
    const fileUploads = formContext.getValues('fileUploads');
    if (fileUploads.length >= POST_MAX_FILE_UPLOADS) {
      dispatch(setAlert('max-number-of-files-reached'));
      return;
    }

    const id = getUID();

    formContext.setValue('fileUploads', [
      ...formContext.getValues('fileUploads'),
      {
        id,
        fileUploadKey: null,
        base64: result.base64,
        status: 'pending',
      },
    ]);

    const res = await dispatch(uploadFileThunk(file));
    const newState = formContext
      .getValues('fileUploads')
      .filter((file) => file.id !== id);
    formContext.setValue(
      'fileUploads',
      [
        ...newState,
        {
          fileUploadKey: typeof res.payload === 'string' ? res.payload : null,
          base64: result.base64,
          status: typeof res.payload === 'string' ? 'success' : 'error',
          id,
        },
      ],
      {
        shouldDirty: true,
        shouldValidate: formContext.formState.isSubmitted,
      },
    );
  };

  return { handleUploadError, uploadFile };
}

export function UploadFiles(props: Partial<ButtonProps>) {
  const { handleUploadError, uploadFile } = usePostUpload();

  const fileUploads = useWatch<CreatePostForm, 'fileUploads'>({
    name: 'fileUploads',
  });

  const hasReachedMaxFiles = fileUploads.length >= POST_MAX_FILE_UPLOADS;

  return (
    <UploadContainer
      accept={Object.values(FileType).join(', ')}
      onError={handleUploadError}
      onUpload={uploadFile}
      disabled={props.disabled}
      maxHeight={POST_IMAGE_CRITERIA.maxHeight}
      maxWidth={POST_IMAGE_CRITERIA.maxWidth}
      maxSize={POST_IMAGE_CRITERIA.maxSize}
      minHeight={POST_IMAGE_CRITERIA.minHeight}
      minWidth={POST_IMAGE_CRITERIA.minWidth}
      multiple
    >
      {hasReachedMaxFiles ? (
        <Tooltip
          placement="right"
          titleTx="errors.upload.max-number-of-files-reached"
        >
          <Button
            disabled
            variant="secondary"
            tx="button.upload-image"
            {...props}
          />
        </Tooltip>
      ) : (
        <Button variant="secondary" tx="button.upload-image" {...props} />
      )}
    </UploadContainer>
  );
}
