import {
  MembershipApplicationStatus,
  MembershipInviteListItem,
  MembershipPeriod,
  RequestedInfoType,
} from '../../models';
import {
  acceptApplicationThunk,
  checkIntegrationThunk,
  createCheckboxThunk,
  createDynamicMembershipLinkThunk,
  createFreetextThunk,
  createIntegratedMembershipTypeThunk,
  createMembershipApplicationRequirementsThunk,
  createMembershipInvitationsThunk,
  createMembershipTypeThunk,
  createMultichoiceQuestionThunk,
  createPeriodThunk,
  deleteCheckboxQuestionThunk,
  deleteFreetextQuestionThunk,
  deleteMembershipTypeThunk,
  deleteMultichoiceQuestionThunk,
  deletePeriodThunk,
  deleteRequirementThunk,
  getMembershipApplicationThunk,
  getMembershipApplicationsThunk,
  getMembershipInvitesThunk,
  getMembershipMemberListItemStats,
  getMembershipMemberThunk,
  getMembershipMembersThunk,
  getMembershipPurchaseListItemsThunk,
  getMembershipTypeStatsThunk,
  getMembershipTypeThunk,
  getMembershipsTypesThunk,
  getSuggestedMembershipsThunk,
  refundMembershipPurchaseThunk,
  rejectApplicationThunk,
  requestMoreInfoOnMembershipApplicationThunk,
  updateCheckboxThunk,
  updateCombinationTypeThunk,
  updateFreetextThunk,
  updateGeneralInfoThunk,
  updateMultichoiceThunk,
  updatePeriodThunk,
  withdrawInviteThunk,
} from './memberships.actions';
import {
  membershipApplicationsAdapter,
  membershipInvitesAdapater,
  membershipMembersAdapter,
  membershipPurchaseListItemsAdapter,
  membershipTypeListItemsAdapter,
} from './memberships.adapter';
import { MembershipsBuilder } from './memberships.types';

const createMembershipTypeBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createMembershipTypeThunk.fulfilled, (state, action) => {
      state.createMembershipType.status = 'idle';
      state.createMembershipType.data = action.payload.membershipTypeKey;
      membershipTypeListItemsAdapter.addOne(
        state.membershipsTypeListItems.data,
        {
          createdAt: Date.now(),
          logo: action.payload.logo,
          logoUrl: action.payload.logo.original.url,
          membershipTypeKey: action.payload.membershipTypeKey,
          name: action.meta.arg.name,
          pendingApplicationCount: 0,
          source: 'orbi',
          totalMemberCount: 0,
          type: action.meta.arg.type,
        },
      );
    })
    .addCase(createMembershipTypeThunk.pending, (state) => {
      state.createMembershipType.status = 'pending';
      state.createMembershipType.error = null;
    })
    .addCase(createMembershipTypeThunk.rejected, (state, action) => {
      state.createMembershipType.status = 'idle';
      state.createMembershipType.error = action.error;
    });
};

const getMembershipsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipsTypesThunk.fulfilled, (state, action) => {
      membershipTypeListItemsAdapter.setAll(
        state.membershipsTypeListItems.data,
        action.payload,
      );

      state.membershipsTypeListItems.status = 'idle';
    })
    .addCase(getMembershipsTypesThunk.pending, (state) => {
      state.membershipsTypeListItems.status = 'pending';
      state.membershipsTypeListItems.error = null;
    })
    .addCase(getMembershipsTypesThunk.rejected, (state, action) => {
      state.membershipsTypeListItems.status = 'idle';
      state.membershipsTypeListItems.error = action.error;
    });
};

const getMembershipTypeBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipTypeThunk.fulfilled, (state, action) => {
      state.membershipType.status = 'idle';
      state.membershipType.data = action.payload;
    })
    .addCase(getMembershipTypeThunk.pending, (state) => {
      state.membershipType.status = 'pending';
      state.membershipType.error = null;
    })
    .addCase(getMembershipTypeThunk.rejected, (state, action) => {
      state.membershipType.status = 'idle';
      state.membershipType.error = action.error;
    });
};

const deleteMembershipTypeBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(deleteMembershipTypeThunk.fulfilled, (state, action) => {
      state.deleteMembershipType.status = 'idle';

      membershipTypeListItemsAdapter.removeOne(
        state.membershipsTypeListItems.data,
        action.meta.arg,
      );
    })
    .addCase(deleteMembershipTypeThunk.pending, (state) => {
      state.deleteMembershipType.status = 'pending';
      state.deleteMembershipType.error = null;
    })
    .addCase(deleteMembershipTypeThunk.rejected, (state, action) => {
      state.deleteMembershipType.status = 'idle';
      state.deleteMembershipType.error = action.error;
    });
};

const createDynamicMembershipLinkBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createDynamicMembershipLinkThunk.fulfilled, (state, action) => {
      state.dynamicMembershipLink.status = 'idle';
      state.dynamicMembershipLink.data = action.payload;
    })
    .addCase(createDynamicMembershipLinkThunk.pending, (state) => {
      state.dynamicMembershipLink.status = 'pending';
      state.dynamicMembershipLink.error = null;
    })
    .addCase(createDynamicMembershipLinkThunk.rejected, (state, action) => {
      state.dynamicMembershipLink.status = 'idle';
      state.dynamicMembershipLink.error = action.error;
    });
};

const rejectApplicationBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(rejectApplicationThunk.fulfilled, (state, action) => {
      state.rejectMembershipApplication.status = 'idle';
      state.membershipApplication.data = null;

      membershipApplicationsAdapter.removeOne(
        state.membershipApplications.data,
        action.meta.arg.membershipApplicationKey,
      );
    })
    .addCase(rejectApplicationThunk.pending, (state) => {
      state.rejectMembershipApplication.status = 'pending';
      state.rejectMembershipApplication.error = null;
    })
    .addCase(rejectApplicationThunk.rejected, (state, action) => {
      state.rejectMembershipApplication.status = 'idle';
      state.rejectMembershipApplication.error = action.error;
    });
};

const acceptApplicationBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(acceptApplicationThunk.fulfilled, (state, action) => {
      state.acceptMembershipApplication.status = 'idle';

      if (state.membershipType.data?.type === 'integration') return;

      const periods = state.membershipApplication.data?.periods;
      const totalCost = periods?.reduce(
        (totalCost, period) => totalCost + period.price,
        0,
      );

      if (!totalCost) {
        state.membershipApplication.data = null;
        membershipApplicationsAdapter.removeOne(
          state.membershipApplications.data,
          action.payload,
        );
        return;
      }

      membershipApplicationsAdapter.updateOne(
        state.membershipApplications.data,
        { id: action.payload, changes: { status: 'awaiting_completion' } },
      );
      state.membershipApplication.data!.status = 'awaiting_completion';
      state.membershipApplication.data!.statusUpdates.push({
        createdAt: Date.now(),
        needsMoreInfoReason: null,
        note: null,
        status: 'awaiting_completion',
      });
    })
    .addCase(acceptApplicationThunk.pending, (state) => {
      state.acceptMembershipApplication.status = 'pending';
      state.acceptMembershipApplication.error = null;
    })
    .addCase(acceptApplicationThunk.rejected, (state, action) => {
      state.acceptMembershipApplication.status = 'idle';
      state.acceptMembershipApplication.error = action.error;
    });
};

const requestMoreInfoOnMembershipApplicationBuild = (
  builder: MembershipsBuilder,
) => {
  return builder
    .addCase(
      requestMoreInfoOnMembershipApplicationThunk.fulfilled,
      (state, action) => {
        state.requestMoreInfoOnMembershipApplication.status = 'idle';

        const status: MembershipApplicationStatus = 'needs_more_info';

        membershipApplicationsAdapter.updateOne(
          state.membershipApplications.data,
          {
            id: action.meta.arg.membershipApplicationKey,
            changes: { status },
          },
        );

        if (!state.membershipApplication.data) return;

        state.membershipApplication.data.status = status;
        state.membershipApplication.data.statusUpdates.push({
          createdAt: Date.now(),
          needsMoreInfoReason: action.meta.arg.reason,
          status,
          note: action.meta.arg.note,
        });
      },
    )
    .addCase(requestMoreInfoOnMembershipApplicationThunk.pending, (state) => {
      state.requestMoreInfoOnMembershipApplication.status = 'pending';
      state.requestMoreInfoOnMembershipApplication.error = null;
    })
    .addCase(
      requestMoreInfoOnMembershipApplicationThunk.rejected,
      (state, action) => {
        state.requestMoreInfoOnMembershipApplication.status = 'idle';
        state.requestMoreInfoOnMembershipApplication.error = action.error;
      },
    );
};

const getMembershipApplicationBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipApplicationThunk.fulfilled, (state, action) => {
      state.membershipApplication.status = 'idle';
      state.membershipApplication.data = action.payload;
    })
    .addCase(getMembershipApplicationThunk.pending, (state) => {
      state.membershipApplication.status = 'pending';
      state.membershipApplication.error = null;
    })
    .addCase(getMembershipApplicationThunk.rejected, (state, action) => {
      state.membershipApplication.status = 'idle';
      state.membershipApplication.error = action.error;
    });
};

const getMembershipApplicationsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipApplicationsThunk.fulfilled, (state, action) => {
      membershipApplicationsAdapter.setMany(
        state.membershipApplications.data,
        action.payload,
      );
      state.membershipApplications.status = 'idle';
    })
    .addCase(getMembershipApplicationsThunk.pending, (state, action) => {
      state.membershipApplications.status = 'pending';
      state.membershipApplications.pagination = action.meta.arg;
      state.membershipApplications.error = null;
    })
    .addCase(getMembershipApplicationsThunk.rejected, (state, action) => {
      state.membershipApplications.status = 'idle';
      state.membershipApplications.error = action.error;
    });
};

const createCheckboxBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createCheckboxThunk.fulfilled, (state, action) => {
      state.createCheckboxQuestion.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      if (!state.membershipType.data.membershipType.membershipQuestions) {
        state.membershipType.data.membershipType.membershipQuestions = [];
      }

      state.membershipType.data.membershipType.membershipQuestions.push({
        createdAt: Date.now(),
        index: 0,
        isRequired: action.meta.arg.isRequired,
        key: action.payload,
        question: action.meta.arg.question,
        type: RequestedInfoType.Checkbox,
      });
    })
    .addCase(createCheckboxThunk.pending, (state) => {
      state.createCheckboxQuestion.status = 'pending';
      state.createCheckboxQuestion.error = null;
    })
    .addCase(createCheckboxThunk.rejected, (state, action) => {
      state.createCheckboxQuestion.status = 'idle';
      state.createCheckboxQuestion.error = action.error;
    });
};

const updateCheckboxBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(updateCheckboxThunk.fulfilled, (state, action) => {
      state.updateCheckboxQuestion.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      const questions =
        state.membershipType.data.membershipType.membershipQuestions;

      if (!questions) return;

      const index =
        state.membershipType.data.membershipType.membershipQuestions?.findIndex(
          (question) => question.key === action.meta.arg.key,
        );

      if (index === undefined || index === -1) return;

      questions[index] = {
        ...questions[index],
        ...action.meta.arg,
      };
    })
    .addCase(updateCheckboxThunk.pending, (state) => {
      state.updateCheckboxQuestion.status = 'pending';
      state.updateCheckboxQuestion.error = null;
    })
    .addCase(updateCheckboxThunk.rejected, (state, action) => {
      state.updateCheckboxQuestion.status = 'idle';
      state.updateCheckboxQuestion.error = action.error;
    });
};

const deleteCheckboxBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(deleteCheckboxQuestionThunk.fulfilled, (state, action) => {
      state.deleteCheckboxQuestion.status = 'idle';

      if (
        state.membershipType.data?.type === 'default' &&
        state.membershipType.data.membershipType.membershipQuestions
      ) {
        state.membershipType.data.membershipType.membershipQuestions =
          state.membershipType.data.membershipType.membershipQuestions?.filter(
            (question) => question.key !== action.meta.arg,
          );
      }
    })
    .addCase(deleteCheckboxQuestionThunk.pending, (state) => {
      state.deleteCheckboxQuestion.status = 'pending';
      state.deleteCheckboxQuestion.error = null;
    })
    .addCase(deleteCheckboxQuestionThunk.rejected, (state, action) => {
      state.deleteCheckboxQuestion.status = 'idle';
      state.deleteCheckboxQuestion.error = action.error;
    });
};

const createFreetextBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createFreetextThunk.fulfilled, (state, action) => {
      state.createFreetextQuestion.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      if (!state.membershipType.data.membershipType.membershipQuestions) {
        state.membershipType.data.membershipType.membershipQuestions = [];
      }

      state.membershipType.data.membershipType.membershipQuestions.push({
        createdAt: Date.now(),
        index: 0,
        isRequired: action.meta.arg.isRequired,
        key: action.payload,
        question: action.meta.arg.question,
        type: RequestedInfoType.FreeText,
      });
    })
    .addCase(createFreetextThunk.pending, (state) => {
      state.createFreetextQuestion.status = 'pending';
      state.createFreetextQuestion.error = null;
    })
    .addCase(createFreetextThunk.rejected, (state, action) => {
      state.createFreetextQuestion.status = 'idle';
      state.createFreetextQuestion.error = action.error;
    });
};

const updateFreetextBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(updateFreetextThunk.fulfilled, (state, action) => {
      state.updateFreetextQuestion.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      const questions =
        state.membershipType.data.membershipType.membershipQuestions;

      if (!questions) return;

      const index =
        state.membershipType.data.membershipType.membershipQuestions?.findIndex(
          (question) => question.key === action.meta.arg.key,
        );

      if (index === undefined || index === -1) return;

      questions[index] = {
        ...questions[index],
        ...action.meta.arg,
      };
    })
    .addCase(updateFreetextThunk.pending, (state) => {
      state.updateFreetextQuestion.status = 'pending';
      state.updateFreetextQuestion.error = null;
    })
    .addCase(updateFreetextThunk.rejected, (state, action) => {
      state.updateFreetextQuestion.status = 'idle';
      state.updateFreetextQuestion.error = action.error;
    });
};

const deleteFreetextBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(deleteFreetextQuestionThunk.fulfilled, (state, action) => {
      state.deleteFreetextQuestion.status = 'idle';

      if (
        state.membershipType.data?.type === 'default' &&
        state.membershipType.data.membershipType.membershipQuestions
      ) {
        state.membershipType.data.membershipType.membershipQuestions =
          state.membershipType.data.membershipType.membershipQuestions?.filter(
            (question) => question.key !== action.meta.arg,
          );
      }
    })
    .addCase(deleteFreetextQuestionThunk.pending, (state) => {
      state.deleteFreetextQuestion.status = 'pending';
      state.deleteFreetextQuestion.error = null;
    })
    .addCase(deleteFreetextQuestionThunk.rejected, (state, action) => {
      state.deleteFreetextQuestion.status = 'idle';
      state.deleteFreetextQuestion.error = action.error;
    });
};

const createMultichoiceBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createMultichoiceQuestionThunk.fulfilled, (state, action) => {
      state.createMultichoiceQuestion.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      if (!state.membershipType.data.membershipType.membershipQuestions) {
        state.membershipType.data.membershipType.membershipQuestions = [];
      }

      state.membershipType.data.membershipType.membershipQuestions.push({
        createdAt: Date.now(),
        index: 0,
        isRequired: action.meta.arg.isRequired,
        key: action.payload,
        options: action.meta.arg.options,
        question: action.meta.arg.question,
        type: RequestedInfoType.MultiChoice,
      });
    })
    .addCase(createMultichoiceQuestionThunk.pending, (state) => {
      state.createMultichoiceQuestion.status = 'pending';
      state.createMultichoiceQuestion.error = null;
    })
    .addCase(createMultichoiceQuestionThunk.rejected, (state, action) => {
      state.createMultichoiceQuestion.status = 'idle';
      state.createMultichoiceQuestion.error = action.error;
    });
};

const updateMultichoiceBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(updateMultichoiceThunk.fulfilled, (state, action) => {
      state.updateMultichoiceQuestion.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      const questions =
        state.membershipType.data.membershipType.membershipQuestions;

      if (!questions) return;

      const index =
        state.membershipType.data.membershipType.membershipQuestions?.findIndex(
          (question) => question.key === action.meta.arg.key,
        );

      if (index === undefined || index === -1) return;

      questions[index] = {
        ...questions[index],
        ...action.meta.arg,
      };
    })
    .addCase(updateMultichoiceThunk.pending, (state) => {
      state.updateMultichoiceQuestion.status = 'pending';
      state.updateMultichoiceQuestion.error = null;
    })
    .addCase(updateMultichoiceThunk.rejected, (state, action) => {
      state.updateMultichoiceQuestion.status = 'idle';
      state.updateMultichoiceQuestion.error = action.error;
    });
};

const deleteMultichoiceBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(deleteMultichoiceQuestionThunk.fulfilled, (state, action) => {
      state.deleteMultichoiceQuestion.status = 'idle';

      if (
        state.membershipType.data?.type === 'default' &&
        state.membershipType.data.membershipType.membershipQuestions
      ) {
        state.membershipType.data.membershipType.membershipQuestions =
          state.membershipType.data.membershipType.membershipQuestions?.filter(
            (question) => question.key !== action.meta.arg,
          );
      }
    })
    .addCase(deleteMultichoiceQuestionThunk.pending, (state) => {
      state.deleteMultichoiceQuestion.status = 'pending';
      state.deleteMultichoiceQuestion.error = null;
    })
    .addCase(deleteMultichoiceQuestionThunk.rejected, (state, action) => {
      state.deleteMultichoiceQuestion.status = 'idle';
      state.deleteMultichoiceQuestion.error = action.error;
    });
};

const updateGeneralInfoBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(updateGeneralInfoThunk.fulfilled, (state, action) => {
      state.updateGeneralInfo.status = 'idle';

      if (!state.membershipType.data?.membershipType) return;
      const changes = {
        name: action.meta.arg.name,
        description: action.meta.arg.description,
        contactDetails: action.meta.arg.contactDetails,
        logo: action.payload.logo,
        backgroundImage: action.payload.backgroundImage,
      };

      state.membershipType.data.membershipType = {
        ...state.membershipType.data.membershipType,
        ...changes,
      };

      membershipTypeListItemsAdapter.updateOne(
        state.membershipsTypeListItems.data,
        {
          id: state.membershipType.data.membershipType.membershipTypeKey,
          changes,
        },
      );
    })
    .addCase(updateGeneralInfoThunk.pending, (state) => {
      state.updateGeneralInfo.status = 'pending';
      state.updateGeneralInfo.error = null;
    })
    .addCase(updateGeneralInfoThunk.rejected, (state, action) => {
      state.updateGeneralInfo.status = 'idle';
      state.updateGeneralInfo.error = action.error;
    });
};

const createIntegratedMembershipTypeBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createIntegratedMembershipTypeThunk.fulfilled, (state, action) => {
      state.createIntegratedMembershipType.status = 'idle';
      state.createIntegratedMembershipType.data = action.payload;
    })
    .addCase(createIntegratedMembershipTypeThunk.pending, (state) => {
      state.createIntegratedMembershipType.status = 'pending';
      state.createIntegratedMembershipType.error = null;
    })
    .addCase(createIntegratedMembershipTypeThunk.rejected, (state, action) => {
      state.createIntegratedMembershipType.status = 'idle';
      state.createIntegratedMembershipType.error = action.error;
    });
};

const checkIntegrationBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(checkIntegrationThunk.fulfilled, (state) => {
      state.checkIntegration.status = 'idle';
    })
    .addCase(checkIntegrationThunk.pending, (state) => {
      state.checkIntegration.status = 'pending';
      state.checkIntegration.error = null;
    })
    .addCase(checkIntegrationThunk.rejected, (state, action) => {
      state.checkIntegration.status = 'idle';
      state.checkIntegration.error = action.error;
    });
};

const createInvitationsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createMembershipInvitationsThunk.fulfilled, (state, action) => {
      state.createMembershipInvitations.status = 'idle';
      state.createMembershipInvitations.data = action.payload;

      const now = Date.now();

      const newInvites: MembershipInviteListItem[] = action.payload.invites
        .filter(
          (invite) =>
            invite.result === 'invited' || invite.result === 'modified',
        )
        .map((invite): MembershipInviteListItem => {
          const membershipTypePeriods =
            state.membershipType.data?.type === 'integration'
              ? null
              : new Map(
                  state.membershipType.data?.membershipType.periods?.map(
                    (period) => [period.key, period],
                  ),
                );

          const periods =
            action.meta.arg.membershipPeriodKeys
              ?.map((periodKey): MembershipPeriod | null => {
                const period = membershipTypePeriods?.get(periodKey);

                if (!period) {
                  return null;
                }

                return {
                  key: period.key,
                  name: period.name,
                  validFrom: period.validFrom ?? now,
                  validTo: period.validTo,
                };
              })
              .filter(
                (period): period is MembershipPeriod => period !== null,
              ) ?? null;

          return {
            createdAt: now,
            email: invite.email,
            fullName: invite.fullName,
            membershipApplicationKey: invite.membershipApplicationKey,
            periods,
            status: 'awaiting_completion',
            profilePicture: invite.profilePicture,
            userKey: invite.userKey,
          };
        });

      membershipInvitesAdapater.addMany(
        state.membershipInvites.data,
        newInvites,
      );

      if (
        state.membershipType.data?.type === 'integration' ||
        !state.membershipType.data?.membershipType
      )
        return;
      state.membershipType.data.membershipType.pendingInvitationCount +=
        newInvites.length;
    })
    .addCase(createMembershipInvitationsThunk.pending, (state) => {
      state.createMembershipInvitations.status = 'pending';
      state.createMembershipInvitations.error = null;
    })
    .addCase(createMembershipInvitationsThunk.rejected, (state, action) => {
      state.createMembershipInvitations.status = 'idle';
      state.createMembershipInvitations.error = action.error;
    });
};

const withdrawInviteBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(withdrawInviteThunk.fulfilled, (state, action) => {
      state.withdrawInvite.status = 'idle';

      membershipInvitesAdapater.updateOne(state.membershipInvites.data, {
        id: action.payload,
        changes: { status: 'withdrawn' },
      });

      if (
        state.membershipType.data?.type === 'integration' ||
        !state.membershipType.data?.membershipType
      )
        return;
      state.membershipType.data.membershipType.pendingInvitationCount -= 1;
    })
    .addCase(withdrawInviteThunk.pending, (state) => {
      state.withdrawInvite.status = 'pending';
      state.withdrawInvite.error = null;
    })
    .addCase(withdrawInviteThunk.rejected, (state, action) => {
      state.withdrawInvite.status = 'idle';
      state.withdrawInvite.error = action.error;
    });
};

const getMembershipInvitesBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipInvitesThunk.fulfilled, (state, action) => {
      state.membershipInvites.status = 'idle';

      membershipInvitesAdapater.setMany(
        state.membershipInvites.data,
        action.payload,
      );
    })
    .addCase(getMembershipInvitesThunk.pending, (state, action) => {
      state.membershipInvites.status = 'pending';
      state.membershipInvites.pagination = action.meta.arg;
      state.membershipInvites.error = null;
    })
    .addCase(getMembershipInvitesThunk.rejected, (state, action) => {
      state.membershipInvites.status = 'idle';
      state.membershipInvites.error = action.error;
    });
};

const getMembershipMembersBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipMembersThunk.fulfilled, (state, action) => {
      if (state.membershipMembers.search) {
        membershipMembersAdapter.setAll(
          state.membershipMembers.data,
          action.payload,
        );
      } else {
        membershipMembersAdapter.setMany(
          state.membershipMembers.data,
          action.payload,
        );
      }

      state.membershipMembers.status = 'idle';
    })
    .addCase(getMembershipMembersThunk.pending, (state, action) => {
      state.membershipMembers.error = null;
      state.membershipMembers.pagination = action.meta.arg;
      state.membershipMembers.status = 'pending';
    })
    .addCase(getMembershipMembersThunk.rejected, (state, action) => {
      state.membershipMembers.status = 'idle';
      state.membershipMembers.error = action.error;
    });
};

const getMembershipMemberBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipMemberThunk.fulfilled, (state, action) => {
      state.membershipMember.data = action.payload;
      state.membershipMember.status = 'idle';
    })
    .addCase(getMembershipMemberThunk.pending, (state) => {
      state.membershipMember.error = null;
      state.membershipMember.status = 'pending';
    })
    .addCase(getMembershipMemberThunk.rejected, (state, action) => {
      state.membershipMember.status = 'idle';
      state.membershipMember.error = action.error;
    });
};

const updateCombinationTypeBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(updateCombinationTypeThunk.fulfilled, (state, action) => {
      state.updateCombinationType.status = 'idle';

      if (
        state.membershipType.data?.type === 'default' &&
        state.membershipType.data.membershipType.requiredMembershipTypes
      ) {
        state.membershipType.data.membershipType.requiredMembershipTypes.combinationType =
          action.meta.arg;
      }
    })
    .addCase(updateCombinationTypeThunk.pending, (state) => {
      state.updateCombinationType.status = 'pending';
      state.updateCombinationType.error = null;
    })
    .addCase(updateCombinationTypeThunk.rejected, (state, action) => {
      state.updateCombinationType.status = 'idle';
      state.updateCombinationType.error = action.error;
    });
};

const deleteRequirementBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(deleteRequirementThunk.fulfilled, (state, action) => {
      state.deleteRequirement.status = 'idle';

      if (
        state.membershipType.data?.type === 'default' &&
        state.membershipType.data.membershipType.requiredMembershipTypes
          ?.membershipApplicationRequirements
      ) {
        state.membershipType.data.membershipType.requiredMembershipTypes.membershipApplicationRequirements =
          state.membershipType.data.membershipType.requiredMembershipTypes.membershipApplicationRequirements?.filter(
            (period) =>
              period.membershipApplicationRequirementKey !== action.meta.arg,
          );
      }
    })
    .addCase(deleteRequirementThunk.pending, (state) => {
      state.deleteRequirement.status = 'pending';
      state.deleteRequirement.error = null;
    })
    .addCase(deleteRequirementThunk.rejected, (state, action) => {
      state.deleteRequirement.status = 'idle';
      state.deleteRequirement.error = action.error;
    });
};

const createApplicationRequirementsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(
      createMembershipApplicationRequirementsThunk.fulfilled,
      (state, action) => {
        state.createMembershipApplicationRequirements.status = 'idle';

        if (state.membershipType.data?.type !== 'default') return;

        const requirements = new Map<string, string>(
          action.payload.membershipApplicationRequirements.map(
            (requirement) => [
              requirement.membershipTypeKey,
              requirement.membershipApplicationRequirementKey,
            ],
          ),
        );

        if (!state.membershipType.data.membershipType.requiredMembershipTypes) {
          state.membershipType.data.membershipType.requiredMembershipTypes = {
            combinationType: 'any',
            membershipApplicationRequirements: [],
          };
        }

        state.membershipType.data.membershipType.requiredMembershipTypes.membershipApplicationRequirements.push(
          ...action.meta.arg.map((requirement) => ({
            ...requirement,
            membershipApplicationRequirementKey: requirements.get(
              requirement.membershipTypeKey,
            )!,
          })),
        );
      },
    )
    .addCase(createMembershipApplicationRequirementsThunk.pending, (state) => {
      state.createMembershipApplicationRequirements.status = 'pending';
      state.createMembershipApplicationRequirements.error = null;
    })
    .addCase(
      createMembershipApplicationRequirementsThunk.rejected,
      (state, action) => {
        state.createMembershipApplicationRequirements.status = 'idle';
        state.createMembershipApplicationRequirements.error = action.error;
      },
    );
};

const getMembershipTypeStatsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipTypeStatsThunk.fulfilled, (state, action) => {
      state.membershipTypeStats.status = 'idle';
      state.membershipTypeStats.data = action.payload;
    })
    .addCase(getMembershipTypeStatsThunk.pending, (state) => {
      state.membershipTypeStats.status = 'pending';
      state.membershipTypeStats.error = null;
    })
    .addCase(getMembershipTypeStatsThunk.rejected, (state, action) => {
      state.membershipTypeStats.status = 'idle';
      state.membershipTypeStats.error = action.error;
    });
};

const getMembershipMemberListItemStatsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipMemberListItemStats.fulfilled, (state, action) => {
      state.membershipMemberListItemStats.status = 'idle';
      state.membershipMemberListItemStats.data = action.payload;
    })
    .addCase(getMembershipMemberListItemStats.pending, (state) => {
      state.membershipMemberListItemStats.status = 'pending';
      state.membershipMemberListItemStats.error = null;
    })
    .addCase(getMembershipMemberListItemStats.rejected, (state, action) => {
      state.membershipMemberListItemStats.status = 'idle';
      state.membershipMemberListItemStats.error = action.error;
    });
};

const createPeriodBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(createPeriodThunk.fulfilled, (state, action) => {
      state.createPeriod.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      if (!state.membershipType.data.membershipType.periods) {
        state.membershipType.data.membershipType.periods = [];
      }

      state.membershipType.data.membershipType.periods.push({
        key: action.payload,
        name: action.meta.arg.name,
        price: action.meta.arg.price,
        saleEnd: action.meta.arg.saleEnd,
        saleStart: action.meta.arg.saleStart,
        validFrom: action.meta.arg.validFrom,
        validTo: action.meta.arg.validTo,
      });
    })
    .addCase(createPeriodThunk.pending, (state) => {
      state.createPeriod.status = 'pending';
      state.createPeriod.error = null;
    })
    .addCase(createPeriodThunk.rejected, (state, action) => {
      state.createPeriod.status = 'idle';
      state.createPeriod.error = action.error;
    });
};

const updatePeriodBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(updatePeriodThunk.fulfilled, (state, action) => {
      state.updatePeriod.status = 'idle';

      if (state.membershipType.data?.type !== 'default') return;

      const periods = state.membershipType.data.membershipType.periods;
      if (!periods) return;

      const index = periods.findIndex(
        (period) => period.key === action.meta.arg.key,
      );

      if (index === undefined || index === -1) return;

      periods[index] = {
        ...periods[index],
        ...action.meta.arg,
      };
    })
    .addCase(updatePeriodThunk.pending, (state) => {
      state.updatePeriod.status = 'pending';
      state.updatePeriod.error = null;
    })
    .addCase(updatePeriodThunk.rejected, (state, action) => {
      state.updatePeriod.status = 'idle';
      state.updatePeriod.error = action.error;
    });
};

const deletePeriodBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(deletePeriodThunk.fulfilled, (state, action) => {
      state.deletePeriod.status = 'idle';

      if (
        state.membershipType.data?.type === 'default' &&
        state.membershipType.data.membershipType.periods
      ) {
        state.membershipType.data.membershipType.periods =
          state.membershipType.data.membershipType.periods?.filter(
            (period) => period.key !== action.meta.arg,
          );
      }
    })
    .addCase(deletePeriodThunk.pending, (state) => {
      state.deletePeriod.status = 'pending';
      state.deletePeriod.error = null;
    })
    .addCase(deletePeriodThunk.rejected, (state, action) => {
      state.deletePeriod.status = 'idle';
      state.deletePeriod.error = action.error;
    });
};

const getMembershipPurchaseListItemsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getMembershipPurchaseListItemsThunk.fulfilled, (state, action) => {
      membershipPurchaseListItemsAdapter.setMany(
        state.membershipPurchaseListItems.data,
        action.payload,
      );
      state.membershipPurchaseListItems.status = 'idle';
    })
    .addCase(getMembershipPurchaseListItemsThunk.pending, (state, action) => {
      state.membershipPurchaseListItems.status = 'pending';
      state.membershipPurchaseListItems.pagination = action.meta.arg;
      state.membershipPurchaseListItems.error = null;
    })
    .addCase(getMembershipPurchaseListItemsThunk.rejected, (state, action) => {
      state.membershipPurchaseListItems.status = 'idle';
      state.membershipPurchaseListItems.error = action.error;
    });
};

const refundMembershipPurchaseBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(refundMembershipPurchaseThunk.fulfilled, (state, action) => {
      state.refundMembershipPurchase.status = 'idle';

      if (state.membershipMember.data) {
        state.membershipMember.data.periods =
          state.membershipMember.data.periods.filter(
            (period) => period.membershipPurchaseKey !== action.meta.arg,
          );

        if (state.membershipMember.data.periods.length === 0) {
          membershipMembersAdapter.removeOne(
            state.membershipMembers.data,
            state.membershipMember.data.userKey,
          );

          state.membershipMember.data = null;
        }
      }

      membershipPurchaseListItemsAdapter.updateOne(
        state.membershipPurchaseListItems.data,
        { id: action.meta.arg, changes: { refundedAt: Date.now() } },
      );
    })
    .addCase(refundMembershipPurchaseThunk.pending, (state) => {
      state.refundMembershipPurchase.status = 'pending';
      state.refundMembershipPurchase.error = null;
    })
    .addCase(refundMembershipPurchaseThunk.rejected, (state, action) => {
      state.refundMembershipPurchase.status = 'idle';
      state.refundMembershipPurchase.error = action.error;
    });
};

const getSuggestedMembershipsBuild = (builder: MembershipsBuilder) => {
  return builder
    .addCase(getSuggestedMembershipsThunk.fulfilled, (state, action) => {
      state.getSuggestedMemberships.status = 'idle';
      state.getSuggestedMemberships.data = action.payload;
    })
    .addCase(getSuggestedMembershipsThunk.pending, (state) => {
      state.getSuggestedMemberships.status = 'pending';
      state.getSuggestedMemberships.error = null;
    })
    .addCase(getSuggestedMembershipsThunk.rejected, (state, action) => {
      state.getSuggestedMemberships.status = 'idle';
      state.getSuggestedMemberships.error = action.error;
    });
};

export const extraReducers = (builder: MembershipsBuilder) => {
  createMembershipTypeBuild(builder);
  getMembershipsBuild(builder);
  getMembershipTypeBuild(builder);
  deleteMembershipTypeBuild(builder);
  createDynamicMembershipLinkBuild(builder);

  getMembershipApplicationsBuild(builder);
  getMembershipApplicationBuild(builder);
  requestMoreInfoOnMembershipApplicationBuild(builder);
  acceptApplicationBuild(builder);
  rejectApplicationBuild(builder);

  createCheckboxBuild(builder);
  updateCheckboxBuild(builder);
  deleteCheckboxBuild(builder);

  createFreetextBuild(builder);
  updateFreetextBuild(builder);
  deleteFreetextBuild(builder);

  createMultichoiceBuild(builder);
  updateMultichoiceBuild(builder);
  deleteMultichoiceBuild(builder);

  updateGeneralInfoBuild(builder);

  createIntegratedMembershipTypeBuild(builder);
  checkIntegrationBuild(builder);

  createInvitationsBuild(builder);
  withdrawInviteBuild(builder);
  getMembershipInvitesBuild(builder);

  getMembershipMemberBuild(builder);
  getMembershipMembersBuild(builder);

  updateCombinationTypeBuild(builder);
  deleteRequirementBuild(builder);
  createApplicationRequirementsBuild(builder);

  createPeriodBuild(builder);
  updatePeriodBuild(builder);
  deletePeriodBuild(builder);

  getSuggestedMembershipsBuild(builder);

  getMembershipTypeStatsBuild(builder);
  getMembershipMemberListItemStatsBuild(builder);

  getMembershipPurchaseListItemsBuild(builder);
  refundMembershipPurchaseBuild(builder);
};
