import {
  Box,
  Button,
  ModalBodyContainer,
  ModalContentContainer,
  ModalFooterContainer,
  ModalHeaderContainer,
  ModalTitle,
  SelectionCard,
} from '@orbiapp/components';
import { PostTargetType } from 'dtos/base/post-target-type';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { CreatePostForm } from '../../../models';
import { DepartmentSelector, useSelector } from '../../../store';
import { QuickActionsContext } from '../../quick-actions';
import { CloseAndResetButton, CreatePostContext } from '../components';

export function PickTargetType() {
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);

  const { createPostModalState } = React.useContext(QuickActionsContext);
  const createPostContext = React.useContext(CreatePostContext);

  const formContext = useFormContext<CreatePostForm>();

  const [selectedTargetType, setSelectedTargetType] = useState<PostTargetType>(
    createPostContext.targetType ?? 'department',
  );

  const selectDepartmentTargetType = () => {
    setSelectedTargetType('department');
  };

  const selectActivityTargetType = () => {
    setSelectedTargetType('activity');
  };

  const selectMembershipTargetType = () => {
    setSelectedTargetType('membership_type');
  };

  const continueToForm = () => {
    if (!selectedTargetType) return;

    createPostContext.setTargetType(selectedTargetType);

    switch (selectedTargetType) {
      case 'activity':
        formContext.reset({
          targetType: 'activity',

          fileUploads: [],
          message: '',
          pushTo: null,
          targetKey: createPostModalState.value?.targetKey,
        });
        break;

      case 'department':
        formContext.reset({
          targetType: 'department',

          fileUploads: [],
          message: '',
          pushTo: null,
          targetKey: departmentKey,
        });
        break;

      case 'membership_type':
        formContext.reset({
          targetType: 'membership_type',
          targetKeys: [],

          fileUploads: [],
          message: '',
          pushTo: null,
        });
        break;
    }
  };

  return (
    <ModalContentContainer>
      <ModalHeaderContainer>
        <ModalTitle
          textAlign="center"
          tx="label.posts.create-post-modal.choose-where-to-post"
        />
      </ModalHeaderContainer>

      <ModalBodyContainer>
        <Box gap={24} flex flexDirection="column">
          <SelectionCard
            onClick={selectDepartmentTargetType}
            minWidth={300}
            flexBasis={0}
            flexGrow={1}
            titleTx="label.posts.create-post-modal.pick-target-type.department-title"
            subtitleTx="label.posts.create-post-modal.pick-target-type.department-subtitle"
            toggled={selectedTargetType === 'department'}
            subtitleColor="textSecondary"
            subtitleVariant="bodySm"
            p={24}
            subtitleMaxWidth="unset"
            icon="academic-cap-outline"
            toggledIcon="academic-cap-solid"
          />

          <SelectionCard
            onClick={selectActivityTargetType}
            minWidth={300}
            flexBasis={0}
            flexGrow={1}
            titleTx="label.posts.create-post-modal.pick-target-type.event-title"
            subtitleTx="label.posts.create-post-modal.pick-target-type.event-subtitle"
            toggled={selectedTargetType === 'activity'}
            subtitleColor="textSecondary"
            subtitleVariant="bodySm"
            p={24}
            subtitleMaxWidth="unset"
            icon="ticket-outline"
            toggledIcon="ticket-solid"
          />

          <SelectionCard
            onClick={selectMembershipTargetType}
            minWidth={300}
            flexBasis={0}
            flexGrow={1}
            titleTx="label.posts.create-post-modal.pick-target-type.membership-title"
            subtitleTx="label.posts.create-post-modal.pick-target-type.membership-subtitle"
            toggled={selectedTargetType === 'membership_type'}
            subtitleColor="textSecondary"
            subtitleVariant="bodySm"
            p={24}
            subtitleMaxWidth="unset"
            icon="identification-outline"
            toggledIcon="identification-solid"
          />
        </Box>
      </ModalBodyContainer>

      <ModalFooterContainer flexJustify="end">
        <CloseAndResetButton />

        <Button
          tx="button.continue"
          variant="primary"
          onClick={continueToForm}
        />
      </ModalFooterContainer>
    </ModalContentContainer>
  );
}
