import React from 'react';

import { BrowserUrl } from '../services';

export function useSignInWithCustomToken(
  onTokenFound: (customToken: string) => Promise<void>,
) {
  const [canContinue, setCanContinue] = React.useState(false);

  const getQueryParams = React.useCallback(async () => {
    const customToken = BrowserUrl.getCustomToken();
    if (customToken) {
      await onTokenFound(customToken);
      BrowserUrl.deleteCustomToken();
    }

    setCanContinue(true);
  }, [onTokenFound]);

  React.useEffect(() => {
    getQueryParams();
  }, [getQueryParams]);

  return canContinue;
}
