import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  ContentContainer,
  ContentSidebar,
  EmptyState,
  IconButton,
  InnerContentContainer,
  NavigateWithQuery,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  formatDate,
  parseCurrency,
  translate,
  useNavigateWithQuery,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  CreateMembershipTypeForm,
  MembershipPeriodForm,
  PeriodsForm,
  PeriodsFormValidation,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import { OrbiPaySettingsSelector, useSelector } from '../../../../../store';
import {
  getOptionalLabelText,
  getPeriodFormDefaultValues,
} from '../../../../../utils';
import { PeriodForm } from '../../components/period-form';
import { CreateMembershipTypeFormNavBlocker } from '../components';

const PeriodsSidebarContext = React.createContext<{
  defaultValues: MembershipPeriodForm | null;
  setDefaultValues: React.Dispatch<
    React.SetStateAction<MembershipPeriodForm | null>
  >;
}>({
  defaultValues: null,
  setDefaultValues: () => {},
});

const AddPeriodButton = () => {
  const { defaultValues, setDefaultValues } = React.useContext(
    PeriodsSidebarContext,
  );

  const addPeriod = () => {
    setDefaultValues(getPeriodFormDefaultValues());
  };

  return (
    <Tooltip
      placement="left"
      titleTx="label.memberships.periods.empty-state.button"
    >
      <IconButton
        disabled={!!defaultValues}
        icon="plus-circle-outline"
        onClick={addPeriod}
      />
    </Tooltip>
  );
};

function PeriodsSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [defaultValues, setDefaultValues] =
    React.useState<MembershipPeriodForm | null>(null);

  return (
    <PeriodsSidebarContext.Provider value={{ defaultValues, setDefaultValues }}>
      {children}
    </PeriodsSidebarContext.Provider>
  );
}

const AddPeriodEmptyState = () => {
  const { defaultValues, setDefaultValues } = React.useContext(
    PeriodsSidebarContext,
  );

  const openSidebar = () => {
    setDefaultValues(getPeriodFormDefaultValues());
  };

  return (
    <EmptyState
      buttonOnClick={openSidebar}
      buttonTx="label.memberships.periods.empty-state.button"
      disabled={defaultValues !== null}
      titleTx="label.memberships.periods.empty-state.label"
    />
  );
};

function PeriodsContent() {
  const { watch, setValue } = useFormContext<PeriodsForm>();
  const { defaultValues, setDefaultValues } = React.useContext(
    PeriodsSidebarContext,
  );

  const periods = watch('periods') ?? [];

  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const renderPeriodTableRow = (period: MembershipPeriodForm) => {
    const removePeriod: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation();

      setValue(
        'periods',
        periods.filter(({ key }) => key !== period.key),
      );
    };

    const selectRow = () => {
      setDefaultValues(period);
    };

    return (
      <TableRow
        highlight={defaultValues?.key === period.key}
        key={period.key}
        onClick={selectRow}
      >
        <TableCell text={period.name} />
        <TableCell
          text={
            period.validFrom
              ? formatDate(period.validFrom, 'DD MMM YYYY HH:mm')
              : translate('label.memberships.update-period-modal.lifetime')
          }
        />
        <TableCell
          text={
            period.validTo
              ? formatDate(period.validTo, 'DD MMM YYYY HH:mm')
              : translate('label.memberships.update-period-modal.lifetime')
          }
        />
        <TableCell text={formatDate(period.saleStart, 'DD MMM YYYY HH:mm')} />
        <TableCell text={formatDate(period.saleEnd, 'DD MMM YYYY HH:mm')} />
        <TableCell text={parseCurrency(period.price, currency)} />
        <TableCell width={40} hoverCell fixedRight>
          <IconButton icon="trash-outline" onClick={removePeriod} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      {periods.length === 0 ? (
        <AddPeriodEmptyState />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead tx="label.memberships.create-membership.periods.name.label" />
              <TableHead tx="label.memberships.create-membership.periods.valid-from.label" />
              <TableHead tx="label.memberships.create-membership.periods.valid-to.label" />
              <TableHead tx="label.memberships.create-membership.periods.sale-start.label" />
              <TableHead tx="label.memberships.create-membership.periods.sale-end.label" />
              <TableHead tx="label.memberships.create-membership.periods.price.label" />
              <TableHead fixedRight />
            </TableRow>
          </TableHeader>
          <TableBody>{periods.map(renderPeriodTableRow)}</TableBody>
        </Table>
      )}
    </React.Fragment>
  );
}

function PeriodsSidebar() {
  const { defaultValues, setDefaultValues } = React.useContext(
    PeriodsSidebarContext,
  );

  const closeSidebar = () => setDefaultValues(null);
  const periodsFormMethods = useFormContext<PeriodsForm>();

  const addPeriod = (data: MembershipPeriodForm) => {
    const periods = periodsFormMethods.getValues('periods') ?? [];

    const periodIndex = periods.findIndex((period) => period.key === data.key);

    if (periodIndex !== -1) {
      periods[periodIndex] = data;
    } else {
      periods.push(data);
    }

    periodsFormMethods.setValue('periods', periods);

    setDefaultValues(null);
  };

  return (
    <ContentSidebar width={470} isOpen={!!defaultValues} onClose={closeSidebar}>
      <PeriodForm
        titleTx="label.memberships.create-membership.periods.sidebar.title"
        defaultValues={defaultValues ?? getPeriodFormDefaultValues()}
        onSubmitted={addPeriod}
      />
    </ContentSidebar>
  );
}

export function CreateMembershipPeriods() {
  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const navigate = useNavigateWithQuery();

  const formMethods = useForm<PeriodsForm>({
    resolver: joiResolver(PeriodsFormValidation),
    defaultValues: {
      periods: createMembershipTypeFormContext.getValues('periods'),
    },
  });

  if (!createMembershipTypeFormContext.formState.isDirty) {
    return (
      <NavigateWithQuery to="/memberships/create-membership/general-info" />
    );
  }

  const handlePrevious = () => {
    createMembershipTypeFormContext.setValue(
      'periods',
      formMethods.getValues('periods'),
    );

    navigate('/memberships/create-membership/questions');
  };

  const handleNext = formMethods.handleSubmit(
    (data) => {
      createMembershipTypeFormContext.setValue('periods', data.periods, {
        shouldDirty: true,
      });

      navigate('/memberships/create-membership/summary');
    },
    (err) => {
      Logger.warning('createMembershipPeriods Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <PeriodsSidebarProvider>
      <FormProvider {...formMethods}>
        <CreateMembershipTypeFormNavBlocker shouldBlock />

        <ContentContainer>
          <InnerContentContainer>
            <Text
              color="pageTitle"
              as="h1"
              tx="label.memberships.create-membership.tabs.periods"
              variant="titleMd"
            />

            <Box flex flexDirection="column" gap={24}>
              <Box flex flexJustify="between" flexAlign="center">
                <Text
                  text={getOptionalLabelText(
                    'label.memberships.create-membership.periods.steps.1',
                  )}
                  variant="bodyMdBold"
                />

                <AddPeriodButton />
              </Box>

              <PeriodsContent />
            </Box>
          </InnerContentContainer>

          <Box p={32} flex flexJustify="between" gap={16}>
            <Tooltip titleTx="button.previous" placement="right">
              <IconButton
                onClick={handlePrevious}
                icon="arrow-left-circle-outline"
              />
            </Tooltip>

            <Tooltip placement="left" titleTx="button.continue">
              <IconButton
                icon="arrow-right-circle-outline"
                onClick={handleNext}
              />
            </Tooltip>
          </Box>
        </ContentContainer>

        <PeriodsSidebar />
      </FormProvider>
    </PeriodsSidebarProvider>
  );
}
