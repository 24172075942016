import Joi from 'joi';

import { CreatePostSchema, UpdatePostSchema } from './post.schema';

export const CreatePostValidation = Joi.object({
  targetType: CreatePostSchema.targetType,
  message: CreatePostSchema.message,
  fileUploads: CreatePostSchema.fileUploads,
  pushTo: CreatePostSchema.pushTo,

  targetKeys: CreatePostSchema.targetKeys,
  targetKey: CreatePostSchema.targetKey,
});

export const EditPostValidation = Joi.object({
  message: UpdatePostSchema.message,
});
