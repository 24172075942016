import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  BreadCrumb,
  BreadCrumbs,
  Button,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  ControlledTextarea,
  EmptyState,
  InnerContentContainer,
  InnerPageContainer,
  Link,
  PageContainer,
  Status,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePagination,
  TablePlaceholderRows,
  TableRow,
  Text,
  Toolbar,
  ToolbarContentContainer,
  flattenFieldErrorsObject,
  formatDate,
  paginatorOptions,
  useFeatureFlag,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';

import { useDataGridPagination } from '../../../helpers';
import {
  ANSWER_MAX_LENGTH,
  Question,
  QuestionForm,
  QuestionOrderByKey,
  QuestionValidation,
} from '../../../models';
import { Logger } from '../../../services';
import {
  AccountSelector,
  QuestionsSelector,
  UpdateQuestionSelector,
  getQuestionsThunk,
  questionsActions,
  setQuestionToggleIsHiddenThunk,
  updateQuestionThunk,
  useDispatch,
  useSelector,
} from '../../../store';

interface QuestionsContextType {
  selectedQuestion: Question | null;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<Question | null>>;
}

const QuestionsContext = React.createContext<QuestionsContextType>({
  selectedQuestion: null,
  setSelectedQuestion: () => {},
});

function QuestionsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [selectedQuestion, setSelectedQuestion] =
    React.useState<Question | null>(null);

  return (
    <QuestionsContext.Provider
      value={{ selectedQuestion, setSelectedQuestion }}
    >
      {children}
    </QuestionsContext.Provider>
  );
}

const TABLE_COLUMN_WIDTHS = {
  status: 200,
  activityTitle: 300,
  createdAt: 200,
  askedByEmail: 200,
  question: 300,
  answeredAt: 200,
  actions: 50,
};

function getQuestionStatusTx(question: Question): TxString {
  if (question.isHidden) {
    return 'label.questions.questions-table-body.hidden';
  }

  if (question.answer) {
    return 'label.questions.questions-table-body.answered';
  }

  return 'label.questions.questions-table-body.open';
}

function QuestionsTable() {
  const { selectedQuestion, setSelectedQuestion } =
    React.useContext(QuestionsContext);
  const questions = useSelector(QuestionsSelector.selectAll);
  const questionsPagination = useSelector(QuestionsSelector.selectPagination);
  const questionsStatus = useSelector(QuestionsSelector.selectStatus);

  const isLoading = questionsStatus === 'pending';

  const { rows, paginatorProps, onPageSizeChange, onPaginate } =
    useDataGridPagination<Question, QuestionOrderByKey>({
      data: questions,
      pagination: questionsPagination,
      reset: questionsActions.clearQuestions,
      thunk: getQuestionsThunk,
    });

  if (!isLoading && rows.length === 0) {
    return <EmptyState titleTx="label.questions.empty-state.title" />;
  }

  if (isLoading && rows.length === 0) {
    return (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.questions.questions-table-header.status" />
            <TableHead tx="label.questions.questions-table-header.activity" />
            <TableHead tx="label.questions.questions-table-header.created-at" />
            <TableHead tx="label.questions.questions-table-header.email" />
            <TableHead tx="label.questions.questions-table-header.question-text" />
            <TableHead tx="label.questions.questions-table-header.answered-at" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>

        <TableBody>
          <TablePlaceholderRows
            rowCount={10}
            layout={Object.values(TABLE_COLUMN_WIDTHS)}
          />
        </TableBody>
      </Table>
    );
  }

  const renderQuestionTableRow = (question: Question) => {
    const openQuestionsSidebar = () => setSelectedQuestion(question);

    return (
      <TableRow
        highlight={question.questionKey === selectedQuestion?.questionKey}
        onClick={openQuestionsSidebar}
        key={question.questionKey}
      >
        <TableCell width={TABLE_COLUMN_WIDTHS.status}>
          <Status
            variant={
              question.isHidden
                ? 'info'
                : question.answer
                  ? 'success'
                  : 'warning'
            }
            tx={getQuestionStatusTx(question)}
          />
        </TableCell>
        <TableCell
          width={TABLE_COLUMN_WIDTHS.activityTitle}
          text={question.activityTitle}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.createdAt}
          text={formatDate(question.createdAt, 'DD MMM YYYY HH:mm')}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.askedByEmail}
          text={question.askedByEmail ?? ''}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.question}
          text={question.question}
        />
        <TableCell
          width={TABLE_COLUMN_WIDTHS.answeredAt}
          text={
            question.answeredAt
              ? formatDate(question.answeredAt, 'DD MMM YYYY HH:mm')
              : ''
          }
        />

        <TableCell width={TABLE_COLUMN_WIDTHS.actions} fixedRight />
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.questions.questions-table-header.status" />
            <TableHead tx="label.questions.questions-table-header.activity" />
            <TableHead tx="label.questions.questions-table-header.created-at" />
            <TableHead tx="label.questions.questions-table-header.email" />
            <TableHead tx="label.questions.questions-table-header.question-text" />
            <TableHead tx="label.questions.questions-table-header.answered-at" />

            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>{rows.map(renderQuestionTableRow)}</TableBody>
      </Table>

      <TablePagination
        currentPage={paginatorProps.currentPage}
        hasNextPage={paginatorProps.hasNextPage}
        hasPrevPage={paginatorProps.hasPrevPage}
        onPageSizeChange={onPageSizeChange}
        onPaginate={onPaginate}
        pageSize={paginatorProps.pageSize}
        paginatorOptions={paginatorOptions}
        tx="label.general.rows-per-page"
      />
    </React.Fragment>
  );
}

function QuestionsContent() {
  return (
    <React.Fragment>
      <Text
        color="pageTitle"
        tx="title.questions.dashboard"
        variant="titleMd"
        as="h1"
      />

      <QuestionsTable />
    </React.Fragment>
  );
}

function QuestionIsHiddenSwitch() {
  const { selectedQuestion, setSelectedQuestion } =
    React.useContext(QuestionsContext);

  const dispatch = useDispatch();

  const toggleQuestionIsHidden = async () => {
    if (!selectedQuestion) return;

    const res = await dispatch(
      setQuestionToggleIsHiddenThunk({
        activityKey: selectedQuestion.activityKey,
        questionKey: selectedQuestion.questionKey,
        isHidden: !selectedQuestion.isHidden,
      }),
    );

    if (res.meta.requestStatus === 'fulfilled') {
      setSelectedQuestion({
        ...selectedQuestion,
        isHidden: !selectedQuestion.isHidden,
      });
    }
  };

  return (
    <Switch
      checked={selectedQuestion?.isHidden ?? false}
      onClick={toggleQuestionIsHidden}
      tx="label.questions.toggle"
    />
  );
}

function UpdateQuestionFormContent() {
  const { selectedQuestion } = React.useContext(QuestionsContext);

  const dispatch = useDispatch();

  const fullName = useSelector(AccountSelector.selectFullName);

  const updateQuestionStatus = useSelector(UpdateQuestionSelector.selectStatus);

  const isLoading = updateQuestionStatus === 'pending';

  const formMethods = useForm<QuestionForm>({
    resolver: joiResolver(QuestionValidation),
    defaultValues: {
      answer: selectedQuestion?.answer ?? '',
    },
  });

  const updateQuestion = formMethods.handleSubmit(
    (data) => {
      if (!selectedQuestion || !fullName) return;

      dispatch(
        updateQuestionThunk({
          activityKey: selectedQuestion.activityKey,
          answer: data.answer,
          answeredByFullName: fullName,
          questionKey: selectedQuestion.questionKey,
        }),
      );
    },
    (err) => {
      Logger.warning('updateQuestion Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  React.useEffect(() => {
    formMethods.reset({
      answer: selectedQuestion?.answer ?? '',
    });
  }, [formMethods, selectedQuestion?.questionKey, selectedQuestion?.answer]);

  return (
    <FormProvider {...formMethods}>
      <ContentSidebarContentContainer>
        <Box flex flexDirection="column" gap={8}>
          <Box width="fit-content">
            <Link
              tx="label.questions.go-to-activity"
              to={`/activities/${selectedQuestion?.activityKey}/questions`}
            />
          </Box>

          <Box>
            <Text
              color="pageTitle"
              variant="titleMd"
              tx="label.questions.question"
            />
            <Text variant="bodySm" text={selectedQuestion?.question} />
          </Box>
        </Box>

        <QuestionIsHiddenSwitch />

        <ControlledTextarea
          disabled={isLoading}
          labelTx="label.questions.answer"
          maxLength={ANSWER_MAX_LENGTH}
          name="answer"
        />
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button
          isLoading={isLoading}
          onClick={updateQuestion}
          tx="button.save"
          variant="secondary"
        />
      </ContentSidebarFooterContainer>
    </FormProvider>
  );
}

function QuestionsSidebar() {
  const { selectedQuestion, setSelectedQuestion } =
    React.useContext(QuestionsContext);

  const isOpen = selectedQuestion !== null;
  const closeQuestionsSidebar = () => setSelectedQuestion(null);

  return (
    <ContentSidebar isOpen={isOpen} onClose={closeQuestionsSidebar} width={400}>
      <UpdateQuestionFormContent />
    </ContentSidebar>
  );
}

function QuestionsToolbar() {
  return (
    <Toolbar backgroundColor="tabHeaderBackground" zIndex={1}>
      <ToolbarContentContainer>
        <BreadCrumbs>
          <BreadCrumb tx="label.breadcrumbs.questions.questions" isLast />
        </BreadCrumbs>
      </ToolbarContentContainer>
    </Toolbar>
  );
}

export function Questions() {
  const showQuestions = useFeatureFlag('student_dashboard_show_questions');
  if (!showQuestions) {
    return <Navigate to="/" />;
  }

  return (
    <QuestionsProvider>
      <PageContainer>
        <QuestionsToolbar />

        <InnerPageContainer>
          <ContentContainer>
            <InnerContentContainer>
              <QuestionsContent />
            </InnerContentContainer>
          </ContentContainer>

          <QuestionsSidebar />
        </InnerPageContainer>
      </PageContainer>
    </QuestionsProvider>
  );
}
