import {
  Box,
  Button,
  Menu,
  MenuItem,
  ResponsiveBox,
  SolidIconButton,
  Text,
  Tooltip,
  formatDate,
  getUID,
  translate,
  useMenu,
} from '@orbiapp/components';
import React from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';

import { RequestedInfoType } from '../../../../../../models';
import {
  MembershipMemberListItemStatsSelector,
  MembershipTypeSelector,
  useSelector,
} from '../../../../../../store';
import { formatMembershipId, isActivePeriod } from '../../../../../../utils';
import { Styled } from './download-users.styled';

const renderTd = (td: string | null, j: number, arr: (string | null)[]) => (
  <td key={`tr-${arr.indexOf(td)}-td-${j}`}>{td}</td>
);

const renderTr = (tr: (string | null)[], i: number) => (
  <tr key={`tr-${i}`}>{tr.map(renderTd)}</tr>
);

const renderTh = (th: string | null, i: number) => (
  <th key={`th-${i}`}>{th}</th>
);

export const DownloadMembershipMembers = () => {
  const membershipTypeName = useSelector(
    MembershipTypeSelector.selectMembershipTypeName,
  );
  const membershipMemberStats = useSelector(
    MembershipMemberListItemStatsSelector.selectData,
  );

  const tableRef = React.useRef(null);

  const menuState = useMenu();

  const filename = membershipTypeName ?? getUID();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: filename,
    sheet: filename,
  });

  const csvData = React.useMemo(() => {
    if (!membershipMemberStats) return [];

    const head = [
      translate('label.memberships.view-membership.download-users.full-name'),
      translate('label.memberships.view-membership.download-users.email'),
      translate('label.memberships.view-membership.download-users.period'),
      translate('label.memberships.view-membership.download-users.status'),
      translate('label.memberships.view-membership.download-users.date'),
      translate('label.memberships.view-membership.download-users.valid-from'),
      translate('label.memberships.view-membership.download-users.valid-to'),
      translate(
        'label.memberships.view-membership.download-users.segmentation',
      ),
      translate(
        'label.memberships.view-membership.download-users.membership-id',
      ),
      translate(
        'label.memberships.view-membership.download-users.food-preferences',
      ),
    ];

    const requestedInfoQuestions = new Map<string, string>();

    membershipMemberStats.forEach((user) => {
      user.answers?.forEach((requestedInfoAnswer) => {
        requestedInfoQuestions.set(
          requestedInfoAnswer.key,
          requestedInfoAnswer.question,
        );
      });
    });

    const keys = Array.from(requestedInfoQuestions.keys());
    const values = Array.from(requestedInfoQuestions.values());

    head.push(...values);

    const rows = membershipMemberStats.flatMap((user) => {
      return user.periods.map((period) => {
        const row = [
          user.fullName,
          user.email,
          period.name ?? '-',
          translate(
            isActivePeriod(period)
              ? 'label.memberships.view-membership.download-users.active'
              : 'label.memberships.view-membership.download-users.inactive',
          ),
          formatDate(period.createdAt, 'DD MMM YYYY'),
          formatDate(period.validFrom, 'DD MMM YYYY'),
          period.validTo
            ? formatDate(period.validTo, 'DD MMM YYYY')
            : translate(
                'label.memberships.view-membership.download-users.lifetime',
              ),
          user.segmentation === 'semester'
            ? translate('label.view-activity.users-list.columns.semester', {
                semester: user.semester.toString(),
              })
            : translate('label.view-activity.users-list.columns.year', {
                year: Math.floor((user.semester + 1) / 2).toString(),
              }),
          user.membershipId ? formatMembershipId(user.membershipId) : '-',
          user.foodPreferences ?? '-',
        ];

        const answers = keys.map((requestedInfoKey) => {
          const answer = user.answers?.find(
            (requestedInfoAnswer) =>
              requestedInfoAnswer.key === requestedInfoKey,
          );

          switch (answer?.type) {
            case RequestedInfoType.Checkbox:
              return answer.isChecked
                ? translate('label.boolean.yes')
                : translate('label.boolean.no');

            case RequestedInfoType.MultiChoice:
              return answer.label;

            case RequestedInfoType.FreeText:
              return answer.answer;

            default:
              return '-';
          }
        });

        row.push(...answers);

        return row;
      });
    });

    return [head, ...rows];
  }, [membershipMemberStats]);

  return (
    <Box ref={menuState.clickOutsideRef} relative>
      <ResponsiveBox
        xs={
          <Button
            icon={menuState.isOpen ? 'chevron-up' : 'chevron-down'}
            onClick={menuState.toggleMenu}
            tx="button.download-members"
            variant="secondary"
            iconPlacement="right"
          />
        }
      >
        <Tooltip placement="left" titleTx="button.download-members">
          <SolidIconButton
            isActive={menuState.isOpen}
            onClick={menuState.toggleMenu}
            icon="arrow-download-tray"
          />
        </Tooltip>
      </ResponsiveBox>

      <Menu
        minWidth={200}
        absolute
        top="100%"
        right={0}
        mt={8}
        ref={menuState.menuRef}
        isOpen={menuState.isOpen}
      >
        <MenuItem onClick={() => {}} p={0}>
          <Styled.DownloadCsv
            data={csvData}
            filename={`${filename}.csv`}
            target="_blank"
          >
            <Text variant="bodyMd" tx="link.general.download-as-csv" />
          </Styled.DownloadCsv>
        </MenuItem>
        <MenuItem onClick={onDownload} tx="link.general.download-as-xls" />
      </Menu>

      {csvData.length !== 0 && (
        <Styled.TableBox>
          <table ref={tableRef}>
            <tbody>
              <tr>{csvData[0].map(renderTh)}</tr>
              {csvData.slice(1, csvData.length).map(renderTr)}
            </tbody>
          </table>
        </Styled.TableBox>
      )}
    </Box>
  );
};
