import {
  Alert,
  Box,
  Button,
  GeneralApiProblem,
  Snackbar,
  Text,
  TxProps,
  createUniqueArray,
  flattenFieldErrorsObject,
  isObject,
} from '@orbiapp/components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CreateActivityErrorResponse,
  CreateActivityResponse,
  CreateCoHostRequest,
  MembershipTypeDeletedError,
  RemoveTicketError,
  TicketType,
  UpdateActivityErrorResponse,
} from '../../../../../../models';
import { Logger } from '../../../../../../services';
import {
  ActivityDataSelector,
  CreateActivitySelector,
  UpdateActivitySelector,
  createActivityThunk,
  updateActivityThunk,
  useDispatch,
  useSelector,
} from '../../../../../../store';
import { ActivityFormContext } from '../../create-activity.context';
import { Styled } from './submit-event-button.styled';

export function EventSubmitButton() {
  const formContext = React.useContext(ActivityFormContext);

  return (
    <React.Fragment>
      {formContext.mode === 'update' ? (
        <UpdateEventButton />
      ) : (
        <PublishEventButton />
      )}

      <ValidationErrorSnackbar />
      <MembershipTypeErrorSnackbar />
    </React.Fragment>
  );
}

function ValidationErrorSnackbar() {
  const formContext = React.useContext(ActivityFormContext);

  const closeErrorSnackbar = () => {
    formContext.setShowErrorSnackbar(false);
  };

  const errorTxStrings = React.useMemo(
    () =>
      getValidationErrorListItems(Object.keys(formContext.formState.errors)),
    [formContext.formState.errors],
  );

  const shouldShowErrorSnackbar =
    errorTxStrings.length > 0 &&
    formContext.formState.isSubmitted &&
    formContext.showErrorSnackbar;

  if (!shouldShowErrorSnackbar) {
    return null;
  }

  return (
    <Snackbar onClose={closeErrorSnackbar} placement="top-end" zIndex={1000}>
      <Alert variant="error">
        <Box>
          <Text
            color="alertErrorTitle"
            variant="bodyMdBold"
            tx="errors.activity-form.title"
          />

          <Text tx="errors.activity-form.subtitle" variant="bodyMd" />

          <Styled.ErrorSnackbarList>
            {errorTxStrings.map(renderErrorKey)}
          </Styled.ErrorSnackbarList>
        </Box>
      </Alert>
    </Snackbar>
  );
}

function MembershipTypeErrorSnackbar() {
  const missingMembershipTypeKeys = useSelector(
    CreateActivitySelector.selectMissingMembershipTypeKeys,
  );

  const formContext = React.useContext(ActivityFormContext);

  const closeErrorSnackbar = () => {
    formContext.setShowErrorSnackbar(false);
  };

  const errorTxStrings = React.useMemo(
    () =>
      getMissingMembershipTypeErrorListItems(
        formContext.getValues('tickets.ticketTypes'),
        missingMembershipTypeKeys,
      ),
    [formContext, missingMembershipTypeKeys],
  );

  const shouldShowErrorSnackbar =
    errorTxStrings.length > 0 &&
    formContext.formState.isSubmitted &&
    formContext.showErrorSnackbar;

  if (!shouldShowErrorSnackbar) {
    return null;
  }

  return (
    <Snackbar onClose={closeErrorSnackbar} placement="top-end" zIndex={1000}>
      <Alert variant="error">
        <Box>
          <Text
            color="alertErrorTitle"
            variant="bodyMdBold"
            tx="errors.activity-form.title"
            mb={8}
          />

          <Styled.ErrorSnackbarList>
            {errorTxStrings.map(renderErrorKey)}
          </Styled.ErrorSnackbarList>
        </Box>
      </Alert>
    </Snackbar>
  );
}

function UpdateEventButton() {
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);
  const activityTicketTypes = useSelector(
    ActivityDataSelector.selectTicketTypes,
  );
  const isLoading = useSelector(UpdateActivitySelector.selectIsLoading);

  const dispatch = useDispatch();

  const formContext = React.useContext(ActivityFormContext);

  const putBackRemovedTicketType = (ticketTypeKey: string) => {
    const ticketType = activityTicketTypes?.find(
      (type) => type.ticketTypeKey === ticketTypeKey,
    );
    if (!ticketType) return;

    const ticketTypes = formContext.getValues('tickets.ticketTypes') ?? [];
    formContext.setValue('tickets.ticketTypes', [...ticketTypes, ticketType]);
  };

  const updateActivity = formContext.handleSubmit(
    async (data) => {
      if (!activityKey) return;

      const res = await dispatch(updateActivityThunk({ activityKey, ...data }));

      if (res.meta.requestStatus === 'fulfilled') {
        const cohosts =
          formContext
            .getValues('coHosting.cohosts')
            ?.map(cohostRequestMapper) ?? null;

        formContext.reset({ ...data, coHosting: { cohosts } });
        return;
      }

      const updateActivityError = getUpdateActivityError(res.payload);
      if (updateActivityError?.code === 0) {
        putBackRemovedTicketType(updateActivityError.metadata.ticketTypeKey);
      }
    },
    (err) => {
      Logger.warning('updateEvent Validation', {
        err: flattenFieldErrorsObject(err),
      });

      formContext.setShowErrorSnackbar(true);
    },
  );

  return (
    <Button
      isLoading={isLoading}
      onClick={updateActivity}
      variant="primary"
      tx="button.activity.update"
    />
  );
}

function cohostRequestMapper(cohost: CreateCoHostRequest): CreateCoHostRequest {
  const cohostRequestStatus =
    cohost.cohostRequestStatus === 'not-sent'
      ? 'pending'
      : cohost.cohostRequestStatus;

  return { ...cohost, cohostRequestStatus };
}

function PublishEventButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formContext = React.useContext(ActivityFormContext);

  const isLoading = useSelector(CreateActivitySelector.selectIsLoading);

  const createActivity = formContext.handleSubmit(
    async (data) => {
      const res = await dispatch(createActivityThunk(data));

      if (res.payload && 'activityKey' in res.payload) {
        navigate(`/activities/${res.payload.activityKey}/description`);
      }

      const publishEventError = getPublishEventError(res.payload);
      if (publishEventError?.code === 1) {
        formContext.setShowErrorSnackbar(true);
      }
    },
    (err) => {
      Logger.warning('createEvent Validation', {
        err: flattenFieldErrorsObject(err),
      });

      formContext.setShowErrorSnackbar(true);
    },
  );

  return (
    <Button
      isLoading={isLoading}
      onClick={createActivity}
      variant="primary"
      tx="button.activity.publish-event"
    />
  );
}

function renderErrorKey(options: TxProps, index: number) {
  return (
    <Styled.ErrorSnackbarListItem key={`event-snackbar-error-key-${index}`}>
      <Text
        variant="bodyMd"
        tx={options.tx}
        text={options.text}
        txArgs={options.txArgs}
      />
    </Styled.ErrorSnackbarListItem>
  );
}

function getMissingMembershipTypeErrorListItems(
  ticketTypes: TicketType[] | null,
  membershipTypeKeys: string[],
) {
  if (!ticketTypes?.length) {
    return [];
  }

  const errors: TxProps[] = [];

  ticketTypes.forEach((ticketType) => {
    const membershipTypeDiscount = ticketType.membershipTypeDiscounts ?? [];
    const requiredMembershipTypes =
      ticketType.requiredMembershipTypes?.membershipApplicationRequirements ??
      [];

    const items = createUniqueArray(
      [...membershipTypeDiscount, ...requiredMembershipTypes],
      'membershipTypeKey',
    );

    items.forEach((item) => {
      if (!membershipTypeKeys.includes(item.membershipTypeKey)) return;

      errors.push({
        tx: 'errors.missing-membership-type',
        txArgs: {
          membershipTypeName: item.name,
          ticketTypeName: ticketType.name,
        },
      });
    });
  });

  return errors;
}

function getValidationErrorListItems(keys: string[]): TxProps[] {
  return keys.map((key): TxProps => {
    switch (key) {
      case 'description':
        return { tx: 'errors.activity-form.description' };

      case 'media':
        return { tx: 'errors.activity-form.media' };

      case 'participants':
        return { tx: 'errors.activity-form.participants' };

      case 'tickets':
        return { tx: 'errors.activity-form.tickets' };

      case 'requestMoreInfo':
        return { tx: 'errors.activity-form.requested-info' };

      case 'coHosting':
        return { tx: 'errors.activity-form.co-hosting' };

      default:
        return { tx: 'errors.activity-form.addons' };
    }
  });
}

function getUpdateActivityError(
  payload?:
    | { departmentKey: string; activityKey: string }
    | GeneralApiProblem<UpdateActivityErrorResponse>,
): RemoveTicketError | null {
  if (!payload) return null;
  if (!('message' in payload)) return null;
  if (!isObject(payload.message)) return null;
  if (payload.message.code !== 0) return null;

  return payload.message;
}

function getPublishEventError(
  payload?:
    | (CreateActivityResponse & { departmentKey: string })
    | GeneralApiProblem<CreateActivityErrorResponse>,
): MembershipTypeDeletedError | null {
  if (!payload) return null;
  if (!('message' in payload)) return null;
  if (!isObject(payload.message)) return null;
  if (payload.message.code !== 1) return null;

  return payload.message;
}
