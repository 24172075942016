import { Language } from '../utils';
import { ImageData } from './image';

export const accountRoles = ['admin', 'member'] as const;
export type AccountRole = (typeof accountRoles)[number];

interface StudentAppWorkspace {
  type: 'student';
}

interface CompanyDashboardWorkspace {
  type: 'company';

  companyKey: string;
  role: AccountRole;
  lastUsedAt: number;
  name: string;
  logo: ImageData;
}

interface StudentDashboardWorkspace {
  type: 'department';

  departmentKey: string;
  role: AccountRole;
  lastUsedAt: number;
  name: string;
  logo: ImageData | null;
  config: (Record<string, any> & { length?: never }) | null;
}

interface AdminDashboardWorkspace {
  type: 'admin';

  role: AccountRole;
}

export type Workspace =
  | StudentAppWorkspace
  | CompanyDashboardWorkspace
  | StudentDashboardWorkspace
  | AdminDashboardWorkspace;

export type SwitchableWorkspace =
  | CompanyDashboardWorkspace
  | StudentDashboardWorkspace
  | AdminDashboardWorkspace;

export interface Account {
  userKey: string;

  profilePicture: ImageData | null;

  createdAt: number;
  firstName: string;
  lastName: string;
  email: string;
  language: Language;

  workspaces: Workspace[];
}

export type UpdateWorkspace =
  | {
      type: StudentDashboardWorkspace['type'];
      departmentKey: string;
    }
  | {
      type: CompanyDashboardWorkspace['type'];
      companyKey: string;
    }
  | {
      type: AdminDashboardWorkspace['type'];
    };
