import {
  Box,
  Button,
  Confirm,
  ContentContainer,
  ContentSidebar,
  ContentSidebarContentContainer,
  ContentSidebarFooterContainer,
  EmptyState,
  IconButton,
  InnerContentContainer,
  InnerPageContainer,
  List,
  ListItem,
  NavigateWithQuery,
  ResponsiveBox,
  SearchInput,
  SolidIconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  createUniqueArray,
  getUID,
  useConfirm,
} from '@orbiapp/components';
import React from 'react';

import {
  useMembershipSearch,
  useSuggestedMembershipApplicationRequirements,
} from '../../../../../helpers';
import {
  MembershipApplicationRequirement,
  PartialMembershipType,
} from '../../../../../models';
import {
  DeleteRequirementSelector,
  MembershipTypeSelector,
  createMembershipApplicationRequirementsThunk,
  deleteRequirementThunk,
  getSuggestedMembershipsThunk,
  searchActions,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { CombinatonTypeSettingsCard } from '../components';

const RequiredMembershipsContext = React.createContext({
  sidebarIsOpen: false,
  openSidebar: () => {},
  closeSidebar: () => {},
});

function RequiredMembershipsProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [sidebarIsOpen, setSidebarIsOpen] = React.useState(false);

  const openSidebar = () => {
    setSidebarIsOpen(true);
  };

  const closeSidebar = () => {
    setSidebarIsOpen(false);
  };

  const value = {
    sidebarIsOpen,
    openSidebar,
    closeSidebar,
  };

  return (
    <RequiredMembershipsContext.Provider value={value}>
      {children}
    </RequiredMembershipsContext.Provider>
  );
}

function AddRequiredMembershipsEmptyState() {
  const { sidebarIsOpen, openSidebar } = React.useContext(
    RequiredMembershipsContext,
  );

  return (
    <EmptyState
      buttonOnClick={openSidebar}
      buttonTx="label.memberships.required-memberships.empty-state.button"
      disabled={sidebarIsOpen}
      titleTx="label.memberships.required-memberships.empty-state.label"
    />
  );
}

function RequiredMembershipsContent() {
  const requiredMembershipTypes = useSelector(
    MembershipTypeSelector.selectMembershipApplicationRequirements,
  );

  const deleteRequirementStatus = useSelector(
    DeleteRequirementSelector.selectStatus,
  );

  const dispatch = useDispatch();

  const { confirmValue, closeConfirm, isOpen, openConfirm } =
    useConfirm<string>();

  if (!requiredMembershipTypes?.length) {
    return <AddRequiredMembershipsEmptyState />;
  }

  const deleteRequirement = async () => {
    if (!confirmValue) return;

    await dispatch(deleteRequirementThunk(confirmValue));

    closeConfirm();
  };

  const renderApplicationRequirementRow = (
    membershipApplicationRequirement: MembershipApplicationRequirement,
  ) => {
    const stageApplicationRequirementForRemove = () => {
      openConfirm(
        membershipApplicationRequirement.membershipApplicationRequirementKey,
      );
    };

    return (
      <TableRow
        key={`required-membership-${membershipApplicationRequirement.membershipTypeKey}`}
      >
        <TableCell text={membershipApplicationRequirement.name} />
        <TableCell text={membershipApplicationRequirement.departmentName} />
        <TableCell width={40} hoverCell fixedRight>
          <IconButton
            icon="trash-outline"
            onClick={stageApplicationRequirementForRemove}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <React.Fragment>
      <CombinatonTypeSettingsCard />

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead tx="label.memberships.create-membership.settings.membership-name.label" />
            <TableHead tx="label.memberships.create-membership.settings.department-name.label" />
            <TableHead fixedRight />
          </TableRow>
        </TableHeader>
        <TableBody>
          {requiredMembershipTypes.map(renderApplicationRequirementRow)}
        </TableBody>
      </Table>

      <Confirm
        cancelTx="prompt.delete-required-membership.cancel"
        confirmTx="prompt.delete-required-membership.confirm"
        isLoading={deleteRequirementStatus === 'pending'}
        isOpen={isOpen}
        messageTx="prompt.delete-required-membership.message"
        onCancel={closeConfirm}
        onConfirm={deleteRequirement}
        titleTx="prompt.delete-required-membership.title"
      />
    </React.Fragment>
  );
}

function RequiredMembershipsFormContent(props: {
  onSave: (data: MembershipApplicationRequirement[]) => void;
  alreadySelectedMembershipTypeKeys: string[];
}) {
  const { alreadySelectedMembershipTypeKeys, onSave } = props;

  const dispatch = useDispatch();

  const { search, searchResult, searchString, setSearchString } =
    useMembershipSearch(['invite_only', 'needs_approval', 'standard']);

  const [selectedMemberships, setSelectedMemberships] = React.useState<
    MembershipApplicationRequirement[]
  >([]);

  const suggestedMemberships = useSuggestedMembershipApplicationRequirements(
    5,
    [
      ...alreadySelectedMembershipTypeKeys,
      ...selectedMemberships.map((item) => item.membershipTypeKey),
    ],
  );

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    setSearchString(e.target.value);
    search(e.target.value);
  };

  const renderSearchResultMembership = (
    partialmembershipType: PartialMembershipType,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) => [
        ...prev,
        {
          departmentName: partialmembershipType.departmentName,
          logoUrl: partialmembershipType.logo.thumbnail64.url,
          membershipTypeKey: partialmembershipType.membershipTypeKey,
          name: partialmembershipType.name,
          membershipApplicationRequirementKey: getUID(),
        },
      ]);
    };

    return (
      <ListItem
        key={`search-result-membership-${partialmembershipType.membershipTypeKey}`}
        avatarSrc={partialmembershipType.logo.thumbnail64.url}
        onClick={addMembershipToRequiredMemberships}
        subtitle={partialmembershipType.departmentName}
        title={partialmembershipType.name}
      />
    );
  };

  const renderSuggestedMembership = (
    suggestedMembership: MembershipApplicationRequirement,
  ) => {
    const addMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) => [
        ...prev,
        {
          departmentName: suggestedMembership.departmentName,
          logoUrl: suggestedMembership.logoUrl,
          membershipTypeKey: suggestedMembership.membershipTypeKey,
          name: suggestedMembership.name,
          membershipApplicationRequirementKey: getUID(),
        },
      ]);
    };

    return (
      <ListItem
        key={`suggested-membership-${suggestedMembership.membershipTypeKey}`}
        avatarSrc={suggestedMembership.logoUrl}
        onClick={addMembershipToRequiredMemberships}
        subtitle={suggestedMembership.departmentName}
        title={suggestedMembership.name}
      />
    );
  };

  const renderSelectedMembership = (
    selectedMembership: MembershipApplicationRequirement,
  ) => {
    const removeMembershipToRequiredMemberships = () => {
      setSelectedMemberships((prev) =>
        prev.filter(
          (item) =>
            item.membershipTypeKey !== selectedMembership.membershipTypeKey,
        ),
      );
    };

    return (
      <ListItem
        icon="check-circle-solid"
        iconColor="listItemIcon"
        key={`selected-membership-${selectedMembership.membershipTypeKey}`}
        avatarSrc={selectedMembership.logoUrl}
        onClick={removeMembershipToRequiredMemberships}
        subtitle={selectedMembership.departmentName}
        title={selectedMembership.name}
      />
    );
  };

  const handleSave = () => {
    onSave(selectedMemberships);
    setSelectedMemberships([]);
    setSearchString('');
    dispatch(searchActions.clearMembershipTypes());
  };

  React.useEffect(() => {
    dispatch(
      getSuggestedMembershipsThunk({
        includeHidden: false,
      }),
    );

    return () => {
      dispatch(searchActions.clearMembershipTypes());
    };
  }, [dispatch]);

  const selectedMembershipTypeKeysSet = new Set([
    ...selectedMemberships.map((item) => item.membershipTypeKey),
    ...alreadySelectedMembershipTypeKeys,
  ]);

  const uniqueSearchResults = createUniqueArray(
    searchResult,
    'membershipTypeKey',
  ).filter(
    (item) => !selectedMembershipTypeKeysSet.has(item.membershipTypeKey),
  );

  const shouldRenderSearchResults = uniqueSearchResults.length > 0;
  const shouldRenderSelectedMemberships = selectedMemberships.length > 0;
  const shouldRenderSuggestedMemberships =
    uniqueSearchResults.length === 0 &&
    Array.isArray(suggestedMemberships) &&
    suggestedMemberships.length > 0;

  return (
    <React.Fragment>
      <Box p={32} flex flexDirection="column" gap={32}>
        <Text
          tx="label.create-activity.tickets.add-required-memberships"
          variant="bodyLgBold"
        />

        <SearchInput
          onChange={handleSearchChange}
          placeholderTx="placeholder.search-for-memberships"
          value={searchString}
        />
      </Box>

      <ContentSidebarContentContainer pt={0}>
        {searchString.length > 0 && !shouldRenderSearchResults && (
          <Text
            textAlign="center"
            tx="placeholder.no-search-results"
            variant="bodySm"
          />
        )}

        {shouldRenderSearchResults && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.search-results"
            />

            <List>{uniqueSearchResults.map(renderSearchResultMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSuggestedMemberships && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.suggested-memberships"
            />

            <List>{suggestedMemberships.map(renderSuggestedMembership)}</List>
          </React.Fragment>
        )}

        {shouldRenderSelectedMemberships && (
          <React.Fragment>
            <Text
              variant="bodyMdBold"
              tx="label.memberships.create-membership.settings.required-memberships"
            />

            <List>{selectedMemberships.map(renderSelectedMembership)}</List>
          </React.Fragment>
        )}
      </ContentSidebarContentContainer>

      <ContentSidebarFooterContainer flexJustify="end">
        <Button tx="button.save" onClick={handleSave} variant="primary" />
      </ContentSidebarFooterContainer>
    </React.Fragment>
  );
}

function RequiredMembershipsSidebar() {
  const { closeSidebar, sidebarIsOpen } = React.useContext(
    RequiredMembershipsContext,
  );
  const alreadyUsedKeys = useSelector(
    MembershipTypeSelector.selectMembershipApplicationRequirementKeys,
  );

  const dispatch = useDispatch();

  const saveMembershipApplicationRequirements = async (
    data: MembershipApplicationRequirement[],
  ) => {
    await dispatch(createMembershipApplicationRequirementsThunk(data));

    closeSidebar();
  };

  return (
    <ContentSidebar width={470} isOpen={sidebarIsOpen} onClose={closeSidebar}>
      <RequiredMembershipsFormContent
        alreadySelectedMembershipTypeKeys={alreadyUsedKeys ?? []}
        onSave={saveMembershipApplicationRequirements}
      />
    </ContentSidebar>
  );
}

function AddRequiredMembershipButton() {
  const { sidebarIsOpen, openSidebar } = React.useContext(
    RequiredMembershipsContext,
  );

  const requiredMembershipTypes = useSelector(
    MembershipTypeSelector.selectMembershipApplicationRequirements,
  );

  if (!requiredMembershipTypes?.length) {
    return null;
  }

  return (
    <ResponsiveBox
      xs={
        <Button
          disabled={sidebarIsOpen}
          onClick={openSidebar}
          tx="label.memberships.required-memberships.empty-state.button"
          variant="secondary"
        />
      }
    >
      <Tooltip
        placement="left"
        titleTx="label.memberships.required-memberships.empty-state.button"
      >
        <SolidIconButton
          disabled={sidebarIsOpen}
          icon="plus-circle-outline"
          onClick={openSidebar}
        />
      </Tooltip>
    </ResponsiveBox>
  );
}

export function ViewRequiredMemberships() {
  const isIntegrated = useSelector(MembershipTypeSelector.selectIsIntegrated);

  if (isIntegrated) {
    return <NavigateWithQuery to="overview" />;
  }

  return (
    <RequiredMembershipsProvider>
      <InnerPageContainer>
        <ContentContainer>
          <InnerContentContainer>
            <Box flex flexJustify="between" gap={16}>
              <Text
                color="pageTitle"
                as="h1"
                tx="label.memberships.view-membership.tabs.required-memberships"
                variant="titleMd"
              />

              <AddRequiredMembershipButton />
            </Box>

            <RequiredMembershipsContent />
          </InnerContentContainer>
        </ContentContainer>

        <RequiredMembershipsSidebar />
      </InnerPageContainer>
    </RequiredMembershipsProvider>
  );
}
