import {
  Account,
  GeneralApiResult,
  GeneralApiResultWithData,
  UpdateWorkspace,
} from '@orbiapp/components';

import {
  CreateAccountByInvitationParams,
  UpdateAccountByInvitationParams,
  UpdateAccountForm,
} from '../../../../../models';
import { Logger } from '../../../../logger';
import { apisauce, apisauceAuth } from '../../../api';
import { CUSTOM_TIMEOUTS } from '../../../api.constants';
import { getGeneralApiProblem } from '../../../api.utils';

export async function getAuthAccount(): Promise<
  GeneralApiResultWithData<Account>
> {
  const res = await apisauceAuth.get<Account, any>('/v2/account', undefined, {
    timeout: CUSTOM_TIMEOUTS.getAccount,
  });

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getAuthAccount', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function getAccount(): Promise<GeneralApiResultWithData<Account>> {
  const res = await apisauce.get<Account, any>('/v2/account', undefined, {
    timeout: CUSTOM_TIMEOUTS.getAccount,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    return { kind: 'ok', data: res.data };
  } catch (err) {
    Logger.error('getAccount', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function createAccountByInvitation(
  createAccountByInvitationParams: CreateAccountByInvitationParams,
): Promise<GeneralApiResultWithData<string>> {
  const res = await apisauce.post<string, any>('/v2/account/by-invitation', {
    departmentInvitationKey:
      createAccountByInvitationParams.departmentInvitationKey,
    password: createAccountByInvitationParams.password,
    firstName: createAccountByInvitationParams.firstName,
    lastName: createAccountByInvitationParams.lastName,
    language: createAccountByInvitationParams.language,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  try {
    if (!res.data) {
      throw res;
    }

    const data = res.data;

    return { kind: 'ok', data };
  } catch (err) {
    Logger.error('createAccountByInvitation', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return { kind: 'bad-data' };
  }
}

export async function updateAccountByInvitation(
  updateAccountByInvitationParams: UpdateAccountByInvitationParams,
): Promise<GeneralApiResult> {
  const res = await apisauce.patch<string, any>('/v2/account/by-invitation', {
    departmentInvitationKey:
      updateAccountByInvitationParams.departmentInvitationKey,
  });

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export async function updateAccount(
  updateAccountParams: UpdateAccountForm,
): Promise<GeneralApiResult> {
  const res = await apisauce.patch<string, any>(
    '/v2/account',
    updateAccountParams,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}

export async function updateWorkspace(
  updateWorkspaceParams: UpdateWorkspace,
): Promise<GeneralApiResult> {
  const res = await apisauce.patch<string, any>(
    '/v2/account/workspace',
    updateWorkspaceParams,
  );

  if (!res.ok) {
    return getGeneralApiProblem(res);
  }

  return { kind: 'ok' };
}
