import appQrCode from './app-qr-code.svg';
import inAssociationWithOrbi from './in-association-with-orbi.png';
import orbiLogoGradientPng from './orbi-logo-gradient.png';
import orbiLogoOrange from './orbi-logo-orange.svg';
import postsImprovedModalImage from './posts-improved.png';
import postsInMembershipModalImage from './posts-in-membership.png';
import redeemOffer from './redeem-offer.png';
import cash from './referral-step-cash.png';
import inbound from './referral-step-inbound.png';
import outbound from './referral-step-outbound.png';

export const assets = {
  appQrCode,
  orbiLogoGradientPng,
  orbiLogoOrange,
  referrals: {
    cash,
    inbound,
    outbound,
  },
  inAssociationWithOrbi,
  redeemOffer,

  postsImprovedModalImage,
  postsInMembershipModalImage,
};
