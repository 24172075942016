import { BRANCH_IO_KEY, DEEP_LINK_BASE_URL } from '../../config';
import { Logger } from '../logger';

type CreateLinkParams =
  | { type: 'activity'; activityKey: string }
  | { type: 'ticketScanner'; activityKey: string; sessionId: string }
  | { type: 'membership'; membershipTypeKey: string }
  | { type: 'departmentOffers'; departmentKey: string };

function getLink(params: CreateLinkParams) {
  switch (params.type) {
    case 'activity':
      return `${DEEP_LINK_BASE_URL}/activityDetail/${params.activityKey}`;

    case 'ticketScanner':
      return `${DEEP_LINK_BASE_URL}/ticketScanner/${params.activityKey}/${params.sessionId}`;

    case 'membership':
      return `${DEEP_LINK_BASE_URL}/membership/${params.membershipTypeKey}`;

    case 'departmentOffers':
      return `${DEEP_LINK_BASE_URL}/offers/${params.departmentKey}/department`;
  }
}

function createLink(params: CreateLinkParams): Promise<string> {
  return new Promise((resolve, reject) => {
    window.branch.link(
      {
        data: {
          $canonical_url: getLink(params),
        },
      },
      (err, link) => {
        if (err) {
          reject(err);
        } else {
          resolve(link);
        }
      },
    );
  });
}

window.branch.init(BRANCH_IO_KEY, (err, data) => {
  if (err) {
    Logger.error('Branch IO init error', { err, data });
  }
});

export const branchIO = { createLink };
