import * as Sentry from '@sentry/react';
import React from 'react';

import { ENV } from '../config';
import {
  AccountSelector,
  AuthStateSelector,
  DepartmentSelector,
  useSelector,
} from '../store';

export function useTracking() {
  const isSuperAdmin = useSelector(AuthStateSelector.selectIsSuperAdmin);
  const fullName = useSelector(AccountSelector.selectFullName);
  const email = useSelector(AccountSelector.selectEmail);
  const userKey = useSelector(AccountSelector.selectUserKey);
  const departmentName = useSelector(DepartmentSelector.selectName);
  const isEmulating = useSelector(AuthStateSelector.selectIsEmulating);
  const role = useSelector(AccountSelector.selectRole);
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);

  React.useEffect(() => {
    if (ENV !== 'production' || isSuperAdmin) {
      return;
    }

    const userAttributes: Record<string, any> = userKey
      ? {
          departmentName,
          email,
          fullName,
          isEmulating,
          role,
          departmentKey,
        }
      : { isEmulating: false };

    Sentry.setTags(userAttributes);
  }, [
    userKey,
    email,
    fullName,
    departmentName,
    isEmulating,
    isSuperAdmin,
    role,
    departmentKey,
  ]);
}
