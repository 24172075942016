import { Pagination, fromScu, getUID } from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  Activity,
  ActivityCategory,
  ActivityDiscountStatsItem,
  ActivityDraft,
  ActivityForm,
  ActivityListItem,
  ActivityOrderByKey,
  ActivityRecord,
  ActivityUser,
  CoHostRequestListItem,
  CreateActivityErrorResponse,
  CreateActivityForm,
  CreateActivityResponse,
  DescriptionForm,
  DownloadableFileType,
  FreeTextListItem,
  FreeTextListItemsOrderByKey,
  QrScanToken,
  RefundTicketErrorResponse,
  RequestedInfoStats,
  ShrodingersTicket,
  ShrodingersTicketErrorResponse,
  TicketDetails,
  TicketHistoryListItem,
  TicketListItem,
  TicketListItemOrderByKey,
  TicketType,
  UpdateActivityErrorResponse,
  UpdateActivityForm,
} from '../../models';
import {
  OrbiApi,
  getPageAndNextPage,
  isClientProblem,
  v1,
  v2,
} from '../../services';
import { branchIO } from '../../services/branch';
import { setAlert } from '../global-ui-state';
import { ThunkApiConfig } from '../store.types';
import {
  activityListItemAdapter,
  freetextListItemsAdapter,
  ticketsAdapter,
} from './activities.adapter';
import { createQrScanTokenUrl } from './activities.utils';

export const createActivityDraftThunk = createAsyncThunk<
  ActivityDraft,
  ActivityForm,
  ThunkApiConfig
>('activity/create-activity-draft', async (createActivityForm, thunkAPI) => {
  const state = thunkAPI.getState();

  const draft: ActivityDraft = {
    lastUpdated: Date.now(),
    data: createActivityForm,
    key: state.activity.activityDraft.data?.key || getUID(),
  };

  const res = await OrbiApi.call(
    v1.activities.draft.createActivityDraft,
    draft,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('save-activity-draft:success'));
    return draft;
  }

  thunkAPI.dispatch(setAlert('save-activity-draft:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getActivityDraftsThunk = createAsyncThunk<
  ActivityDraft[],
  undefined,
  ThunkApiConfig
>('activity/get-activity-drafts', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.draft.getActivityDrafts,
    undefined,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const deleteActivityDraftThunk = createAsyncThunk<
  string,
  { suppressAlert?: boolean; draftKey: string },
  ThunkApiConfig
>(
  'activity/delete-activity-draft',
  async (deleteActivityDraftParams, thunkAPI) => {
    const { draftKey, suppressAlert } = deleteActivityDraftParams;

    const res = await OrbiApi.call(
      v1.activities.draft.deleteActivityDraft,
      draftKey,
    );

    if (res.kind === 'ok') {
      if (!suppressAlert) {
        thunkAPI.dispatch(setAlert('delete-activity-draft:success'));
      }

      return draftKey;
    }

    if (!suppressAlert) {
      thunkAPI.dispatch(setAlert('delete-activity-draft:error'));
    }
    return thunkAPI.rejectWithValue(res);
  },
);

export const getActivityDraftThunk = createAsyncThunk<
  ActivityDraft,
  string,
  ThunkApiConfig
>('activity/get-activity-draft', async (draftKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.draft.getActivityDraft,
    draftKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createActivityThunk = createAsyncThunk<
  CreateActivityResponse & { departmentKey: string },
  CreateActivityForm,
  ThunkApiConfig<CreateActivityErrorResponse>
>('activity/create', async (createActivityForm, thunkAPI) => {
  const state = thunkAPI.getState();
  const departmentKey = state.department.department.data?.departmentKey;
  const draftKey = state.activity.activityDraft.data?.key;

  if (!departmentKey) {
    return thunkAPI.rejectWithValue({ kind: 'forbidden' });
  }

  const res = await OrbiApi.call(
    v1.activities.createActivity,
    createActivityForm,
  );

  if (res.kind === 'ok') {
    if (draftKey) {
      thunkAPI.dispatch(
        deleteActivityDraftThunk({ draftKey, suppressAlert: true }),
      );
    }

    thunkAPI.dispatch(setAlert('create-activity:success'));
    return {
      activityKey: res.data.activityKey,
      coverImage: res.data.coverImage,
      departmentKey,
    };
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('create-activity:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('create-activity:error'));

    return thunkAPI.rejectWithValue(res);
  }

  if (res.message.metadata) {
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(setAlert('create-activity:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getActivityThunk = createAsyncThunk<
  Activity,
  string,
  ThunkApiConfig
>('activity/get-one', async (activityKey, thunkAPI) => {
  const state = thunkAPI.getState();
  const departmentSettings = state.department.department.data;

  const res = await OrbiApi.call(v1.activities.getActivity, {
    activityKey,
    enableTags: departmentSettings?.enableTags ?? false,
  });

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getActivitiesThunk = createAsyncThunk<
  ActivityListItem[],
  Pagination<ActivityOrderByKey>,
  ThunkApiConfig
>('activity/get-all', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();

  const [currentPage, nextPage] = await getPageAndNextPage(
    activityListItemAdapter
      .getSelectors()
      .selectAll(state.activity.activities.data),
    v2.activities.listed.getActivities,
    { pagination },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const updateActivityThunk = createAsyncThunk<
  { departmentKey: string; activityKey: string },
  UpdateActivityForm,
  ThunkApiConfig<UpdateActivityErrorResponse>
>('activity/update', async (updateActivityForm, thunkAPI) => {
  const state = thunkAPI.getState();
  const departmentKey = state.department.department.data?.departmentKey;

  if (!departmentKey) {
    thunkAPI.dispatch(setAlert('update-activity:error'));
    return thunkAPI.rejectWithValue({ kind: 'forbidden' });
  }

  const res = await OrbiApi.call(
    v2.activities.updateActivity,
    updateActivityForm,
  );

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-activity:success'));
    return { departmentKey, activityKey: updateActivityForm.activityKey };
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('update-activity:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('update-activity:error'));
    return thunkAPI.rejectWithValue(res);
  }

  const findTicketType = (ticketType: TicketType) =>
    typeof res.message === 'object' &&
    res.message.metadata?.ticketTypeKey === ticketType.ticketTypeKey;

  const ticketType =
    updateActivityForm.tickets.ticketTypes?.find(findTicketType) ||
    state.activity.activity.data?.ticketTypes?.find(findTicketType);

  if (!ticketType) {
    thunkAPI.dispatch(setAlert('update-activity:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message.code !== 'number') {
    thunkAPI.dispatch(setAlert('update-activity:error'));
    return thunkAPI.rejectWithValue(res);
  }

  switch (res.message.code) {
    case 0:
      thunkAPI.dispatch(
        setAlert('update-activity-0:error', {
          ticketTypeName: ticketType.name,
        }),
      );
      break;
    case 1:
      thunkAPI.dispatch(
        setAlert('update-activity-1:error', {
          ticketTypeName: ticketType.name,
        }),
      );
      break;
  }

  return thunkAPI.rejectWithValue(res);
});

export const unpublishActivityThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>('activity/unpublish-activity', async (activityKey, thunkAPI) => {
  const res = await OrbiApi.call(v1.activities.unpublishActivity, activityKey);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('unpublish-activity:success'));
    return;
  }

  thunkAPI.dispatch(thunkAPI.dispatch(setAlert('unpublish-activity:error')));
  return thunkAPI.rejectWithValue(res);
});

export const createDynamicActivityLinkThunk = createAsyncThunk<
  string,
  string,
  ThunkApiConfig
>('activity/create-dynamic-activity-link', async (activityKey, thunkAPI) => {
  try {
    const link = await branchIO.createLink({
      type: 'activity',
      activityKey,
    });

    return link;
  } catch (err) {
    return thunkAPI.rejectWithValue({ kind: 'rejected' });
  }
});

export const getActivityRecordsThunk = createAsyncThunk<
  ActivityRecord[],
  undefined,
  ThunkApiConfig
>('activity/get-activity-records', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.activities.getActivityRecords, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getCoHostingRequestsThunk = createAsyncThunk<
  CoHostRequestListItem[],
  undefined,
  ThunkApiConfig
>('activity/co-hosting/requests', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.coHostRequests.getCoHostingRequests,
    undefined,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const updateCohostingRequestThunk = createAsyncThunk<
  void,
  { cohostRequestKey: string; status: 'accepted' | 'rejected' },
  ThunkApiConfig
>(
  'activity/co-hosting/update-request',
  async (updateCoHostingRequestParams, thunkAPI) => {
    const { cohostRequestKey, status } = updateCoHostingRequestParams;
    const res = await OrbiApi.call(
      v1.activities.coHostRequests.updateCoHostingRequest,
      { cohostRequestKey, status },
    );

    const isAccepted = status === 'accepted';

    if (res.kind === 'ok') {
      thunkAPI.dispatch(
        setAlert(
          isAccepted
            ? 'accept-cohosting-request:success'
            : 'reject-cohosting-request:success',
        ),
      );
      return;
    }

    thunkAPI.dispatch(
      setAlert(
        isAccepted
          ? 'accept-cohosting-request:error'
          : 'reject-cohosting-request:error',
      ),
    );
    return thunkAPI.rejectWithValue(res);
  },
);

export const getQrScanTokensThunk = createAsyncThunk<
  QrScanToken[],
  undefined,
  ThunkApiConfig
>('activity/qr-scan-tokens/get-qr-scan-tokens', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const activityKey = state.activity.activity.data?.activityKey;

  if (!activityKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.activities.qrScanTokens.getQrScanTokens, {
    activityKey,
  });

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const createQrScanTokenThunk = createAsyncThunk<
  QrScanToken,
  undefined,
  ThunkApiConfig
>('activity/qr-scan-tokens/create-qr-scan-token', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const activityKey = state.activity.activity.data?.activityKey;

  if (!activityKey) {
    thunkAPI.dispatch(setAlert('create-qr-scan-token:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const sessionId = getUID();
  const shortLink = await createQrScanTokenUrl(activityKey, sessionId);

  if (!shortLink) {
    thunkAPI.dispatch(setAlert('create-qr-scan-token:error'));
    return thunkAPI.rejectWithValue({ kind: 'rejected' });
  }

  const res = await OrbiApi.call(v1.activities.qrScanTokens.createQrScanToken, {
    activityKey,
    createQrScanToken: { sessionId, url: shortLink },
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('create-qr-scan-token:success'));
    return {
      createdAt: Date.now(),
      qrScanTokenKey: res.data,
      url: shortLink,
    };
  }

  thunkAPI.dispatch(setAlert('create-qr-scan-token:error'));
  return thunkAPI.rejectWithValue(res);
});

export const deleteQrScanTokenThunk = createAsyncThunk<
  void,
  string,
  ThunkApiConfig
>(
  'activity/qr-scan-tokens/delete-qr-scan-token',
  async (qrScanTokenKey, thunkAPI) => {
    const state = thunkAPI.getState();
    const activityKey = state.activity.activity.data?.activityKey;

    if (!activityKey) {
      thunkAPI.dispatch(setAlert('delete-qr-scan-token:error'));
      return thunkAPI.rejectWithValue({ kind: 'bad-request' });
    }

    const res = await OrbiApi.call(
      v1.activities.qrScanTokens.deleteQrScanToken,
      { activityKey, qrScanTokenKey },
    );

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('delete-qr-scan-token:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('delete-qr-scan-token:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const getFreeTextListItemsThunk = createAsyncThunk<
  FreeTextListItem[],
  Pagination<FreeTextListItemsOrderByKey>,
  ThunkApiConfig
>('activity/free-text-list-items-paginated', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();
  const activityKey = state.activity.activity.data?.activityKey;
  if (!activityKey) return thunkAPI.rejectWithValue({ kind: 'bad-request' });

  const [currentPage, nextPage] = await getPageAndNextPage(
    freetextListItemsAdapter
      .getSelectors()
      .selectAll(state.activity.freetextListItems.data),
    v2.activities.stats.getFreeTextListItems,
    {
      activityKey,
      pagination,
    },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const getRequestedInfoStatsThunk = createAsyncThunk<
  RequestedInfoStats,
  string,
  ThunkApiConfig
>('activity/get-requested-info-stats', async (activityKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.activities.stats.getRequestedInfoStats,
    activityKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getActivityUsersThunk = createAsyncThunk<
  ActivityUser[],
  string,
  ThunkApiConfig
>('activity/get-activity-users', async (activityKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.activities.stats.getActivityUsers,
    activityKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getActivityDiscountStatsThunk = createAsyncThunk<
  ActivityDiscountStatsItem[],
  string,
  ThunkApiConfig
>('activity/get-activity-discount-stats', async (activityKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.activities.stats.getActivityDiscountStats,
    activityKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getTicketListItemsThunk = createAsyncThunk<
  TicketListItem[],
  Pagination<TicketListItemOrderByKey>,
  ThunkApiConfig
>('activity/tickets', async (pagination, thunkAPI) => {
  const state = thunkAPI.getState();
  const activityKey = state.activity.activity.data?.activityKey;

  if (!activityKey) return thunkAPI.rejectWithValue({ kind: 'bad-request' });

  const search = state.activity.ticketListItems.search;
  if (search) {
    const res = await OrbiApi.call(v1.activities.tickets.getTicketListItems, {
      activityKey,
      pagination,
      search,
    });

    if (res.kind === 'ok') {
      return res.data;
    }

    return thunkAPI.rejectWithValue(res);
  }

  const [currentPage, nextPage] = await getPageAndNextPage(
    ticketsAdapter
      .getSelectors()
      .selectAll(state.activity.ticketListItems.data),
    v1.activities.tickets.getTicketListItems,
    { activityKey, pagination, search: undefined },
  );

  if (currentPage.kind !== 'ok') return thunkAPI.rejectWithValue(currentPage);
  if (nextPage.kind !== 'ok') return thunkAPI.rejectWithValue(nextPage);

  return [...currentPage.data, ...nextPage.data];
});

export const getTicketsHistoryThunk = createAsyncThunk<
  TicketHistoryListItem[],
  string,
  ThunkApiConfig
>('activity/get-tickets-history', async (activityKey, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.tickets.getTicketsHistory,
    activityKey,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const getActivityUsersDataThunk = createAsyncThunk<
  Blob,
  { activityKey: string; type: DownloadableFileType },
  ThunkApiConfig
>('activity/get-activity-users-data', async (params, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.tickets.getActivityUsersData,
    params,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  thunkAPI.dispatch(setAlert('download-users-data:error'));
  return thunkAPI.rejectWithValue(res);
});

export const refundTicketThunk = createAsyncThunk<
  void,
  { activityKey: string; ticketKey: string },
  ThunkApiConfig<RefundTicketErrorResponse>
>('activity/tickets/refund-ticket', async (refundTicketParams, thunkAPI) => {
  const { activityKey, ticketKey } = refundTicketParams;

  const res = await OrbiApi.call(v1.activities.tickets.refundTicket, {
    activityKey,
    ticketKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('refund-ticket:success'));
    return;
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('refund-ticket:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('refund-ticket:error'));
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(
    setAlert('refund-ticket:limit-exceeded', {
      refundLimit: fromScu(res.message.metadata.refundLimit),
    }),
  );
  return thunkAPI.rejectWithValue(res);
});

export const consumeTicketThunk = createAsyncThunk<
  void,
  { activityKey: string; ticketKey: string },
  ThunkApiConfig
>('activity/tickets/consume-ticket', async (refundTicketParams, thunkAPI) => {
  const { activityKey, ticketKey } = refundTicketParams;

  const res = await OrbiApi.call(v1.activities.tickets.consumeTicket, {
    activityKey,
    ticketKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('consume-ticket:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('consume-ticket:error'));
  return thunkAPI.rejectWithValue(res);
});

export const unconsumeTicketThunk = createAsyncThunk<
  void,
  { activityKey: string; ticketKey: string },
  ThunkApiConfig
>(
  'activity/tickets/undo-ticket-consume',
  async (refundTicketParams, thunkAPI) => {
    const { activityKey, ticketKey } = refundTicketParams;

    const res = await OrbiApi.call(v1.activities.tickets.undoConsumeTicket, {
      activityKey,
      ticketKey,
    });

    if (res.kind === 'ok') {
      thunkAPI.dispatch(setAlert('undo-ticket-consume:success'));
      return;
    }

    thunkAPI.dispatch(setAlert('undo-ticket-consume:error'));
    return thunkAPI.rejectWithValue(res);
  },
);

export const getTicketDetailsThunk = createAsyncThunk<
  TicketDetails,
  undefined,
  ThunkApiConfig
>('activity/tickets/get-ticket-details', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const ticketKey = state.activity.selectedTicketListItem?.ticketKey;
  const activityKey = state.activity.activity.data?.activityKey;

  if (!ticketKey || !activityKey) {
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const ticketListItem = ticketsAdapter
    .getSelectors()
    .selectById(state.activity.ticketListItems.data, ticketKey);

  if (ticketListItem?.details) {
    return ticketListItem.details;
  }

  const res = await OrbiApi.call(v1.activities.tickets.getTicketDetails, {
    activityKey,
    ticketKey,
  });

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const scanTicketThunk = createAsyncThunk<
  ShrodingersTicket,
  { activityKey: string; ticketPurchaseKey: string },
  ThunkApiConfig<ShrodingersTicketErrorResponse>
>('activity/tickets/scan-ticket', async (scanTicketParams, thunkAPI) => {
  const res = await OrbiApi.call(
    v1.activities.tickets.scanTicket,
    scanTicketParams,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  if (!isClientProblem(res)) {
    thunkAPI.dispatch(setAlert('scan-ticket:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (typeof res.message === 'string' || !res.message) {
    thunkAPI.dispatch(setAlert('scan-ticket:error'));
    return thunkAPI.rejectWithValue(res);
  }

  if (res.message.code === 0) {
    thunkAPI.dispatch(setAlert('scan-ticket:not-found'));
    return thunkAPI.rejectWithValue(res);
  }

  if (res.message.code === 1) {
    thunkAPI.dispatch(setAlert('scan-ticket:wrong-event'));
    return thunkAPI.rejectWithValue(res);
  }

  thunkAPI.dispatch(setAlert('scan-ticket:error'));
  return thunkAPI.rejectWithValue(res);
});

export const undoScanTicketThunk = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfig
>('activity/tickets/un-scan-ticket', async (_, thunkAPI) => {
  const state = thunkAPI.getState();

  const activityKey = state.activity.activity.data?.activityKey;
  const ticketPurchaseKey = state.activity.scanTicket.data?.ticketPurchaseKey;

  if (!activityKey || !ticketPurchaseKey) {
    thunkAPI.dispatch(setAlert('undo-scan-ticket:error'));
    return thunkAPI.rejectWithValue({ kind: 'bad-request' });
  }

  const res = await OrbiApi.call(v1.activities.tickets.undoScanTicket, {
    activityKey,
    ticketPurchaseKey,
  });

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('undo-scan-ticket:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('undo-scan-ticket:error'));
  return thunkAPI.rejectWithValue(res);
});

export const getActivityCategoriesThunk = createAsyncThunk<
  ActivityCategory[],
  undefined,
  ThunkApiConfig
>('activity/get-categories', async (_, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.activities.categories.getCategories,
    undefined,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});

export const suggestActivityCategoriesThunk = createAsyncThunk<
  string[],
  Pick<DescriptionForm, 'description' | 'title'>,
  ThunkApiConfig
>('activity/suggest-categories', async (activityCategorySuggest, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.activities.categories.suggestCategories,
    activityCategorySuggest,
  );

  if (res.kind === 'ok') {
    return res.data;
  }

  return thunkAPI.rejectWithValue(res);
});
