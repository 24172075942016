import { NavigateWithQuery } from '@orbiapp/components';
import { Outlet, RouteObject } from 'react-router-dom';

import {
  AuthenticatedRoute,
  FallbackNavigate,
  UnauthenticatedRoute,
} from '../guards';
import { MainOutlet, SignedInOutlet } from '../outlets';
import {
  EmulateUser,
  NotFound,
  ResetPassword,
  SignOut,
  SignUp,
  SignUpFromInvitation,
} from '../pages/shared';
import {
  AccessDenied,
  AccessDeniedStudent,
  AccountSettings,
  Activities,
  CreateActivity,
  CreateActivityAddons,
  CreateActivityCoHosting,
  CreateActivityDescription,
  CreateActivityFromDraft,
  CreateActivityMedia,
  CreateActivityParticipants,
  CreateActivityRequestMoreInfo,
  CreateActivityTickets,
  CreateIntegratedMembershipGeneralInfo,
  CreateIntegratedMembershipSelectService,
  CreateIntegratedMembershipSummary,
  CreateIntegratedMembershipType,
  CreateMembershipGeneralInfo,
  CreateMembershipPeriods,
  CreateMembershipQuestions,
  CreateMembershipSettings,
  CreateMembershipSummary,
  CreateMembershipType,
  CreateOffer,
  Department,
  DuplicateActivity,
  DuplicateOffer,
  EditActivity,
  EmailNotVerified,
  Jobs,
  MembershipInvites,
  Memberships,
  Offers,
  OnBoarded,
  OrbiPay,
  PickScanDevice,
  Posts,
  Questions,
  Referrals,
  ReviewApplications,
  ScanWithCamera,
  ScanWithDevice,
  Settings,
  SwitchWorkspace,
  Team,
  ViewActivity,
  ViewActivityDescription,
  ViewActivityPosts,
  ViewActivityQuestions,
  ViewActivityTickets,
  ViewActivityTicketsHistory,
  ViewMembership,
  ViewMembershipMembers,
  ViewMembershipOverview,
  ViewMembershipPeriods,
  ViewMembershipPurchases,
  ViewMembershipQuestions,
  ViewOffer,
  ViewRequiredMemberships,
} from '../pages/signed-in';
import { SignIn } from '../pages/signed-out';

const accessDeniedRouteObject: RouteObject = {
  path: 'access-denied',
  element: (
    <AuthenticatedRoute>
      <AccessDenied />
    </AuthenticatedRoute>
  ),
};

const accessDeniedStudentRouteObject: RouteObject = {
  path: 'access-denied-student',
  element: (
    <AuthenticatedRoute>
      <AccessDeniedStudent />
    </AuthenticatedRoute>
  ),
};

const emailNotVerifiedRouteObject: RouteObject = {
  path: 'email-not-verified',
  element: (
    <AuthenticatedRoute>
      <EmailNotVerified />
    </AuthenticatedRoute>
  ),
};

const emulateUserRouteObject: RouteObject = {
  path: 'emulate-user',
  children: [
    { index: true, element: <EmulateUser /> },
    { path: ':userKey', element: <EmulateUser /> },
  ],
};

const signUpRouteObject: RouteObject = {
  path: 'sign-up',
  children: [
    { index: true, element: <SignUp /> },
    {
      path: ':departmentInvitationKey',
      element: <SignUpFromInvitation />,
    },
  ],
};

const signOutRouteObject: RouteObject = {
  path: 'sign-out',
  children: [{ index: true, element: <SignOut /> }],
};

const signInRouteObject: RouteObject = {
  path: 'sign-in',
  element: (
    <UnauthenticatedRoute>
      <Outlet />
    </UnauthenticatedRoute>
  ),
  children: [{ index: true, element: <SignIn /> }],
};

const resetPasswordRouteObject: RouteObject = {
  path: 'reset-password',
  element: <Outlet />,
  children: [{ index: true, element: <ResetPassword /> }],
};

const createActivityRouteObjectChildren: RouteObject[] = [
  {
    element: <NavigateWithQuery to="description" />,
    index: true,
  },
  {
    element: <CreateActivityDescription />,
    path: 'description',
  },
  {
    path: 'media',
    element: <CreateActivityMedia />,
  },
  {
    path: 'participants',
    element: <CreateActivityParticipants />,
  },
  {
    path: 'tickets',
    element: <CreateActivityTickets />,
  },
  {
    path: 'request-more-info',
    element: <CreateActivityRequestMoreInfo />,
  },
  {
    path: 'co-hosting',
    element: <CreateActivityCoHosting />,
  },
  {
    path: 'addons',
    element: <CreateActivityAddons />,
  },
];

const activitiesRouteObject: RouteObject = {
  path: 'activities',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <Activities /> },
    {
      path: ':activityKey',
      element: <ViewActivity />,
      children: [
        {
          element: <NavigateWithQuery to="description" />,
          index: true,
        },
        {
          path: 'description',
          element: <ViewActivityDescription />,
        },
        {
          path: 'questions',
          element: <ViewActivityQuestions />,
        },
        {
          path: 'tickets',
          element: <ViewActivityTickets />,
        },
        {
          path: 'tickets-history',
          element: <ViewActivityTicketsHistory />,
        },
        {
          path: 'posts',
          element: <ViewActivityPosts />,
        },
      ],
    },
    {
      path: ':activityKey/consume-tickets',
      children: [
        {
          element: <NavigateWithQuery to="pick-scan-device" />,
          index: true,
        },
        {
          path: 'pick-scan-device',
          element: <PickScanDevice />,
        },
        {
          path: 'camera',
          element: <ScanWithCamera />,
        },
        {
          path: 'device',
          element: <ScanWithDevice />,
        },
      ],
    },
    {
      path: 'create-activity',
      element: <CreateActivity />,
      children: createActivityRouteObjectChildren,
    },
    {
      path: 'duplicate-activity/:activityKey',
      element: <DuplicateActivity />,
      children: createActivityRouteObjectChildren,
    },
    {
      path: 'draft/:draftKey',
      element: <CreateActivityFromDraft />,
      children: createActivityRouteObjectChildren,
    },
    {
      path: 'edit-activity/:activityKey',
      element: <EditActivity />,
      children: createActivityRouteObjectChildren,
    },
    { path: '*', element: <NavigateWithQuery replace to="/activities" /> },
  ],
};

const jobsRouteObject: RouteObject = {
  path: 'jobs',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <Jobs /> },
    { path: '*', element: <NavigateWithQuery replace to="/jobs" /> },
  ],
};

const postsRouteObject: RouteObject = {
  path: 'posts',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <Posts /> },
    { path: '*', element: <NavigateWithQuery replace to="/posts" /> },
  ],
};

const orbiPayRouteObject: RouteObject = {
  path: 'orbi-pay',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <OrbiPay /> },
    { path: '*', element: <NavigateWithQuery replace to="/orbi-pay" /> },
  ],
};

const questionsRouteObject: RouteObject = {
  path: 'questions',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <Questions /> },
    { path: '*', element: <NavigateWithQuery replace to="/questions" /> },
  ],
};

const referralsRouteObject: RouteObject = {
  path: 'referrals',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <Referrals /> },
    { path: '*', element: <NavigateWithQuery replace to="/referrals" /> },
  ],
};

const settingsRouteObject: RouteObject = {
  path: 'settings',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      path: '',
      element: <Settings />,
      children: [
        { path: 'account-settings', element: <AccountSettings /> },
        { path: 'department-settings', element: <Department /> },
        { path: 'team-settings', element: <Team /> },
        {
          index: true,
          element: <NavigateWithQuery replace to="account-settings" />,
        },
        {
          path: '*',
          element: <NavigateWithQuery replace to="account-settings" />,
        },
      ],
    },
  ],
};

const membershipsRouteObject: RouteObject = {
  path: 'memberships',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <Memberships /> },
    {
      path: 'create-membership',
      element: <CreateMembershipType />,
      children: [
        {
          element: <NavigateWithQuery to="general-info" />,
          index: true,
        },
        {
          path: 'general-info',
          element: <CreateMembershipGeneralInfo />,
        },
        {
          path: 'settings',
          element: <CreateMembershipSettings />,
        },
        {
          path: 'questions',
          element: <CreateMembershipQuestions />,
        },
        {
          path: 'periods',
          element: <CreateMembershipPeriods />,
        },
        {
          path: 'summary',
          element: <CreateMembershipSummary />,
        },
      ],
    },
    {
      path: 'create-integration',
      element: <CreateIntegratedMembershipType />,
      children: [
        {
          element: <NavigateWithQuery to="select-service" />,
          index: true,
        },
        {
          path: 'select-service',
          element: <CreateIntegratedMembershipSelectService />,
        },
        {
          path: 'general-info',
          element: <CreateIntegratedMembershipGeneralInfo />,
        },
        {
          path: 'summary',
          element: <CreateIntegratedMembershipSummary />,
        },
      ],
    },
    {
      path: ':membershipTypeKey',
      element: <ViewMembership />,
      children: [
        {
          element: <NavigateWithQuery to="overview" />,
          index: true,
        },
        {
          path: 'overview',
          element: <ViewMembershipOverview />,
        },
        {
          path: 'members',
          element: <ViewMembershipMembers />,
        },
        {
          path: 'questions',
          element: <ViewMembershipQuestions />,
        },
        {
          path: 'periods',
          element: <ViewMembershipPeriods />,
        },
        {
          path: 'required-memberships',
          element: <ViewRequiredMemberships />,
        },
        {
          path: 'purchases',
          element: <ViewMembershipPurchases />,
        },
        {
          path: '*',
          element: <NavigateWithQuery replace to="overview" />,
        },
      ],
    },
    {
      path: ':membershipTypeKey/invites',
      element: <MembershipInvites />,
      children: [
        {
          path: '*',
          element: <NavigateWithQuery replace to="invites" />,
        },
      ],
    },
    {
      path: ':membershipTypeKey/review-applications',
      element: <ReviewApplications />,
      children: [
        {
          path: '*',
          element: <NavigateWithQuery replace to="review-applications" />,
        },
      ],
    },
    { path: '*', element: <NavigateWithQuery replace to="/memberships" /> },
  ],
};

const offersRouteObject: RouteObject = {
  path: 'offers',
  element: (
    <AuthenticatedRoute>
      <SignedInOutlet />
    </AuthenticatedRoute>
  ),
  children: [
    { index: true, element: <Offers /> },
    { path: 'create-offer', element: <CreateOffer /> },
    { path: ':offerKey', element: <ViewOffer /> },
    { path: 'duplicate-offer/:offerKey', element: <DuplicateOffer /> },
    { path: '*', element: <NavigateWithQuery replace to="/offers" /> },
  ],
};

const switchWorkspaceRouteObject: RouteObject = {
  path: 'workspace/:type/:workspaceKey',
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [{ index: true, element: <SwitchWorkspace /> }],
};

const onboardedRouteObject: RouteObject = {
  path: '/onboarded',
  element: (
    <AuthenticatedRoute>
      <Outlet />
    </AuthenticatedRoute>
  ),
  children: [
    {
      index: true,
      element: <OnBoarded />,
    },
  ],
};

const fallbackRouteObject: RouteObject = {
  path: '',
  element: <FallbackNavigate />,
};

const redirectToDepartmentSettingsRouteObject: RouteObject = {
  path: '/department-settings',
  element: <NavigateWithQuery to="/settings/department-settings" />,
};

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <MainOutlet />,
    errorElement: <NotFound />,
    children: [
      /**
       * Can always be accessed
       */
      emulateUserRouteObject,
      signUpRouteObject,
      signOutRouteObject,
      resetPasswordRouteObject,

      /**
       * Can only be accessed when signed out
       */
      signInRouteObject,

      /**
       * Can only be accessed when signed in
       */
      onboardedRouteObject,
      activitiesRouteObject,
      jobsRouteObject,
      membershipsRouteObject,
      orbiPayRouteObject,
      offersRouteObject,
      postsRouteObject,
      questionsRouteObject,
      referralsRouteObject,
      settingsRouteObject,
      redirectToDepartmentSettingsRouteObject,
      switchWorkspaceRouteObject,
      accessDeniedRouteObject,
      accessDeniedStudentRouteObject,
      emailNotVerifiedRouteObject,
      fallbackRouteObject,
    ],
  },
];
