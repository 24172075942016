import { joiResolver } from '@hookform/resolvers/joi';
import { Modal, useConfirm } from '@orbiapp/components';
import { PostTargetType } from 'dtos/base/post-target-type';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CreatePostForm, CreatePostValidation } from '../../models';
import { QuickActionsContext } from '../quick-actions';
import { ActivityPostForm } from './activity-post-form';
import {
  CreatePostContext,
  DiscardChangesConfirm,
  useCloseAndReset,
} from './components';
import { DepartmentPostForm } from './department-post-form';
import { MembershipPostForm } from './membership-post-form';
import { PickTargetType } from './pick-target-type';

function ModalContent() {
  const { targetType } = React.useContext(CreatePostContext);

  switch (targetType) {
    case 'activity':
      return <ActivityPostForm />;

    case 'department':
      return <DepartmentPostForm />;

    case 'membership_type':
      return <MembershipPostForm />;

    default:
      return <PickTargetType />;
  }
}

function CreatePostModalContent() {
  const { createPostModalState } = React.useContext(QuickActionsContext);
  const { targetType } = React.useContext(CreatePostContext);

  const closeAndReset = useCloseAndReset();

  return (
    <Modal
      width={createPostModalState.value?.targetType || targetType ? 850 : 500}
      isOpen={createPostModalState.isOpen}
      onClose={closeAndReset}
      hideCloseButton
      zIndex={1900}
    >
      <DiscardChangesConfirm />

      {createPostModalState.value?.targetType === 'activity' ? (
        <ActivityPostForm />
      ) : (
        <ModalContent />
      )}
    </Modal>
  );
}

export function CreatePostModal() {
  const confirm = useConfirm();

  const [targetType, setTargetType] = React.useState<PostTargetType | null>(
    null,
  );

  const formMethods = useForm<CreatePostForm>({
    resolver: joiResolver(CreatePostValidation),
    defaultValues: {
      fileUploads: [],
      message: '',
      pushTo: null,
      targetKey: undefined,
      targetKeys: undefined,
      targetType: undefined,
    },
  });

  return (
    <CreatePostContext.Provider value={{ confirm, targetType, setTargetType }}>
      <FormProvider {...formMethods}>
        <CreatePostModalContent />
      </FormProvider>
    </CreatePostContext.Provider>
  );
}
