import { joiResolver } from '@hookform/resolvers/joi';
import {
  Box,
  Chip,
  ContentContainer,
  ContentSidebar,
  EmptyState,
  Icon,
  IconButton,
  InnerContentContainer,
  NavigateWithQuery,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Text,
  Tooltip,
  flattenFieldErrorsObject,
  useNavigateWithQuery,
} from '@orbiapp/components';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import {
  CreateMembershipTypeForm,
  MembershipTypeQuestionForm,
  QuestionsForm,
  QuestionsFormValidation,
  RequestedInfoType,
} from '../../../../../models';
import { Logger } from '../../../../../services';
import {
  getMembershipRequestedInfoDefaultValues,
  getOptionalLabelText,
  getRequestedInfoTypeTx,
} from '../../../../../utils';
import { CheckboxForm } from '../../components/checkbox-form';
import { FreeTextForm } from '../../components/freetext-form';
import { MultiChoiceForm } from '../../components/multichoice-form';
import { CreateMembershipTypeFormNavBlocker } from '../components';

const QuestionsSidebarContext = React.createContext<{
  defaultValues: MembershipTypeQuestionForm | null;
  setDefaultValues: React.Dispatch<
    React.SetStateAction<MembershipTypeQuestionForm | null>
  >;
}>({
  defaultValues: null,
  setDefaultValues: () => {},
});

function QuestionsSidebarProvider(props: React.PropsWithChildren) {
  const { children } = props;

  const [defaultValues, setDefaultValues] =
    React.useState<MembershipTypeQuestionForm | null>(null);

  return (
    <QuestionsSidebarContext.Provider
      value={{ defaultValues, setDefaultValues }}
    >
      {children}
    </QuestionsSidebarContext.Provider>
  );
}

function AddQuestionEmptyState() {
  const { defaultValues, setDefaultValues } = React.useContext(
    QuestionsSidebarContext,
  );

  const { watch } = useFormContext<QuestionsForm>();

  const enabled = watch('enabled');

  const openSidebar = () => {
    setDefaultValues(
      getMembershipRequestedInfoDefaultValues(RequestedInfoType.FreeText),
    );
  };

  return (
    <EmptyState
      buttonOnClick={openSidebar}
      buttonTx="label.memberships.questions.empty-state.button"
      disabled={defaultValues !== null || !enabled}
      titleTx="label.memberships.questions.empty-state.label"
    />
  );
}

function AddNewQuestionButton() {
  const { setDefaultValues, defaultValues } = React.useContext(
    QuestionsSidebarContext,
  );

  const addNewQuestion = () =>
    setDefaultValues(
      getMembershipRequestedInfoDefaultValues(RequestedInfoType.FreeText),
    );

  const { watch } = useFormContext<QuestionsForm>();

  const enabled = watch('enabled');

  return (
    <Box ml="auto">
      <Tooltip
        placement="left"
        titleTx="label.memberships.questions.empty-state.button"
      >
        <IconButton
          disabled={defaultValues !== null || !enabled}
          icon="plus-circle-outline"
          onClick={addNewQuestion}
        />
      </Tooltip>
    </Box>
  );
}

function QuestionsHeader() {
  const { watch, setValue } = useFormContext<QuestionsForm>();
  const { setDefaultValues } = React.useContext(QuestionsSidebarContext);

  const closeSidebar = () => setDefaultValues(null);

  const enabled = watch('enabled');

  const toggleAddQuestions = () => {
    if (enabled) {
      setValue('createMembershipQuestions', null);
      closeSidebar();
    }

    setValue('enabled', !enabled);
  };

  return (
    <Box width="100%" flex flexDirection="column" gap={24}>
      <Text
        text={getOptionalLabelText(
          'label.memberships.create-membership.questions.steps.1',
        )}
        variant="bodyMdBold"
      />

      <Box
        flex
        flexAlign="center"
        flexJustify="between"
        flexWrap="wrap"
        width="100%"
        gap={24}
      >
        <Box flex flexAlign="center" gap={8}>
          <Switch
            checked={enabled}
            tx="label.memberships.create-membership.questions.toggle-questions-label"
            onClick={toggleAddQuestions}
          />

          <Tooltip
            placement="left"
            titleTx="label.memberships.create-membership.questions.toggle-questions-tooltip"
          >
            <Icon color="checkmarkIcon" name="question-mark-circle-solid" />
          </Tooltip>
        </Box>

        <AddNewQuestionButton />
      </Box>
    </Box>
  );
}

function QuestionsContent() {
  const { watch, setValue } = useFormContext<QuestionsForm>();

  const { defaultValues } = React.useContext(QuestionsSidebarContext);
  const { createMembershipQuestions } = watch();

  const { setDefaultValues } = React.useContext(QuestionsSidebarContext);

  const renderQuestionTableRow = (question: MembershipTypeQuestionForm) => {
    const removeQuestion: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation();

      setValue(
        'createMembershipQuestions',
        createMembershipQuestions!.filter(({ key }) => key !== question.key),
      );
    };

    const selectRow = () => {
      setDefaultValues(question);
    };

    return (
      <TableRow
        highlight={defaultValues?.key === question.key}
        onClick={selectRow}
        key={question.key}
      >
        <TableCell text={question.question} />
        <TableCell>
          <Chip variant={1} tx={getRequestedInfoTypeTx(question.type)} />
        </TableCell>
        <TableCell>
          <Chip
            variant={2}
            tx={
              question.isRequired
                ? 'label.memberships.view-membership.questions.is-required'
                : 'label.memberships.view-membership.questions.is-optional'
            }
          />
        </TableCell>

        <TableCell width={40} hoverCell fixedRight>
          <IconButton icon="trash-outline" onClick={removeQuestion} />
        </TableCell>
      </TableRow>
    );
  };

  const questionsEmpty =
    !createMembershipQuestions ||
    (createMembershipQuestions && createMembershipQuestions.length === 0);

  return (
    <Box flex flexDirection="column" gap={24}>
      <QuestionsHeader />

      {questionsEmpty ? (
        <AddQuestionEmptyState />
      ) : (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead tx="label.memberships.create-membership.questions.table.question" />
              <TableHead tx="label.memberships.create-membership.questions.table.type" />
              <TableHead tx="label.memberships.create-membership.questions.table.required" />

              <TableHead fixedRight />
            </TableRow>
          </TableHeader>
          <TableBody>
            {createMembershipQuestions!.map(renderQuestionTableRow)}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}

function RequestedInfoForm() {
  const { defaultValues, setDefaultValues } = React.useContext(
    QuestionsSidebarContext,
  );
  const { getValues, setValue } = useFormContext<QuestionsForm>();

  const upsertMembershipQuestion = (
    createMembershipQuestion: MembershipTypeQuestionForm,
  ) => {
    const createMembershipQuestions =
      getValues('createMembershipQuestions') ?? [];

    const index = createMembershipQuestions.findIndex(
      (question) => question.key === createMembershipQuestion.key,
    );

    if (index !== -1) {
      setValue(`createMembershipQuestions.${index}`, createMembershipQuestion);
    } else {
      createMembershipQuestions.push(createMembershipQuestion);
      setValue('createMembershipQuestions', createMembershipQuestions);
    }

    setDefaultValues(null);
  };

  switch (defaultValues?.type) {
    case RequestedInfoType.FreeText:
      return (
        <FreeTextForm
          defaultValues={defaultValues}
          onSubmitted={upsertMembershipQuestion}
          setDefaultValues={setDefaultValues}
          titleTx="label.memberships.create-membership.questions.new-question"
        />
      );

    case RequestedInfoType.Checkbox:
      return (
        <CheckboxForm
          defaultValues={defaultValues}
          onSubmitted={upsertMembershipQuestion}
          setDefaultValues={setDefaultValues}
          titleTx="label.memberships.create-membership.questions.new-question"
        />
      );

    case RequestedInfoType.MultiChoice:
      return (
        <MultiChoiceForm
          defaultValues={defaultValues}
          onSubmitted={upsertMembershipQuestion}
          setDefaultValues={setDefaultValues}
          titleTx="label.memberships.create-membership.questions.new-question"
        />
      );

    default:
      return null;
  }
}

function QuestionsSidebar() {
  const { defaultValues, setDefaultValues } = React.useContext(
    QuestionsSidebarContext,
  );

  const closeSidebar = () => setDefaultValues(null);

  return (
    <ContentSidebar width={470} isOpen={!!defaultValues} onClose={closeSidebar}>
      <RequestedInfoForm />
    </ContentSidebar>
  );
}

export function CreateMembershipQuestions() {
  const createMembershipTypeFormContext =
    useFormContext<CreateMembershipTypeForm>();

  const navigate = useNavigateWithQuery();

  const [createMembershipQuestions, enabled] =
    createMembershipTypeFormContext.getValues([
      'createMembershipQuestions',
      'enabled',
    ]);

  const formMethods = useForm<QuestionsForm>({
    resolver: joiResolver(QuestionsFormValidation),
    defaultValues: {
      createMembershipQuestions,
      enabled,
    },
  });

  if (!createMembershipTypeFormContext.formState.isDirty) {
    return (
      <NavigateWithQuery to="/memberships/create-membership/general-info" />
    );
  }

  const handlePrevious = () => {
    createMembershipTypeFormContext.setValue(
      'createMembershipQuestions',
      formMethods.getValues('createMembershipQuestions'),
    );
    createMembershipTypeFormContext.setValue(
      'enabled',
      formMethods.getValues('enabled'),
    );

    navigate('/memberships/create-membership/settings');
  };

  const handleNext = formMethods.handleSubmit(
    (data) => {
      const options = { shouldDirty: true };

      createMembershipTypeFormContext.setValue(
        'createMembershipQuestions',
        data.createMembershipQuestions,
        options,
      );
      createMembershipTypeFormContext.setValue(
        'enabled',
        data.enabled,
        options,
      );

      navigate('/memberships/create-membership/periods');
    },
    (err) => {
      Logger.warning('createMembershipQuestions Validation', {
        err: flattenFieldErrorsObject(err),
      });
    },
  );

  return (
    <FormProvider {...formMethods}>
      <CreateMembershipTypeFormNavBlocker shouldBlock />

      <QuestionsSidebarProvider>
        <ContentContainer>
          <InnerContentContainer>
            <Text
              color="pageTitle"
              as="h1"
              tx="label.memberships.create-membership.tabs.questions"
              variant="titleMd"
            />

            <QuestionsContent />
          </InnerContentContainer>

          <Box p={32} flex flexJustify="between" gap={16}>
            <Tooltip titleTx="button.previous" placement="right">
              <IconButton
                onClick={handlePrevious}
                icon="arrow-left-circle-outline"
              />
            </Tooltip>

            <Tooltip placement="left" titleTx="button.continue">
              <IconButton
                icon="arrow-right-circle-outline"
                onClick={handleNext}
              />
            </Tooltip>
          </Box>
        </ContentContainer>

        <QuestionsSidebar />
      </QuestionsSidebarProvider>
    </FormProvider>
  );
}
