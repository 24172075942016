import { formatDate } from '@orbiapp/components';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';

import { AccountSelector, DepartmentSelector, useSelector } from '../store';

export function useUserPilot() {
  const userKey = useSelector(AccountSelector.selectUserKey);
  const fullName = useSelector(AccountSelector.selectFullName);
  const email = useSelector(AccountSelector.selectEmail);
  const createdAt = useSelector(AccountSelector.selectCreatedAt);

  const location = useLocation();

  const departmentName = useSelector(DepartmentSelector.selectName);
  const departmentKey = useSelector(DepartmentSelector.selectDepartmentKey);
  const departmentCreatedAt = useSelector(DepartmentSelector.selectCreatedAt);

  React.useEffect(() => {
    if (!userKey || !departmentKey) return;

    Userpilot.identify(userKey, {
      name: fullName,
      email: email,
      created_at: formatDate(createdAt, 'YYYY-MM-DD'),
      company: {
        id: departmentKey,
        name: departmentName,
        created_at: formatDate(departmentCreatedAt, 'YYYY-MM-DD'),
      },
      // Additional properties
      // projectId: "1",
      // trialEnds: '2019-10-31' // ISO8601 Date
    });
  }, [
    userKey,
    fullName,
    email,
    createdAt,
    departmentName,
    departmentKey,
    departmentCreatedAt,
  ]);

  React.useEffect(() => {
    if (!userKey) return;

    Userpilot.reload();
  }, [location, userKey]);
}
