import {
  INITIAL_DATA_STATE,
  INITIAL_PAGINATION_STATE,
} from '../store.constants';
import {
  activityListItemAdapter,
  coHostingAdapter,
  draftAdapter,
  freetextListItemsAdapter,
  qrScanTokensAdapter,
  ticketsAdapter,
  ticketsHistoryAdapter,
} from './activities.adapter';
import { ActivityState } from './activities.types';

export const initialActivitiesState: ActivityState = {
  activity: INITIAL_DATA_STATE,
  activityRecords: INITIAL_DATA_STATE,
  createActivity: INITIAL_DATA_STATE,
  dynamicActivityLink: INITIAL_DATA_STATE,
  unpublishActivity: INITIAL_DATA_STATE,
  updateActivity: INITIAL_DATA_STATE,
  activities: {
    ...INITIAL_DATA_STATE,
    data: activityListItemAdapter.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'startDate' },
  },

  coHostingRequests: {
    ...INITIAL_DATA_STATE,
    data: coHostingAdapter.getInitialState(),
  },
  updateCoHostingRequest: INITIAL_DATA_STATE,

  activityDraft: INITIAL_DATA_STATE,
  activityDrafts: {
    ...INITIAL_DATA_STATE,
    data: draftAdapter.getInitialState(),
  },
  createActivityDraft: INITIAL_DATA_STATE,
  deleteActivityDraft: INITIAL_DATA_STATE,

  createQrScanToken: INITIAL_DATA_STATE,
  deleteQrScanToken: INITIAL_DATA_STATE,
  qrScanTokens: {
    ...INITIAL_DATA_STATE,
    data: qrScanTokensAdapter.getInitialState(),
  },

  activityUsers: INITIAL_DATA_STATE,
  discountStats: INITIAL_DATA_STATE,
  freetextListItems: {
    ...INITIAL_DATA_STATE,
    data: freetextListItemsAdapter.getInitialState(),
    pagination: { ...INITIAL_PAGINATION_STATE, orderBy: 'createdAt' },
  },
  requestedInfoStats: INITIAL_DATA_STATE,

  consumeTicket: INITIAL_DATA_STATE,
  refundTicket: INITIAL_DATA_STATE,
  scanTicket: INITIAL_DATA_STATE,
  selectedTicketListItem: null,
  ticketDetails: INITIAL_DATA_STATE,
  ticketListItems: {
    ...INITIAL_DATA_STATE,
    data: ticketsAdapter.getInitialState(),
    pagination: {
      ...INITIAL_PAGINATION_STATE,
      orderBy: 'claimedAt',
    },
    search: '',
  },
  ticketsHistory: {
    ...INITIAL_DATA_STATE,
    data: ticketsHistoryAdapter.getInitialState(),
  },
  undoConsumeTicket: INITIAL_DATA_STATE,
  undoScanTicket: INITIAL_DATA_STATE,
  activityUsersData: INITIAL_DATA_STATE,

  activityCategories: INITIAL_DATA_STATE,
  suggestActivityCategories: INITIAL_DATA_STATE,
};
