import { sumOver } from '@orbiapp/components';

import {
  ActivityListItem,
  CreateActivityForm,
  UpdateActivityForm,
} from '../../models';
import { Logger } from '../../services';
import { branchIO } from '../../services/branch';

export const createActivityFormToActivityListItem = (
  createActivityForm: CreateActivityForm,
  activityKey: string,
  departmentKey: string,
): ActivityListItem => {
  const totalTicketCount =
    createActivityForm.tickets.ticketTypes?.reduce((prev, curr) => {
      return prev + curr.ticketCount;
    }, 0) ?? 0;

  return {
    departmentKey,
    startDate: createActivityForm.description.startDate,
    endDate: createActivityForm.description.endDate,
    title: createActivityForm.description.title,
    activityKey,
    totalTicketCount,
    stats: {
      numberOfClicks: 0,
      numberOfQuestions: 0,
      numberOfSoldTickets: 0,
    },
    coverImage: {
      fileName: createActivityForm.media.coverImage!.fileName,
      fileType: createActivityForm.media.coverImage!.fileType,
      fileUrl: createActivityForm.media.coverImage!.base64,
    },
    description: createActivityForm.description.description,
  };
};

export const updateActivityFormToActivityListItem = (
  updateActivityForm: UpdateActivityForm,
  departmentKey: string,
): Omit<ActivityListItem, 'stats'> => ({
  activityKey: updateActivityForm.activityKey,
  departmentKey,
  endDate: updateActivityForm.description.endDate,
  startDate: updateActivityForm.description.startDate,
  title: updateActivityForm.description.title,
  totalTicketCount: updateActivityForm.tickets.ticketTypes
    ? sumOver(updateActivityForm.tickets.ticketTypes, 'ticketCount')
    : 0,
  coverImage: {
    fileName: updateActivityForm.media.coverImage!.fileName,
    fileType: updateActivityForm.media.coverImage!.fileType,
    fileUrl: updateActivityForm.media.coverImage!.base64,
  },
  description: updateActivityForm.description.description,
});

export const createQrScanTokenUrl = async (
  activityKey: string,
  sessionId: string,
) => {
  try {
    const link = await branchIO.createLink({
      type: 'ticketScanner',
      activityKey,
      sessionId,
    });

    return link;
  } catch (err) {
    Logger.error('createQrScanTokenUrl', {
      err: err instanceof Error ? err.message : JSON.stringify(err),
    });
    return null;
  }
};
