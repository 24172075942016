import {
  Account,
  QUERY_CUSTOM_TOKEN_KEY,
  QUERY_WORKSPACE_KEY,
  UpdateWorkspace,
  WorkspacesSessionStorage,
  constructQueryURL,
} from '@orbiapp/components';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ADMIN_DASHBOARD_URL, COMPANY_DASHBOARD_URL } from '../../config';
import {
  CreateAccountByInvitationParams,
  UpdateAccountByInvitationParams,
  UpdateAccountForm,
} from '../../models';
import { OrbiApi, v1, v2 } from '../../services';
import {
  authActions,
  getAuthAccountThunk,
  signInThunk,
  signOutThunk,
} from '../auth';
import { getDepartmentThunk } from '../department';
import { setAlert } from '../global-ui-state';
import { getOrbiPaySettingsThunk } from '../orbi-pay';
import { ThunkApiConfig } from '../store.types';

function getSwitchWorkspaceBaseURL(emulateUserKey?: string) {
  if (!emulateUserKey) {
    return COMPANY_DASHBOARD_URL;
  }

  return [COMPANY_DASHBOARD_URL, 'emulate-user', emulateUserKey].join('/');
}

export const getAccountThunk = createAsyncThunk<
  Account,
  undefined,
  ThunkApiConfig
>('account/get-account', async (_, thunkAPI) => {
  const res = await OrbiApi.call(v2.account.getAccount, undefined);

  if (res.kind === 'ok') {
    return res.data;
  }

  thunkAPI.dispatch(signOutThunk());
  return thunkAPI.rejectWithValue(res);
});

export const createAccountByInvitationThunk = createAsyncThunk<
  string,
  CreateAccountByInvitationParams,
  ThunkApiConfig
>(
  'account/create-account-by-invitation',
  async (createAccountByInvitationParams, thunkAPI) => {
    const res = await OrbiApi.call(
      v2.account.createAccountByInvitation,
      createAccountByInvitationParams,
    );
    if (res.kind !== 'ok') {
      return thunkAPI.rejectWithValue(res);
    }

    await thunkAPI.dispatch(
      signInThunk({
        email: createAccountByInvitationParams.email,
        password: createAccountByInvitationParams.password,
      }),
    );
    return res.data;
  },
);

export const updateAccountByInvitationThunk = createAsyncThunk<
  void,
  UpdateAccountByInvitationParams,
  ThunkApiConfig
>(
  'account/update-account-by-invitation',
  async (updateAccountByInvitationParams, thunkAPI) => {
    await thunkAPI.dispatch(
      signInThunk({
        email: updateAccountByInvitationParams.email,
        password: updateAccountByInvitationParams.password,
      }),
    );

    const res = await OrbiApi.call(
      v2.account.updateAccountByInvitation,
      updateAccountByInvitationParams,
    );

    if (res.kind !== 'ok') {
      return thunkAPI.rejectWithValue(res);
    }

    WorkspacesSessionStorage.endSession();

    await thunkAPI.dispatch(getAccountThunk());
    await thunkAPI.dispatch(getAuthAccountThunk());
    await thunkAPI.dispatch(getDepartmentThunk());
  },
);

export const updateAccountThunk = createAsyncThunk<
  void,
  UpdateAccountForm,
  ThunkApiConfig
>('account/update-account', async (updateAccountParams, thunkAPI) => {
  const res = await OrbiApi.call(v2.account.updateAccount, updateAccountParams);

  if (res.kind === 'ok') {
    thunkAPI.dispatch(setAlert('update-account-settings:success'));
    return;
  }

  thunkAPI.dispatch(setAlert('update-account-settings:error'));
  return thunkAPI.rejectWithValue(res);
});

export const updateWorkspaceThunk = createAsyncThunk<
  void,
  UpdateWorkspace,
  ThunkApiConfig
>('account/update-workspace', async (updateWorkspaceParams, thunkAPI) => {
  const res = await OrbiApi.call(
    v2.account.updateWorkspace,
    updateWorkspaceParams,
  );

  if (res.kind !== 'ok') {
    return thunkAPI.rejectWithValue(res);
  }
});

export const switchWorkspaceThunk = createAsyncThunk<
  void,
  UpdateWorkspace & { emulateUserKey?: string },
  ThunkApiConfig
>('account/switch-workspace', async (switchWorkspaceParams, thunkAPI) => {
  const customTokenRes = await OrbiApi.call(
    v1.auth.getCustomSignInToken,
    undefined,
  );

  if (customTokenRes.kind !== 'ok') {
    return thunkAPI.rejectWithValue(customTokenRes);
  }

  switch (switchWorkspaceParams.type) {
    case 'admin':
      window.location.href = constructQueryURL(ADMIN_DASHBOARD_URL, {
        [QUERY_CUSTOM_TOKEN_KEY]: customTokenRes.data,
      });
      break;

    case 'company':
      window.location.href = constructQueryURL(
        getSwitchWorkspaceBaseURL(switchWorkspaceParams.emulateUserKey),
        {
          [QUERY_CUSTOM_TOKEN_KEY]: customTokenRes.data,
          [QUERY_WORKSPACE_KEY]: switchWorkspaceParams.companyKey,
        },
      );
      break;

    case 'department':
      WorkspacesSessionStorage.startSession(
        switchWorkspaceParams.departmentKey,
      );

      await thunkAPI.dispatch(getAccountThunk());
      await thunkAPI.dispatch(getAuthAccountThunk());
      await thunkAPI.dispatch(getOrbiPaySettingsThunk());

      thunkAPI.dispatch(
        authActions.setAuthState({
          accountVerified: true,
          authenticated: true,
          initializing: false,
        }),
      );
      break;
  }

  return;
});
