import {
  FullscreenProvider,
  LoadingContainer,
  PageContainer,
  useNavigateWithQuery,
} from '@orbiapp/components';
import React from 'react';
import { useParams } from 'react-router-dom';

import {
  ActivityDataSelector,
  ActivitySelector,
  getActivityThunk,
  getQrScanTokensThunk,
  useDispatch,
  useSelector,
} from '../../../../../store';
import { NotFound } from '../../../../shared';
import { ShareScanLinkSidebarProvider } from '../share-scan-link-sidebar';

export function ScanQrCodesContainer(props: React.PropsWithChildren) {
  const { children } = props;

  const dispatch = useDispatch();
  const navigate = useNavigateWithQuery();
  const activityStatus = useSelector(ActivitySelector.selectStatus);
  const activityError = useSelector(ActivitySelector.selectError);
  const activityKey = useSelector(ActivityDataSelector.selectActivityKey);

  const params = useParams<{ activityKey: string }>();
  const hasInitialized = React.useRef(false);

  const initScanQrCodesContainer = React.useCallback(async () => {
    const _activityKey = params.activityKey || activityKey;

    if (!_activityKey) {
      navigate('/');
      return;
    }

    hasInitialized.current = true;
    await dispatch(getActivityThunk(_activityKey));
    dispatch(getQrScanTokensThunk());
  }, [dispatch, navigate, params.activityKey, activityKey]);

  React.useEffect(() => {
    if (hasInitialized.current) return;

    initScanQrCodesContainer();
  }, [hasInitialized, initScanQrCodesContainer]);

  if (activityError) {
    return <NotFound continueTo="/activities" />;
  }

  if (activityStatus === 'pending' || !activityKey) {
    return <LoadingContainer />;
  }

  return (
    <ShareScanLinkSidebarProvider>
      <FullscreenProvider>
        <PageContainer>{children}</PageContainer>
      </FullscreenProvider>
    </ShareScanLinkSidebarProvider>
  );
}
