import { colors, palette } from '@orbiapp/theme';

export const studentDashboardColors = {
  ...colors,

  /*
   * Scan QR Codes
   */
  scanQrCodesContentAreaBackground: palette.neutral[1000],
  scanQrCodesCardBackground: palette.neutral[1000],
  scanQrCodesCardBorder: palette.neutral[800],
  scanQrCodesPickDeviceCardBackground: palette.neutral.white,
  scanQrCodesPickDeviceCardBorder: palette.neutral[700],
  scanQrCodesShareScanLinkOddRowBackground: palette.neutral.white,
  scanQrCodesShareScanLinkEvenRowBackground: palette.neutral[1000],
  scanQrCodesShareScanLinkTableHeadBorder: palette.neutral[700],

  /*
   * Summary list item
   */
  summaryListItemTitle: palette.neutral[300],

  /*
   * Select service (create integration)
   */
  selectServiceBorder: palette.neutral[500],
  selectServiceBackground: palette.neutral[1000],

  /*
   * Summary grid item
   */
  summaryGridItemBorder: palette.neutral[700],

  /*
   * Review applications
   */
  reviewApplicationsHistoryDotBackground: palette.neutral[700],
  reviewApplicationsInfoIconColor: palette.neutral[500],
  reviewApplicationsNoteBoxBorder: palette.neutral[800],

  /*
   * Select option box
   */
  selectOptionBoxBackground: palette.neutral[1000],
  selectOptionBoxBorder: palette.neutral[400],

  /*
   * Invite members modal
   */
  inviteMembersModalErrorIcon: palette.alert[200],
  inviteMembersModalSuccessIcon: palette.success[200],

  /*
   * Membership settings card
   */
  settingCardBackground: palette.neutral[1000],
  settingCardBorder: palette.neutral[700],
  settingCardBackgroundHover: palette.neutral[900],

  settingCardTitle: palette.neutral[300],

  settingCardSubtitle: palette.neutral[100],

  settingCardIcon: palette.neutral[100],

  /**
   * Membership card
   */
  membershipCardLogoBorder: palette.neutral.white,
  membershipCardName: palette.neutral.white,

  /*
   * Membership List item
   */
  membershipOverviewListItemTitle: palette.neutral[300],

  /*
   * Pick city
   */
  pickCityCardBackground: palette.neutral.white,

  /*
   * Checkmark Icon
   */
  checkmarkIcon: palette.neutral[500],

  /*  
    Create event location
  */
  customLocationPlusIcon: palette.neutral[500],

  /*
   * Referrals sidebar message
   */
  sidebarItemReferralsHeader: palette.neutral[100],
  sidebarItemReferralsDescription: palette.neutral[400],
  sidebarItemReferralsLearnMore: palette.neutral[300],

  /**
   *  Sidebar new label
   */
  sidebarItemNewLabel: palette.neutral.white,
  sidebarItemNewLabelBackground: palette.neutral[100],

  /**
   *  Upload cover image
   */
  uploadCoverImageHasValueLabel: palette.neutral.white,
};
