import {
  Box,
  LAYOUT_Z_INDEX,
  Sidebar,
  SidebarContext,
  SidebarItem,
  SidebarMessage,
  Text,
  useFeatureFlag,
} from '@orbiapp/components';
import { breakpoints } from '@orbiapp/theme';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { OrbiPayRole } from '../../models';
import { Analytics } from '../../services';
import {
  AccountSelector,
  CoHostingRequestsSelector,
  DepartmentSelector,
  OrbiPaySettingsSelector,
  UnhandledQuestionsSelector,
  getCoHostingRequestsThunk,
  getUnhandledQuestionsCountThunk,
  useDispatch,
  useSelector,
} from '../../store';
import { getReferralRewardAmountsInCurrency } from '../../utils';
import { Styled } from './main-sidebar.styled';

function SidebarItems() {
  const isCareerAdmin = useSelector(DepartmentSelector.selectIsCareerAdmin);
  const unhandledQuestionsCount = useSelector(
    UnhandledQuestionsSelector.selectData,
  );
  const coHostingRequestsCount = useSelector(
    CoHostingRequestsSelector.selectTotal,
  );
  const hasPendingCohostInvitation = coHostingRequestsCount > 0;

  const orbiPayIsDisabled = useSelector(
    OrbiPaySettingsSelector.selectIsDisabled,
  );
  const orbiPayRole = useSelector(OrbiPaySettingsSelector.selectOrbiPayRole);
  const stripeRequirements = useSelector(
    OrbiPaySettingsSelector.selectRequirementsType,
  );
  const currency = useSelector(OrbiPaySettingsSelector.selectCurrency);

  const department_name = useSelector(DepartmentSelector.selectName);
  const fullName = useSelector(AccountSelector.selectFullName);
  const email = useSelector(AccountSelector.selectEmail);

  const { closeSidebar } = React.useContext(SidebarContext);

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const handleMenuItemClick =
    window.innerWidth < breakpoints.xs ? closeSidebar : undefined;

  const showQuestions = useFeatureFlag('student_dashboard_show_questions');

  const handleReferralsClick = () => {
    handleMenuItemClick?.();
    Analytics.track('click_sidebar_item_referrals', {
      param1: department_name,
      param2: fullName ?? undefined,
      param3: email,
    });
  };

  React.useEffect(() => {
    if (showQuestions) {
      dispatch(getUnhandledQuestionsCountThunk());
    }
    dispatch(getCoHostingRequestsThunk());
  }, [dispatch, showQuestions]);

  return (
    <React.Fragment>
      <SidebarItem
        hasNotification={hasPendingCohostInvitation}
        icon="ticket-outline"
        iconActive="ticket-solid"
        isActive={pathname.startsWith('/activities')}
        onClick={handleMenuItemClick}
        to="/activities"
        tooltipTx="link.sidebar.activities"
        tx="link.sidebar.activities"
      />

      <SidebarItem
        icon="identification-outline"
        iconActive="identification-solid"
        isActive={pathname.startsWith('/memberships')}
        onClick={handleMenuItemClick}
        to="/memberships"
        tooltipTx="link.sidebar.memberships"
        tx="link.sidebar.memberships"
      />

      <SidebarItem
        icon="tag-outline"
        iconActive="tag-solid"
        isActive={pathname.startsWith('/offers')}
        onClick={handleMenuItemClick}
        to="/offers"
        tooltipTx="link.sidebar.offers"
        tx="link.sidebar.offers"
      />

      {isCareerAdmin && (
        <SidebarItem
          icon="briefcase-outline"
          iconActive="briefcase-solid"
          isActive={pathname.startsWith('/jobs')}
          onClick={handleMenuItemClick}
          to="/jobs"
          tooltipTx="link.sidebar.jobs"
          tx="link.sidebar.jobs"
        />
      )}

      {showQuestions && (
        <SidebarItem
          hasNotification={unhandledQuestionsCount > 0}
          icon="question-mark-circle-outline"
          iconActive="question-mark-circle-solid"
          isActive={pathname.startsWith('/questions')}
          onClick={handleMenuItemClick}
          to="/questions"
          tooltipTx="link.sidebar.questions"
          tx="link.sidebar.questions"
        />
      )}

      <SidebarItem
        icon="megaphone-outline"
        iconActive="megaphone-solid"
        isActive={pathname.startsWith('/posts')}
        onClick={handleMenuItemClick}
        to="/posts"
        tooltipTx="link.sidebar.posts"
        tx="link.sidebar.posts"
      />

      <SidebarItem
        icon="credit-card-outline"
        iconActive="credit-card-solid"
        isActive={pathname.startsWith('/orbi-pay')}
        onClick={handleMenuItemClick}
        to="/orbi-pay"
        tooltipTx="link.sidebar.orbi-pay"
        tx="link.sidebar.orbi-pay"
        hasNotification={
          (orbiPayIsDisabled || !!stripeRequirements) &&
          orbiPayRole === OrbiPayRole.Admin
        }
      />

      <Box mt="auto" role="menuitem">
        <SidebarMessage
          icon="currency-dollar-outline"
          iconActive="currency-dollar-solid"
          isActive={pathname.startsWith('/referrals')}
          onClick={handleReferralsClick}
          to="/referrals"
          tooltipTx="link.sidebar.referrals"
        >
          <Box flex flexDirection="column" gap={8}>
            <Text
              tx="link.sidebar.referrals"
              variant="bodySmBold"
              color="sidebarItemReferralsHeader"
              whiteSpace="nowrap"
            />
            <Text
              tx="link.sidebar.referrals-description"
              variant="bodySm"
              txArgs={{
                maxReward:
                  getReferralRewardAmountsInCurrency(currency)
                    .maxRewardWithoutCurrency,
              }}
              color="sidebarItemReferralsDescription"
              width="19ch"
            />
            <Styled.ReferAFriend
              tx="link.sidebar.referrals-learn-more"
              variant="bodySm"
              color="sidebarItemReferralsLearnMore"
              whiteSpace="nowrap"
            />
          </Box>
        </SidebarMessage>
      </Box>
    </React.Fragment>
  );
}

export function MainSidebar() {
  return (
    <Sidebar
      backdropZIndex={LAYOUT_Z_INDEX.pageSidebarBackdrop}
      zIndex={LAYOUT_Z_INDEX.pageSidebar}
    >
      <SidebarItems />
    </Sidebar>
  );
}
